import React, { useContext } from "react";
import _ from "lodash";
import { isBrowser, isMobile } from "react-device-detect";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import ContentArea from "../../ContentArea";
import { PriceModule, PriceModule_2 } from "../../PriceModule"

const incentives = [
  {
    name: "PA Systems",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "The PA system features advanced technology and equipment, including microphones, speakers, and amplifiers, that ensure clear and crisp sound quality. With the PA system, guests can enjoy seamless and effective communication during their meetings or events.",
  },
  {
    name: "WIFI Internet",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Basileia Palace provides secure and high-speed WiFi for guests in all areas of the hotel, enabling them to stay connected and productive during their stay.",
  },
  {
    name: "Projector",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "Basileia Palace offers projectors for use in their meeting rooms and outdoor celebrations venue, providing a high-quality audio-visual experience for presentations and entertainment. ",
  },
  {
    name: "Mini Bar",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "Basileia Palace offers a mini-bar for their guests, providing a convenient and refreshing selection of beverages and snacks for guests.",
  },
  {
    name: "Catering",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Guests can enjoy their meals in the dining areas or outdoor venue, which is a picturesque and tranquil oasis. The catering service is designed to provide guests with a memorable and enjoyable dining experience.",
  },
];

const tiers = [
  {
    name: "Basic",
    id: "tier-basic",
    href: "#",
    price: { monthly: "$15", annually: "$12" },
    description: "Everything necessary to get started.",
    features: [
      "5 products",
      "Up to 1,000 subscribers",
      "Basic analytics",
      "48-hour support response time",
    ],
  },
  {
    name: "Essential",
    id: "tier-essential",
    href: "#",
    price: { monthly: "$30", annually: "$24" },
    description:
      "Everything in Basic, plus essential tools for growing your business.",
    features: [
      "25 products",
      "Up to 10,000 subscribers",
      "Advanced analytics",
      "24-hour support response time",
      "Marketing automations",
    ],
  },
  {
    name: "Growth",
    id: "tier-growth",
    href: "#",
    price: { monthly: "$60", annually: "$48" },
    description:
      "Everything in Essential, plus collaboration tools and deeper insights.",
    features: [
      "Unlimited products",
      "Unlimited subscribers",
      "Advanced analytics",
      "1-hour, dedicated support response time",
      "Marketing automations",
      "Custom reporting tools",
    ],
  },
];

const Elem = () => (
  <div className="bg-white/100 backdrop-blur-sm rounded-none">
    <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-1 px-4 py-4 sm:px-6 sm:py-2 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
    <div className="grid grid-cols-3 grid-rows-2 place-items-center gap-1 ">
        <img
          src="https://cdn.basileiapalaceja.com/media/indoor_image_1.jpg"
          alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
          className="col-span-3 rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/indoor_image_2.jpg"
          alt="Top down view of walnut card tray with embedded magnets and card groove."
          className="col-span-2 rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/indoor_image_3.jpg"
          alt="Top down view of walnut card tray with embedded magnets and card groove."
          className="col-span-1 aspect-square rounded-full bg-gray-100 shadow-md shadow-gray-500"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/indoor_image_4.jpg"
          alt="Side of walnut card tray with card groove and recessed card area."
          className="col-span-1 aspect-square rounded-full bg-gray-100 shadow-md shadow-gray-500"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/indoor_image_5.jpg"
          alt="Walnut card tray filled with cards and card angled in dedicated groove."
          className="rounded-lg bg-gray-100 col-span-2"
        />
      </div>
      <div>

        <dl className="mt-16 grid grid-cols-1 gap-x-6  sm:grid-cols-2 gap-y-4">
          {incentives.map((incentive) => (
            <div key={incentive.name} className="sm:flex lg:block ring-1 ring-slate-200/80  shadow-xl rounded-xl p-4 bg-gradient-to-b from-white/80 to-white/10">
              
              <div className="sm:ml-6 sm:mt-0 lg:ml-0">
                <h3 className="text-sm font-extrabold uppercase text-amber-800/40">
                  {incentive.name}
                </h3>
                <p className="mt-2 text-xs text-gray-800">
                  {incentive.description}
                </p>
              </div>
            </div>
          ))}
        </dl>
      </div>
      
    </div>
  </div>
);

function Hero() {
  return (
    <div className="overflow-hidden bg-white py-32">
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-thin uppercase tracking-tight text-gray-900 sm:text-4xl">
              Inside or Outside{" "}
            </h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">
              The choice is yours, we accommodate both, based on your taste we can
              provide the best environment for the occasion.
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">
              We can seat up to 150 guests, we provide chairs, tables, podiums
              and PA systems. If you want we also provide catering and
              refreshments and many more.
            </p>
          </div>
          <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
              <img
                src="https://cdn.basileiapalaceja.com/media/indoor_image_6.jpg"
                alt=""
                className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover object-top"
              />
            </div>
            <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
              <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                <img
                  src="https://cdn.basileiapalaceja.com/media/indoor_image_7.jpg"
                  alt=""
                  className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover object-top"
                />
              </div>
              <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                <img
                  src="https://cdn.basileiapalaceja.com/media/indoor_image_8.jpg"
                  alt=""
                  className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover  object-top"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const d = (
  <div className="mx-auto  max-w-7xl lg:px-4 pt-14">
    <div
      className="w-full rounded-none h-full  overflow-auto   bg-contain bg-no-repeat bg-gradient-to-r from-violet-500 to-fuchsia-500"
      style={{
        backgroundImage: `url('https://cdn.basileiapalaceja.com/media/indoor_image_4.jpg')`,
      }}
    >
      <div className="mx-auto max-w-7xl py-0  lg:max-w-none">
        <div className="grid grid-cols-1 items-center gap-x-16 gap-y-10 lg:grid-cols-2 py-0">
          <h2 className="text-4xl font-thin tracking-tight text-gray-100 px-10">
            <p className="shadow-xl bg-gray-50/50 text-black">Corporate Events</p>
            <p className="text-lg tracking-normal font-bold indent-3  text-black">@ Basiliea Palace</p>
          </h2>
          <p className="m-4  bg-gradient-to-b from-white/40 to-white/50 backdrop-blur-sm text-xl text-gray-900 font-thin p-8  rounded-lg antialiased hover:subpixel-antialiased">
            <b>Elevate your corporate events to new heights in our sophisticated and professional setting.</b> <br/><br/> From corporate conferences to product launches, our versatile spaces cater to all your business needs. With state-of-the-art technology, impeccable service, and customizable packages, we create an environment that fosters productivity, networking, and success.
          </p>
        </div>
        <Elem />
        <PriceModule category='corporate' />
      </div>
    </div>
  </div>
);

export  function CorporateContentBlock() {
  return <ContentArea>{d}</ContentArea>;
}
