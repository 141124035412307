import React, { useContext } from "react";
import _ from "lodash";
import { isBrowser, isMobile } from "react-device-detect";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import CategoryBlock from "./CategoryBlock";
import ContentArea from "../ContentArea";
import { PriceModule, PriceModule_2 } from "../../components/PriceModule"

const incentives = [
  {
    name: "Podium",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "Basileia Palace offers podiums in their meeting rooms that are equipped with state-of-the-art technology and designed for the comfort of the speaker, providing a platform for effective and clear presentations during conferences and meetings.",
  },
  {
    name: "PA Systems",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "The PA system features advanced technology and equipment, including microphones, speakers, and amplifiers, that ensure clear and crisp sound quality. With the PA system, guests can enjoy seamless and effective communication during their meetings or events.",
  },
  {
    name: "WIFI Internet",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Basileia Palace provides secure and high-speed WiFi for guests in all areas of the hotel, enabling them to stay connected and productive during their stay.",
  },
  {
    name: "Projector",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "Basileia Palace offers projectors for use in their meeting rooms and outdoor celebrations venue, providing a high-quality audio-visual experience for presentations and entertainment. ",
  },
  {
    name: "Mini Bar",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "Basileia Palace offers a mini-bar for their guests, providing a convenient and refreshing selection of beverages and snacks for guests.",
  },
  {
    name: "Catering",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Guests can enjoy their meals in the dining areas or outdoor venue, which is a picturesque and tranquil oasis. The catering service is designed to provide guests with a memorable and enjoyable dining experience.",
  },
];


const Elem = () => (
  <div className="bg-black/20 rounded-lg">
    <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
      <div>
        <h2 className="text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">
          Value Added
        </h2>
        <p className="mt-4 text-gray-100">
          The walnut wood card tray is precision milled to perfectly fit a stack
          of Focus cards. The powder coated steel divider separates active cards
          from new ones, or can be used to archive important task lists.
        </p>

        <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
          {incentives.map((incentive) => (
            <div key={incentive.name} className="sm:flex lg:block">
              <div className="sm:flex-shrink-0">
                <img
                  className="hidden h-16 w-16"
                  src={incentive.imageSrc}
                  alt=""
                />
              </div>
              <div className="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                <h3 className="text-sm font-bold underline text-gray-100">
                  {incentive.name}
                </h3>
                <p className="mt-2 text-sm text-gray-100">
                  {incentive.description}
                </p>
              </div>
            </div>
          ))}
        </dl>
      </div>
      <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
        <img
          src="https://tef.gov.jm/wp-content/uploads/2020/07/SNP_7698.jpg?v=1609973361"
          alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
          className="rounded-lg bg-gray-100"
        />
        <img
          src="https://tef.gov.jm/wp-content/uploads/2020/07/SNP_7832-700x700_c.jpg"
          alt="Top down view of walnut card tray with embedded magnets and card groove."
          className="rounded-lg bg-gray-100"
        />
        <img
          src="https://northcoasttimesja.com/wp-content/uploads/2017/07/DSCN7806.jpg"
          alt="Side of walnut card tray with card groove and recessed card area."
          className="rounded-lg bg-gray-100"
        />
        <img
          src="https://northcoasttimesja.com/wp-content/uploads/2017/07/DSCN7853-768x793.jpg"
          alt="Walnut card tray filled with cards and card angled in dedicated groove."
          className="rounded-lg bg-gray-100"
        />
      </div>
    </div>
  </div>
);

function Hero() {
  return (
    <div className="overflow-hidden bg-white py-32">
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-thin uppercase tracking-tight text-gray-900 sm:text-4xl">
              Inside or Outside{" "}
            </h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">
              The choice is yours, we accommodate both, based on your taste we can
              provide the best environment for the occasion.
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">
              We can seat up to 150 guests, we provide chairs, tables, podiums
              and PA systems. If you want we also provide catering and
              refreshments and many more.
            </p>
            <div className="mt-10 flex">
              <a
                href="#"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Book Early <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
          <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
              <img
                src="https://cdn.basileiapalaceja.com/media/building/building_1.jpg"
                alt=""
                className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
              />
            </div>
            <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
              <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                <img
                  src="https://cdn.basileiapalaceja.com/media/indoor_image_2.jpg"
                  alt=""
                  className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
              <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                <img
                  src="https://cdn.basileiapalaceja.com/media/building/building_3.jpg"
                  alt=""
                  className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
              <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                <img
                  src="https://cdn.basileiapalaceja.com/media/indoor_image_4.jpg"
                  alt=""
                  className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const img = '/images/conference.jpg'
const d = (
  <div className="mx-auto  max-w-7xl lg:px-4 py-0">
    <div
      className="w-full rounded-none h-full  overflow-auto  bg-cover bg-fixed  bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${img})`,
      }}
    >
      <div className="mx-auto max-w-2xl  lg:max-w-none">
        <div className="grid grid-cols-1 items-center gap-x-16 gap-y-10 lg:grid-cols-2 py-0">
          <h2 className="text-8xl font-thin tracking-tight text-gray-100 px-10">
            <p>Conferences</p>
            <p className="text-lg tracking-normal font-bold indent-3">@ Basiliea Palace</p>
          </h2>
          <p className="m-4 bg-black/10 text-2xl text-gray-100 p-8  rounded-lg antialiased hover:subpixel-antialiased">
            Graduation is a significant milestone in every student's life, and
            it is a time to celebrate the hard work and dedication that they
            have put into their studies. As the school year comes to an end,
            many schools are looking for a venue to host their graduation
            ceremony. Basileia Palace, a luxurious hotel, is now offering its
            stunning outdoor venue to schools for their graduation ceremony.
            involved.
          </p>
          <p className="hidden">
            The outdoor venue at Basileia Palace is a perfect location for a
            graduation ceremony. The venue is spacious and can accommodate a
            large number of guests, making it an ideal choice for schools that
            have a large graduating class. The venue is also equipped with
            state-of-the-art technology, ensuring that the ceremony runs
            smoothly and that all guests can hear and see the proceedings. In
            addition to the stunning outdoor venue, Basileia Palace offers a
            range of amenities that will make the graduation ceremony even more
            special. The hotel's dining area can cater to the needs of the
            guests, providing them with delicious food and beverages to enjoy
            during the ceremony. The hotel also offers meeting rooms with
            state-of-the-art technology that can be used for speeches or
            presentations. Basileia Palace's outdoor venue is not only a
            beautiful location for a graduation ceremony, but it also provides a
            peaceful and relaxing atmosphere. The venue is surrounded by lush
            greenery and provides a serene environment that is perfect for
            celebrating the end of the school year. In conclusion, Basileia
            Palace's outdoor venue is an excellent choice for schools looking
            for a location to host their graduation ceremony. With its spacious
            and beautiful surroundings, state-of-the-art technology, and range
            of amenities, Basileia Palace is sure to make the graduation
            ceremony a memorable and special event for all
          </p>
          <div className="my-0 col-span-2 bg-white/10 rounded-xl">
            <Hero />
          </div>
        </div>
        <Elem />
        <PriceModule category='conference' />
      </div>
    </div>
  </div>
);

export default function ConferenceContentBlock() {
  return <ContentArea>{d}</ContentArea>;
}
