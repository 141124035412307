import React, { useContext } from "react";
import _ from "lodash";
import { isBrowser, isMobile } from "react-device-detect";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import CategoryBlock from "./CategoryBlock";
import ContentArea from "../ContentArea";
import { PriceModule, PriceModule_2 } from "../../components/PriceModule"
import { Divider } from "@aws-amplify/ui-react";

const incentives = [
  {
    name: "Podium",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "Basileia Palace offers podiums in their meeting rooms that are equipped with state-of-the-art technology and designed for the comfort of the speaker, providing a platform for effective and clear presentations during conferences and meetings.",
  },
  {
    name: "PA Systems",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "The PA system features advanced technology and equipment, including microphones, speakers, and amplifiers, that ensure clear and crisp sound quality. With the PA system, guests can enjoy seamless and effective communication during their meetings or events.",
  },
  {
    name: "WIFI Internet",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Basileia Palace provides secure and high-speed WiFi for guests in all areas of the hotel, enabling them to stay connected and productive during their stay.",
  },
  {
    name: "Projector",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "Basileia Palace offers projectors for use in their meeting rooms and outdoor celebrations venue, providing a high-quality audio-visual experience for presentations and entertainment. ",
  },
  {
    name: "Mini Bar",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "Basileia Palace offers a mini-bar for their guests, providing a convenient and refreshing selection of beverages and snacks for guests.",
  },
  {
    name: "Catering",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Guests can enjoy their meals in the dining areas or outdoor venue, which is a picturesque and tranquil oasis. The catering service is designed to provide guests with a memorable and enjoyable dining experience.",
  },
];


const Elem = () => (
  <div className="bg-white rounded-none">
    <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
      <div>
        <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
          {incentives.map((incentive) => (
            <div key={incentive.name} className="sm:flex lg:block">
              <div className="sm:flex-shrink-0">
                <img
                  className="hidden h-16 w-16"
                  src={incentive.imageSrc}
                  alt=""
                />
              </div>
              <div className="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                <h3 className="text-sm font-bold underline text-gray-800">
                  {incentive.name}
                </h3>
                <p className="mt-2 text-sm text-gray-800">
                  {incentive.description}
                </p>
              </div>
            </div>
          ))}
        </dl>
      </div>
      <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
        <img
          src="https://cdn.basileiapalaceja.com/media/repass/repass_5.jpg"
          alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
          className="rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/repass/repass_6.jpg"
          alt="Top down view of walnut card tray with embedded magnets and card groove."
          className="rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/repass/repass_2.jpg"
          alt="Side of walnut card tray with card groove and recessed card area."
          className="rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/repass/repass_8.jpg"
          alt="Walnut card tray filled with cards and card angled in dedicated groove."
          className="rounded-lg bg-gray-100"
        />
      </div>
    </div>
  </div>
);

function Hero() {
  return (
    <div className="overflow-hidden bg-white py-32">
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
      </div>
      <div className="mx-auto mt-10 max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid place-items-center max-w-2xl grid-cols-2 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
              <img
                src="https://cdn.basileiapalaceja.com/media/repass/repass_3.jpg"
                alt=""
                className="aspect-[10/5] w-[32rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
              />
            </div>
          </div>
          <div className="lg:col-end-2 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-thin uppercase tracking-tight text-gray-900 sm:text-4xl">
              Catering and Refreshments
            </h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">
              To ease the burden of organizing the event, we offer catering services that can provide a range of food and beverage options.
              Our skilled chefs will prepare a selection of dishes that can cater to different dietary preferences and cultural traditions.
              From comforting classics to custom menus, we are dedicated to ensuring that the culinary aspect of the repass or memorial meets your expectations.
            </p>
          </div>
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-thin uppercase tracking-tight text-gray-900 sm:text-4xl">Spacious Seating for Guests</h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">Our venue can comfortably seat over 150 guests, providing ample space for family, friends,
              and community members to come together in remembrance. We provide chairs, tables, podiums, and PA systems to ensure that every aspect of the
              gathering is taken care of and that everyone can participate and share their memories.</p>
          </div>
          <div className="lg:col-end-2 lg:w-full lg:max-w-lg lg:pb-8">
            <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
              <img
                src="https://cdn.basileiapalaceja.com/media/repass/repass_8.jpg"
                alt=""
                className="aspect-[12/5] w-[34rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
              />
            </div>
          </div>
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
              <img
                src="https://cdn.basileiapalaceja.com/media/repass/repass_3.jpg"
                alt=""
                className="aspect-[5/5] w-[27rem] max-w-none flex-none rounded-full bg-gray-50 object-cover"
              />
            </div>
          </div>
          <div className="lg:col-end-2 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-thin uppercase tracking-tight text-gray-900 sm:text-4xl">A Time to Remember</h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">
              At Basileia Palace, we believe in creating meaningful experiences that honor the lives of those who have passed.
              Our venue provides a serene and tranquil atmosphere, allowing you to gather with loved ones in a space that feels comforting and supportive.
              We invite you to trust us with the arrangements for your repass or memorial, knowing that we will handle every aspect with the utmost care and respect.
            </p>

          </div>
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">

            <h2 className="text-3xl font-thin uppercase tracking-tight text-gray-900 sm:text-4xl">
              Thoughtful and Compassionate Service
            </h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">
              Our experienced staff understands the sensitivity of repass and memorial events. We are committed to providing compassionate and attentive service,
              taking care of every detail so that you can focus on honoring and celebrating the life of your loved one.
              We will work closely with you to ensure that your vision is brought to life and that the gathering reflects the unique spirit and memories of the individual being honored.
            </p>

          </div>
          <div className="lg:col-end-2 lg:w-full lg:max-w-lg lg:pb-8">
            <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
              <img
                src="https://cdn.basileiapalaceja.com/media/repass/repass_3.jpg"
                alt=""
                className="aspect-[5/5] w-[27rem] max-w-none flex-none rounded-full bg-gray-50 object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const d = (
  <div className="mx-auto  max-w-7xl lg:px-0 py-0">
    <div
      className="w-full rounded-none h-full bg-fixed  overflow-auto bg-cover bg-fixed bg-center bg-no-repeat"
      style={{
        //backgroundImage: `url('https://assets.teenvogue.com/photos/5e66a34f7ac2c30008a4819d/16:9/w_2560%2Cc_limit/GettyImages-1062799214.jpg')`,
        backgroundImage: `url('../images/flowers.jpg')`
      }}
    >
      <div className="mx-auto max-w-7xl py-8  lg:max-w-none">
        <div className=" grid grid-cols-1 items-center gap-x-16 gap-y-0 lg:grid-cols-2 py-0">
          <h2 className="text-8xl font-thin tracking-tight text-gray-100 px-10">
            <p>Repass and Memorial</p>
            <p className="text-lg tracking-normal font-bold indent-3">
              @ Basiliea Palace
            </p>
          </h2>
          <p className="m-4 text-2xl text-gray-100 p-8  rounded-lg antialiased hover:subpixel-antialiased">
            Honoring the memory of your loved ones is a heartfelt and important occasion. Our compassionate team understands the significance of repasses and memorials, providing a serene and comforting environment for family and friends to gather and reminisce. Let us handle the details with sensitivity and care, allowing you to focus on cherishing the memories of your loved ones.
          </p>
          <div className="my-0 col-span-2 bg-white rounded-none">
            <Hero />
          </div>
          <div className="grid px-3 my-0  col-span-2 gap-3 bg-black/40 backdrop-blur min-h-[10rem]">

            <p class="text-white text-6xl">Celebrate a Life Well-Lived</p>
            <p class="text-white text-xl">Contact us today to discuss your repass or memorial requirements and reserve our venue for a heartfelt gathering. Let us help you create a meaningful event that honors the memory of your loved one and brings comfort to those in attendance.</p>
          </div>
        </div>
        <Elem />
        <PriceModule category='repass' />
      </div>
    </div>
  </div>
);

export default function RepassContentBlock() {
  return <ContentArea>{d}</ContentArea>;
}
