import { Fragment, useContext } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  MagnifyingGlassIcon,
  ShoppingBagIcon,
} from "@heroicons/react/24/outline";

import {
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
  RectangleGroupIcon,
} from "@heroicons/react/20/solid";
import { ContextServiceApi } from "../../context/ContextServiceApi";



function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function EventPopOver(props) {
  const {products} = new useContext(ContextServiceApi)
  return (
    <>
      <Popover className="flex">
        {({ open }) => (
          <>
            <div className="relative flex">
              <Popover.Button
                className={classNames(
                  open
                    ? "border-amber-600 text-amber-600"
                    : "border-transparent text-gray-700 hover:text-gray-800",
                  "relative z-10 -mb-px flex items-center border-b-4 pt-px text-sm font-medium transition-colors duration-200 ease-out"
                )}
              >
                Events
              </Popover.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Popover.Panel className="absolute inset-x-0 bottom-full text-gray-500 sm:text-sm">
                {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                <div
                  className="absolute inset-0 bottom-1/2 bg-white shadow"
                  aria-hidden="true"
                />

                <div className="relative bg-gradient-to-t from-amber-500 to-amber-400 ">
                  <div className="mx-auto px-4 sm:px-6 lg:px-8">
                    <div
                      className="w-full overflow-x-scroll grid grid-flow-col gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8
                      snap-x snap-mandatory"
                    >
                      {products.map((item) => (
                        <div
                          key={item.name}
                          className="snap-normal snap-center group relative rounded-lg w-[220px] p-6 text-sm leading-6 hover:bg-gray-50"
                        >
                          <div className="hidden flex h-11 w-11 items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                            <item.icon
                              className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                              aria-hidden="true"
                            />
                          </div>
                          <a
                            href={item.href}
                            className="mt-6 block font-semibold text-gray-900"
                          >
                            {item.name}
                            <span className="absolute inset-0" />
                          </a>
                          <p className="mt-1 text-gray-600">
                            {item.description}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
}
