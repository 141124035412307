import React, { useContext } from "react";
import _ from "lodash";
import { MainLayout } from "../../layouts/mainLayout";
import Footer_alt from "../../components/Footer/Footer_alt";
import ContentArea from "../../components/ContentArea";
import BirthdayContentBlock from "../../components/ContentAreaBlocks/BirthdayContentBlock"

export default function BirthdayPage() {
  return (
    <>
      <MainLayout>
        <BirthdayContentBlock />
        <Footer_alt />
      </MainLayout>
    </>
  );
}
