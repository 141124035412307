import React, { useContext } from "react";
import _ from "lodash";
import { MainLayout } from "../layouts/mainLayout";
import Footer_alt from "../components/Footer/Footer_alt";
import ContentArea from "../components/ContentArea";
import Hero_1 from "../components/Hero_1";
import HomeContentBlock from "../components/ContentAreaBlocks/HomeContentBlock";
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
    Bars3Icon,
    BellIcon,
    CalendarIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon, PhotoIcon, UserCircleIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import { Amplify } from 'aws-amplify';

import '@aws-amplify/ui-react/styles.css';

import { PlusIcon } from '@heroicons/react/20/solid'
import {
    EventRequestCreateForm
} from '../ui-components';
import { Storage } from "@aws-amplify/storage"
import { DataStore } from '@aws-amplify/datastore';
import { EventRequest } from '../models';
import awsExports from '../aws-exports';
import NewEventRequest from "../components/NewEventRequest";
Amplify.configure(awsExports);


export default function RequestedEventTable(props) {

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                                    >
                                        Type
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                    >
                                        Date
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                    >
                                        Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                    >
                                        Phone
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                    >
                                        #
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                    >
                                        Status
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                    >
                                        Price
                                    </th>
                                    <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-0">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {props.quotes.map((quote) => (
                                    <tr key={quote.date+quote.email}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                            {quote.eventType}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{quote.date}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{quote.name}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{quote.phone}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{quote.partySize}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{quote.status}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{quote.price}</td>
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                            <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                                View<span className="sr-only">, {quote.eventType}</span>
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
