import React, { useContext } from "react";
import _ from "lodash";
import { isBrowser, isMobile } from "react-device-detect";
import { MainLayout } from "../layouts/mainLayout";

import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";

export default function Hero_1(props) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const img_p_w = props.w;
  const imageFeatures = [
    {
      src: "https://cdn.basileiapalaceja.com/media/building/building_26.jpg",
      opac: 100,
      w: img_p_w,
      l: 0,
      t: 0,
    },
    {
      src: "https://cdn.basileiapalaceja.com/media/indoor_image_2.jpg",
      opac: 100,
      w: img_p_w - img_p_w * 0.33,
      l: img_p_w / 2 - (img_p_w - img_p_w * 0.33) / 2,
      t: img_p_w / 2 - (img_p_w - img_p_w * 0.33) / 2,
    },
    {
      src: "https://cdn.basileiapalaceja.com/media/building/building_13.jpg",
      opac: 100,
      w: img_p_w - img_p_w * 0.66,
      l: img_p_w / 2 - (img_p_w - img_p_w * 0.66) / 2,
      t: img_p_w / 2 - (img_p_w - img_p_w * 0.66) / 2,
    },
  ];

  const imageGrid = (
    <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
      <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
        <div className="relative">
          <img
            src="https://cdn.basileiapalaceja.com/media/birthday/birthday_6.jpg"
            alt=""
            className="aspect-[1/1] w-full rounded-full bg-gray-900/5 object-cover shadow-lg ring-2 ring-white"
          />
          <div className="pointer-events-none absolute inset-0" />
        </div>
      </div>
      <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
        <div className="relative">
          <img
            src="https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_9.jpg"
            alt=""
            className="aspect-[1/1] w-full rounded-full bg-gray-900/5 object-cover shadow-lg ring-2 ring-white"
          />
          <div className="pointer-events-none absolute inset-0" />
        </div>
        <div className="relative">
          <img
            src="https://cdn.basileiapalaceja.com/media/building/building_9.jpg"
            alt=""
            className="aspect-[1/1] w-full rounded-full bg-gray-900/5 object-cover shadow-lg ring-2 ring-white"
          />
          <div className="pointer-events-none absolute inset-0" />
        </div>
      </div>
      <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
        <div className="relative">
          <img
            src="https://images.unsplash.com/photo-1670272504528-790c24957dda?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=left&w=400&h=528&q=80"
            alt=""
            className="aspect-[1/1] w-full rounded-full bg-gray-900/5 object-cover shadow-lg ring-2 ring-white"
          />
          <div className="pointer-events-none absolute inset-0" />
        </div>
        <div className="relative">
          <img
            src="https://images.unsplash.com/photo-1670272505284-8faba1c31f7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
            alt=""
            className="aspect-[1/1] w-full rounded-full bg-gray-900/5 object-cover shadow-lg ring-2 ring-white"
          />
          <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
        </div>
      </div>
      <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
        <div className="relative">
          <img
            src="https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_9.jpg"
            alt=""
            className="aspect-[1/1] w-full rounded-full bg-gray-900/5 object-cover shadow-lg ring-2 ring-white"
          />
          <div className="pointer-events-none absolute inset-0" />
        </div>
        <div className="relative">
          <img
            src="https://cdn.basileiapalaceja.com/media/building/building_9.jpg"
            alt=""
            className="aspect-[1/1] w-full rounded-full bg-gray-900/5 object-cover shadow-lg ring-2 ring-white"
          />
          <div className="pointer-events-none absolute inset-0" />
        </div>
        <div className="relative">
          <img
            src="https://cdn.basileiapalaceja.com/media/building/building_9.jpg"
            alt=""
            className="aspect-[1/1] w-full rounded-full bg-gray-900/5 object-cover shadow-lg ring-2 ring-white"
          />
          <div className="pointer-events-none absolute inset-0" />
        </div>
      </div>
    </div>
  );

  const imageGridFlex = (
    <div
      className={`relative   grid place-items-center`}
      style={{
        height: `${img_p_w}px`,
        width: `${img_p_w}px`,
      }}
    >
      <div
        className={`absolute top-0 left-0`}
        style={{
          height: `${img_p_w}px`,
          width: `${img_p_w}px`,
        }}
      >
        {imageFeatures.map((img, index) => (
          <div
            key={index}
            className={`absolute opacity-${img.opac}`}
            style={{
              left: `${img.l}px`,
              top: `${img.t}px`,
              width: `${img.w}px`,
            }}
          >
            <img
              src={img.src}
              alt=""
              className="aspect-[1/1] w-full rounded-full  object-cover shadow-lg shadow-black/60"
            />
            <div className="pointer-events-none absolute inset-0" />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="bg-black/5 backdrop-blur-lg">
      <main>
        <div className="relative isolate">
          <svg
            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
            />
          </svg>
          <div className="absolute top-0 left-1/2 right-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48">
            <svg
              viewBox="0 0 801 1036"
              aria-hidden="true"
              className="w-[50.0625rem] mix-blend-multiply"
            >
              <path
                fill="url(#70656b7e-db44-4b9b-b7d2-1f06791bed52)"
                fillOpacity=".3"
                d="m282.279 843.371 32.285 192.609-313.61-25.32 281.325-167.289-58.145-346.888c94.5 92.652 277.002 213.246 251.009-45.597C442.651 127.331 248.072 10.369 449.268.891c160.956-7.583 301.235 116.434 351.256 179.39L507.001 307.557l270.983 241.04-495.705 294.774Z"
              />
              <defs>
                <linearGradient
                  id="70656b7e-db44-4b9b-b7d2-1f06791bed52"
                  x1="508.179"
                  x2="-28.677"
                  y1="-116.221"
                  y2="1091.63"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#9089FC" />
                  <stop offset={1} stopColor="#FF80B5" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="grid place-content-center overflow-hidden ">
            <div className="w-[600px] px-0 pb-0 pt-0 sm:pt-0 lg:px-8 lg:pt-0 pb-4">
              <div className="mx-auto max-w-2xl gap-x-10 lg:mx-0 lg:flex lg:max-w-none lg:items-center ">
                <div className="flex pt-0 flex-col w-full max-w-xl lg:shrink-0 xl:max-w-2xl bg-amber-100/100 text-black backdrop-blur-2xl p-3 rounded-b-full bg-gradient-to-r from-white/100 via-amber-300/90 to-amber-100/20 text-center">
                  <div className="grow">
                    <h1 className="pb-4 text-4xl font-bold tracking-tight  sm:text-6xl">
                      Basileia Palace
                    </h1>
                    <h1 className="text-4xl  font-thin tracking-tight sm:text-6xl">
                      changes the way people connect.
                    </h1>
                    <p className="relative mt-6 text-md leading-8 sm:max-w-md lg:max-w-none text-justify p-3">
                      <strong className="text-4xl font-thin pl-1">B</strong>
                      asileia Palace established itself as a premier, luxury
                      mini hotel when it opened its doors in 2017. Whether
                      visitors are traveling for business or pleasure, Basileia
                      Palace offers quality hospitality and unrivaled
                      facilities. Offering a calm and professional environment,
                      our discerning guests will encounter immaculate service
                      that caters to their every need. Our dedicated team is
                      available to make every aspect of your stay with us at
                      Basileia Palace an enjoyable and pleasurable experience.
                      Call today for room or event packages as well as upcoming
                      deals and specials.
                      <span className="font-bold bg-black/20 rounded-lg mx-2 p-1">
                        {" "}
                        <a href="/about">Read More...</a>
                      </span>
                    </p>
                  </div>

                  <div className="h-fit mx-auto max-w-xl lg:shrink-0 xl:max-w-2xl  p-0 rounded-lg ">
                    {imageGridFlex}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
