import React, { useContext } from "react";
import _ from "lodash";
import { isBrowser, isMobile } from "react-device-detect";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import CategoryBlock from "./CategoryBlock";
import ContentArea from "../ContentArea";
import { PriceModule, PriceModule_2 } from "../../components/PriceModule"

const incentives = [
  {
    name: "PA Systems",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "The PA system features advanced technology and equipment, including microphones, speakers, and amplifiers, that ensure clear and crisp sound quality. With the PA system, guests can enjoy seamless and effective communication during their meetings or events.",
  },
  {
    name: "WIFI Internet",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Basileia Palace provides secure and high-speed WiFi for guests in all areas of the hotel, enabling them to stay connected and productive during their stay.",
  },
  {
    name: "Projector",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "Basileia Palace offers projectors for use in their meeting rooms and outdoor celebrations venue, providing a high-quality audio-visual experience for presentations and entertainment. ",
  },
  {
    name: "Mini Bar",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "Basileia Palace offers a mini-bar for their guests, providing a convenient and refreshing selection of beverages and snacks for guests.",
  },
  {
    name: "Catering",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Guests can enjoy their meals in the dining areas or outdoor venue, which is a picturesque and tranquil oasis. The catering service is designed to provide guests with a memorable and enjoyable dining experience.",
  },
];


const Elem = () => (
  <div className="bg-white/50 backdrop-blur-sm rounded-lg">
    <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
      <div>

        <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
          {incentives.map((incentive) => (
            <div key={incentive.name} className="sm:flex lg:block">
              <div className="sm:flex-shrink-0">
                <img
                  className="hidden h-16 w-16"
                  src={incentive.imageSrc}
                  alt=""
                />
              </div>
              <div className="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                <h3 className="text-sm font-bold underline text-gray-800">
                  {incentive.name}
                </h3>
                <p className="mt-2 text-sm text-gray-800">
                  {incentive.description}
                </p>
              </div>
            </div>
          ))}
        </dl>
      </div>
      <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
        <img
          src="https://cdn.basileiapalaceja.com/media/birthday/birthday_60.jpg"
          alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
          className="rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/birthday/birthday_30.jpg"
          alt="Top down view of walnut card tray with embedded magnets and card groove."
          className="rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/birthday/birthday_35.jpg"
          alt="Side of walnut card tray with card groove and recessed card area."
          className="rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/birthday/birthday_45.jpg"
          alt="Walnut card tray filled with cards and card angled in dedicated groove."
          className="rounded-lg bg-gray-100"
        />
      </div>
    </div>
  </div>
);

function Hero() {
  return (
    <div className="overflow-hidden bg-white py-32">
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-thin uppercase tracking-tight text-gray-900 sm:text-4xl">
            Let's Celebrate Together
            </h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">
            At Basileia Palace, we believe that every milestone deserves a remarkable celebration. Whether you're commemorating a birthday or applauding the achievements of graduates, our luxurious hotel provides the perfect backdrop for unforgettable moments.
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">
            Make your birthday party an affair to remember at Basileia Palace. Our stunning indoor and outdoor venues offer versatile spaces to suit your preferences. From intimate gatherings to grand celebrations, we have the perfect setting to create magical memories that will be cherished for years to come.
            </p>
          </div>
          <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
              <img
                src="https://cdn.basileiapalaceja.com/media/birthday/birthday_33.jpg"
                alt=""
                className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover object-top"
              />
            </div>
            <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
              <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                <img
                  src="https://cdn.basileiapalaceja.com/media/birthday/birthday_22.jpg"
                  alt=""
                  className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover  object-top"
                />
              </div>
              <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                <img
                  src="https://cdn.basileiapalaceja.com/media/birthday/birthday_1.jpg"
                  alt=""
                  className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover  object-top"
                />
              </div>
              <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                <img
                  src="https://cdn.basileiapalaceja.com/media/birthday/birthday_12.jpg"
                  alt=""
                  className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover object-top"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const d = (
  <div className="mx-auto  max-w-7xl lg:px-4 py-0">
    <div
      className="w-full rounded-none h-full  overflow-auto bg-fixed  bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url('../images/confetti.jpg')`,
      }}
    >
      <div className="mx-auto max-w-7xl py-8  lg:max-w-none">
        <div className="grid grid-cols-1 items-center gap-x-16 gap-y-10 lg:grid-cols-2 py-0">
          <h2 className="text-8xl font-thin tracking-tight text-gray-800 px-10">
            <p>Birthday Parties</p>
            <p className="text-lg tracking-normal font-bold indent-3">@ Basiliea Palace</p>
          </h2>
          <p className="m-4 bg-white/50 backdrop-blur-sm text-2xl text-gray-800 p-8  rounded-lg antialiased hover:subpixel-antialiased">
          Ignite the spirit of celebration with our exciting and vibrant birthday party venues. Whether it's a milestone event or a themed extravaganza, our venues offer a lively and engaging ambiance for unforgettable birthday festivities. Dance, laugh, and make memories that will be treasured for years to come.
          </p>
          <div className="my-0 col-span-2 bg-white rounded-xl">
            <Hero />
          </div>
        </div>
        <Elem />
        <PriceModule category='birthday' />
      </div>
    </div>
  </div>
);

export default function BirthdayContentBlock() {
  return <ContentArea>{d}</ContentArea>;
}
