import React, { useContext } from "react";
import _ from "lodash";
import { isBrowser, isMobile } from "react-device-detect";
import {
  CheckCircleIcon, CheckIcon
} from "@heroicons/react/24/outline";
import { ContextServiceApi } from "../context/ContextServiceApi";

const wedding_tiers = [
  {
    name: "Venue Starting Price",
    id: "tier-basic",
    href: `/quote?v=wedding`,
    price: "35,000 JMD",
    description: "The venue will be ready and prepared for you.",
    features: [
      "Music & PA System",
      "Up to 100 Patrons",
      "Main Table setup",
      "Catering",
    ],
  }
];
const pool_tiers = [
  {
    name: "Venue Starting Price",
    id: "tier-basic",
    href: `/quote?v=pool`,
    price: "35,000 JMD",
    description: "The venue will be ready and prepared for you.",
    features: [
      "Music & PA System",
      "Up to 100 Patrons",
      "Main Table setup",
      "Catering",
    ],
  },
];
const birthday_tiers = [
  {
    name: "Venue Starting Price",
    id: "tier-basic",
    href: `/quote?v=birthday`,
    price: "35,000 JMD",
    description: "The venue will be ready and prepared for you.",
    features: [
      "Music & PA System",
      "Up to 80 chairs and seating",
      "Main Table setup",
      "Catering",
    ],
  },
];
const outdoor_tiers = [
  {
    name: "Venue Starting Price",
    id: "tier-basic",
    href: ` /quote?v=outdoor`,
    price: "35,000 JMD",
    description: "The venue will be ready and prepared for you.",
    features: [
      "Music & PA System",
      "Up to 80 chairs and seating",
      "Main Table setup",
      "Catering",
    ],
  },
];
const graduation_tiers = [
  {
    name: "Venue Starting Price",
    id: "tier-basic",
    href: `/quote?v=outdoor`,
    price: "35,000 JMD",
    description: "The venue will be ready and prepared for you.",
    features: [
      "Music & PA System",
      "Up to 80 chairs and seating",
      "Main Table setup",
      "Catering",
    ],
  },
];
const repass_tiers = [
  {
    name: "Venue Starting Price",
    id: "tier-basic",
    href: `/quote?v=outdoor`,
    price: "35,000 JMD",
    description: "The venue will be ready and prepared for you.",
    features: [
      "Music & PA System",
      "Up to 80 chairs and seating",
      "Main Table setup",
      "Catering",
    ],
  },
];
const babyshower_tiers = [
  {
    name: "Venue Starting Price",
    id: "tier-basic",
    href: `/quote?v=outdoor`,
    price: "35,000 JMD",
    description: "The venue will be ready and prepared for you.",
    features: [
      "Music & PA System",
      "Up to 80 chairs and seating",
      "Main Table setup",
      "Catering",
    ],
  },
];
const catering_tiers = [
  {
    name: "Venue Starting Price",
    id: "tier-basic",
    href: `/quote?v=outdoor`,
    price: "Negotiable",
    description: "The venue will be ready and prepared for you.",
    features: [
      "Music & PA System",
      "Up to 80 chairs and seating",
      "Main Table setup",
      "Catering",
    ],
  },
];
const conference_tiers = [
  {
    name: "Venue Starting Price",
    id: "tier-basic",
    href: `/quote?v=outdoor`,
    price: "35,000 JMD",
    description: "The venue will be ready and prepared for you.",
    features: [
      "Music & PA System",
      "Up to 80 chairs and seating",
      "Main Table setup",
      "Catering",
    ],
  },
];
const general_tiers = [
  {
    name: "Venue Starting Price",
    id: "tier-basic",
    href: `/quote?v=general`,
    price: "Negotiable",
    description: "The venue will be ready and prepared for you.",
    features: [
      "PA System",
      "Up to 100 Patrons",
      "Free Fast Wifi",
      "Mini Bar"
    ],
  },
];
const photoshoot_tiers = [
  {
    name: "Venue Starting Price",
    id: "tier-basic",
    href: `/quote?v=general`,
    price: "Negotiable",
    description: "The venue will be ready and prepared for you.",
    features: [
      "Opulent Interiors",
      "Breathtaking Gardens",
      "Wifi",
      "Mini Bar"
    ],
  },
];

let TierGroup = (category) => {
  switch (category) {
    case 'wedding': return wedding_tiers; break;
    case 'pool': return pool_tiers; break;
    case 'birthday': return birthday_tiers; break;
    case 'outdoor': return outdoor_tiers; break;
    case 'graduation': return graduation_tiers; break;
    case 'repass': return repass_tiers; break;
    case 'babyshower': return babyshower_tiers; break;
    case 'catering': return catering_tiers; break;
    case 'conference': return conference_tiers; break;
    case 'photoshoot': return photoshoot_tiers; break;
    default: return general_tiers;
  }
}

export function PriceModule_2(props) {
  const prices = TierGroup(props.category)
  return (
    <div className="isolate overflow-hidden  mt-8 rounded-xl">
      <div className="mx-auto max-w-7xl px-6 pb-96 pt-24 text-center sm:pt-32 lg:px-8">
        <div className="mx-auto max-w-4xl">
          <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
            The right price for you,{" "}
            <br className="hidden sm:inline lg:hidden" />
            whoever you are
          </p>
        </div>
      </div>
      <div className="flow-root pb-24 sm:pb-32">
        <div className="-mt-80">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl">
              {prices.map((tier) => (
                <div
                  key={tier.id}
                  className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
                >
                  <div>
                    <h3
                      id={tier.id}
                      className="uppercase font-semibold leading-7 text-black"
                    >
                      {tier.name}
                    </h3>
                    <div className="mt-4 flex items-baseline gap-x-2">
                      <span className="text-5xl font-bold tracking-tight text-gray-900">
                        {tier.price}
                      </span>
                    </div>
                    <p className="mt-6 text-base leading-7 text-gray-600">
                      {tier.description}
                    </p>
                    <ul
                      role="list"
                      className="mt-10 space-y-4 text-sm leading-6 text-gray-600"
                    >
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3">
                          <CheckIcon
                            className="h-6 w-5 flex-none text-amber-600"
                            aria-hidden="true"
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <a
                    href={tier.href}
                    aria-describedby={tier.id}
                    className="mt-8 block rounded-md bg-amber-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
                  >
                    Request Quote
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function OtherProducts() {
  const { products } = new useContext(ContextServiceApi)
  return (
    <div className="bg-gradient-to-t from-yellow-400 to-amber-700  rounded-xl xs:mx-2 sm:mx-12 shadow-2xl xs:mt-6">
      <div className="mx-auto max-w-7xl ">
        <div className="mx-6 grid grid-cols-2 gap-0.5 overflow-hidden sm:mx-0 sm:rounded-2xl md:grid-cols-3">
          {
            products.map((product) => (

              <a href={product.href}>
                <div className="grid place-items-center content-center bg-white/5 p-3 sm:p-5 cursor-pointer
              block from-amber-200 to-yellow-500 px-3 py-2 text-center text-sm font-semibold leading-6 text-green-800
              shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
              hover:bg-gradient-to-bl hover:shadow-xl h-[4rem]">
                  <span className="font-bold text-white text-xs uppercase align-center">{product.name}</span>
                </div>
              </a>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export function PriceModule(props) {
  const prices = TierGroup(props.category)
  return (
    <div className="bg-no-repeat bg-gradient-to-t from-amber-600 to-yellow-500 mt-0 py-4 rounded-t-none sm:mb-6 ">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mt-2 grid xs:grid-cols-1 sm:grid-cols-2 bg-gradient-to-b from-green-800 to-green-500 py-4 rounded-lg flow-root">
          <div className={`grid max-w-sm grid-cols-1  gap-y-6 divide-y divide-gray-100 sm:mx-auto  lg:mt-0 lg:max-w-none  lg:divide-x lg:divide-y-0  px-6`}>
            {prices.map((tier) => (
              <div key={tier.id} className="pt-6 lg:px-8 lg:pt-0 xl:px-14">
                <h3
                  id={tier.id}
                  className="hidden text-base font-semibold leading-7 text-gray-900"
                >
                  {tier.name}
                </h3>
                <p className="hidden mt-6 flex items-baseline gap-x-1">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">
                    {tier.price}
                  </span>
                </p>
                <a
                  href={tier.href}
                  aria-describedby={tier.id}
                  className="mt-10 block rounded-md bg-gradient-to-br from-amber-200 to-yellow-500 px-3 py-2 text-center text-sm font-semibold leading-6 text-green-800 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
                  hover:bg-gradient-to-bl hover:shadow-xl"
                >
                  Request Quote 
                </a>
                <ul
                  role="list"
                  className="mt-6 space-y-3 text-xl text-center leading-6 text-gray-600"
                >
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckCircleIcon
                        className="h-6 w-5 flex-none text-green-300"
                        aria-hidden="true"
                      />
                      <span className="text-green-200">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="bg-white/0 grid place-items-center">
            <OtherProducts />
          </div>
        </div>
      </div>
    </div>
  );
}