import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ProviderServiceApi from './context/ProviderServiceApi'
import AuthPage from "./pages/admin/AuthPage";

import RoomPage from "./pages/RoomPage";
import StandardRoomPage from "./pages/room/StandardRoomPage";
import DeluxeRoomPage from "./pages/room/DeluxeRoomPage";
import LuxuryRoomPage from "./pages/room/LuxuryRoomPage";
import FullHousePage from "./pages/room/FullHousePage";

import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import EventPage from "./pages/EventPage";
import ContactPage from "./pages/ContactPage";
import QuotePage from "./pages/QuotePage";
import CorporatePage from "./pages/events/CorporatePage";
import OutdoorCelebrationPage from "./pages/events/OutdoorCelebrationPage";
import PoolPage from "./pages/events/PoolPage";
import PhotoshootPage from "./pages/events/PhotoshootPage";
import RepassPage from "./pages/events/RepassPage";
import BabyShowerPage from "./pages/events/BabyShowerPage";
import BirthdayPage from "./pages/events/BirthdayPage";
import CateringPage from "./pages/events/CateringPage";
import ConferencePage from "./pages/events/ConferencePage";
import WeddingPage from "./pages/events/WeddingPage";

import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import './App.css';

import { Amplify, Hub } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { DataStore, Predicates } from "@aws-amplify/datastore";
import awsExports from './aws-exports';
import { UserProfile } from './models';

Amplify.configure(awsExports);
DataStore.configure(awsExports);

console.log(`Datastore ${DataStore.state}`)

console.log(DataStore)

async function init() {
  await Amplify.DataStore.stop()
  await Amplify.DataStore.clear();
  await Amplify.DataStore.start();
}



init()


// Create listener
/* let listener = Hub.listen('datastore', async hubData => {
  const { event, data } = hubData.payload;

  console.log(event)
  if (event === 'networkStatus') {
    console.log(`User has a network connection: ${data.active}`)
  }
})

// Remove listener
listener(); */

setInterval(() => {
  //  console.log(`Datastore ${DataStore.state}`)
}, 1000)



var signed_in = false

function initProfile(payload) {
  console.log(`creating new profile...`)
  return DataStore.save(
    new UserProfile({
      "userID": payload.attributes.sub,
      "Name": payload.attributes.name,
      "Address": "",
      "Country": "",
      "State": "",
      "Phone": payload.attributes.phone_number,
      "Email": payload.attributes.email,
      "DOB": null,
      "permissions": {} /* Provide init commands */
    })
  );
}


Hub.listen('auth', ({ payload }) => {

  const { event } = payload;
  console.log(payload)
  console.log(event)


  if (event === 'signUp') {
    console.log("signed up")
  }
  if (event === 'confirmSignUp') {
    console.log("confirm signed up")
  }
  if (event === 'autoSignIn') {
    console.log("auto signing in")
    initProfile(payload.data)
  }
  /*  if (event === 'signOut') {
      console.log("signed out")
      DataStore.clear();
    }
    if (event === 'signIn') {
      console.log("Signed In")
      const fx = async () => await DataStore.start();
      fx().then((r) => { console.log(r) })
      signed_in = true
    }
  
    if (event === 'configured') {
      console.log("Configured")
      const fx = async () => await DataStore.start();
      console.log(fx)
      fx().then((r) => { console.log(r) })
      signed_in = true
    }*/



})
//const fx = async () => await DataStore.observeQuery();
/* setInterval(() => {
  fx().then((r) => {
    console.log(r)
  })
}, 2000); */

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ProviderServiceApi>
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/about" element={<AboutPage />} />
        <Route exact path="/contact" element={<ContactPage />} />
        <Route exact path="/quote" element={<QuotePage />} />
        <Route exact path="/events" element={<EventPage />} />
        <Route exact path="/events/corporate" element={<CorporatePage />} />
        <Route exact path="/events/outdoorcelebration" element={<OutdoorCelebrationPage />} />
        <Route exact path="/events/pool" element={<PoolPage />} />
        {<Route exact path="/events/photoshoot" element={<PhotoshootPage />} />}
        <Route exact path="/events/repass" element={<RepassPage />} />
        <Route exact path="/events/babyshower" element={<BabyShowerPage />} />
        <Route exact path="/events/birthday" element={<BirthdayPage />} />
        <Route exact path="/events/catering" element={<CateringPage />} />
        {/* <Route exact path="/events/conference" element={<ConferencePage/>}/> */}
        <Route exact path="/events/wedding" element={<WeddingPage />} />
        <Route exact path="/auth" element={<AuthPage />} />
        <Route exact path="/room" element={<RoomPage />} />
        <Route exact path="/room/standard" element={<StandardRoomPage />} />
        <Route exact path="/room/deluxe" element={<DeluxeRoomPage />} />
        <Route exact path="/room/luxury" element={<LuxuryRoomPage />} />
        <Route exact path="/room/fullhouse" element={<FullHousePage />} />

      </Routes>
    </Router>
  </ProviderServiceApi>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
