import React, { useContext } from "react";
import _, { isEmpty } from "lodash";
import { MainLayout } from "../layouts/mainLayout";
import { MobileLayout } from "../layouts/mobileLayout";
import Footer_alt from "../components/Footer/Footer_alt";
import ContentArea from "../components/ContentArea";
import Hero_1 from "../components/Hero_1";
import HomeContentBlock from "../components/ContentAreaBlocks/HomeContentBlock";
import { Amplify, Auth, Hub } from 'aws-amplify';
import { DataStore, syncExpression } from 'aws-amplify/';
import { EventRequest, UserProfile } from '../models';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import MobileHomeContentBlock from "../components/Mobile/MobileContentAreaBlocks/MobileHomeContentBlock";




Hub.listen('auth', ({ payload }) => {

  const { event } = payload;

  //console.log(payload)
  if (payload.event === 'signOut') {
    console.log("signed out")
    DataStore.clear();
  }
  if (payload.event === 'signIn') {
    console.log("signed In")
  }

  /*  if (event === 'autoSignIn') {
     const user = payload.data;
 
     console.log("autoSignIn")
     DataStore.configure({
       syncExpressions: [
         syncExpression(EventRequest),
         syncExpression(UserProfile)
       ]
     });
     console.log("assign user")
     // assign user
   } else if (event === 'autoSignIn_failure') {
     console.log("autoSignIn_failure")
     // redirect to sign in page
   } */

})

export default function HomePage() {



  return (
    <> <BrowserView>
      <MainLayout>
        <HomeContentBlock>
          <Hero_1 w={500} />
        </HomeContentBlock>
        <Footer_alt Auth={Auth.user} />
      </MainLayout>
    </BrowserView>
      <MobileView>
        <MobileLayout>
        <MobileHomeContentBlock/>
        </MobileLayout>
      </MobileView>
    </>
  );
}
