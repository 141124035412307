import React, { useContext, useEffect } from "react";
import _, { first, isEmpty } from "lodash";
import { MainLayout } from "../../layouts/mainLayout";
import Footer_alt from "../../components/Footer/Footer_alt";
import ContentArea from "../../components/ContentArea";
import Hero_1 from "../../components/Hero_1";
import HomeContentBlock from "../../components/ContentAreaBlocks/HomeContentBlock";
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  BuildingOffice2Icon,
  EnvelopeIcon,
  PhoneIcon,
  PencilIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import { Amplify, Hub } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { DataStore, Predicates } from "@aws-amplify/datastore";

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { EventRequest, UserProfile } from '../../models';
import awsExports from '../../aws-exports';
import DataTabContainer from "../../components/DataTabContainer";
Amplify.configure(awsExports);
DataStore.configure(awsExports);



var signed_in = false

//DataStore.start();
Hub.listen('auth', ({ payload }) => {

  const { event } = payload;
  console.log(payload)
  console.log(event)
  if (event === 'signOut') {
    console.log("signed out")
    DataStore.clear();
  }
  if (event === 'signIn') {
    console.log("Signed In")
    //const fx = async () => await DataStore.start();
    //fx().then((r) => { console.log(r) })
    signed_in = true
  }

  if (event === 'configured') {
    console.log("Configured")
    // const fx = async () => await DataStore.start();
    //console.log(fx)
    //fx().then((r) => { console.log(r) })
    signed_in = true
  }



})
const fx = async () => await DataStore.observeQuery();
setInterval(() => {
  fx().then((r) => {
    //console.log(r) 
  })
}, 2000);


async function fetchProfile(username) {
  return await DataStore.query(UserProfile/* , Predicates.ALL, (p) => p.userID.contains(username) */);
}

function initProfile(payload) {
  console.log(`creating new profile...`)
  return DataStore.save(
    new UserProfile({
      "userID": payload.attributes.sub,
      "Name": payload.attributes.name,
      "Address": "",
      "Country": "",
      "State": "",
      "Phone": payload.attributes.phone_number,
      "Email": payload.attributes.email,
      "DOB": null,
      "permissions": {} /* Provide init commands */
    })
  );
}


async function uploadSample() {

  /* await Storage.list('') // for listing ALL files without prefix, pass '' instead
    .then(result => console.log(result))
    .catch(err => console.log(err)); */


  Storage.get('test.txt', {
    download: true,
    progressCallback(progress) {
      console.log(`Downloaded: ${progress.loaded}/${progress.total}`);
    }
  });
}

function App({ signOut, user }) {
  return (
    <>
      <h1>Hello {user.username}</h1>
      <button onClick={signOut}>Sign out</button>
    </>
  );
}

export default withAuthenticator(AuthPage);

export function AuthPage({ signOut, user }) {
  //console.log(user)

  const [ds, setDs] = new useState(DataStore.state)
  const [i, setI] = new useState(null)
  const [profile, setProfile] = new useState()

  /*   async function fetchProfile() {
      console.log(user.attributes)
      await DataStore.query(UserProfile, Predicates.ALL, (p) => p.userID.eq(user.attributes.sub)).then((data) => {
        console.log(data)
       // const p = _.find(data, (d) => d.email == user.attributes.email);
       // console.log(p)
        setProfile(data)
        console.log(data)
      })
    } */

  /*   useEffect(() => {
      const sub = DataStore.observeQuery(UserProfile, (p) =>
        p.userID.eq(user.attributes.sub)
      ).subscribe(({ items }) => {
        setProfile(items[0]);
      });
  
      return () => {
        sub.unsubscribe();
      };
    }, []); */




  useEffect(() => {


    if (ds === 'Starting' || ds === 'Running') {
      setI(null)
      clearInterval(i);
      const sub = DataStore.observeQuery(UserProfile, (p) =>
        p.userID.eq(user.attributes.sub)
      ).subscribe(({ items }) => {
        setProfile(items[0]);
      });
      return () => {
        sub.unsubscribe();
      };
    }
  }, [ds])

  useEffect(() => {
    setI(setInterval(() => {
      setDs(DataStore.state)
      console.log(profile)
    }, 1000))
  }, [])

  useEffect(() => {
    console.log(profile)
  }, [profile])




  return (
    <>
      <MainLayout>
        <Content profile={profile} />
        <Footer_alt />
      </MainLayout>
    </>
  );
}

export function Form() {
  return (
    <>
      {/*
          This example requires updating your template:
  
          ```
          <html className="h-full bg-white">
          <body className="h-full">
          ```
        */}
      <div className="flex bg-white min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="grid place-items-center align-center sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="block h-[300px] w-auto"
            src="https://cdn.basileiapalaceja.com/media/basileia_palace_logo.png"
            alt="Basileia Palace"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Secure Back Office
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" action="#" method="POST">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="text-sm">
                  <a
                    href="#"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}


const people = [
  { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
  // More people...
]

function Container(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <div className="flex-1 flex-grow-1 h-100  px-4 sm:px-6 lg:px-8 rounded-lg ring-1 ring-slate-200">
      {JSON.stringify(props.profile)}
    </div>
  )
}

function Content(props) {
  const [isAdmin, setIsAdmin] = new useState(false)

  const fx = async () => {
    const user = await Auth.currentAuthenticatedUser();
    // Returns an array of groups
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
    const result = _.includes(groups, 'web-admin')
    console.log(result)
    setIsAdmin(result)
  }

  fx()

  return (
    <div className="relative isolate bg-white flex flex-grow mb-20">
      <div className="flex-grow mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
        <div className="flex-grow relative px-6 pb-20 pt-20 sm:pt-32 lg:static lg:px-8 lg:py-20  rounded-lg">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg bg-white p-6 rounded-lg shadow">
            <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
              <svg
                className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                    width={200}
                    height={200}
                    x="100%"
                    y={-1}
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M130 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <rect width="100%" height="100%" strokeWidth={0} fill="white" />
                <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                  <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                </svg>
                <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
              </svg>
            </div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
              <dt>{props.profile?.Name} </dt></h2>{isAdmin && <span className="bg-green-300 rounded-full ring-1 ring-slate-400 text-[8px] px-1 grid place-items-center w-fit">ADMINISTRATOR</span>}
            <div className="relative">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative py-5 flex items-center justify-between">
                <span className="bg-white pr-3 text-base font-thin leading-6 text-gray-900">Account Details</span>
                <button
                  type="button"
                  className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  <PencilIcon className="-ml-1 -mr-0.5 h-3 w-3 text-gray-400" aria-hidden="true" />
                  <span>Edit</span>
                </button>
              </div>
            </div>

            <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Address</span>
                  <BuildingOffice2Icon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                {props.profile?.Address ?
                  <dd>
                    {props.profile?.Address}
                    <br />
                    {props.profile?.Country}, {props.profile?.State}
                  </dd>
                  :
                  <span className="text-xs grid bg-gray-100 rounded-full px-5 place-items-center"> No Address </span>}
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Telephone</span>
                  <PhoneIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                {props.profile?.Phone ?
                  <dd>
                    <a className="hover:text-gray-900" href={`tel:${props.profile?.Phone}`}>

                      {props.profile?.Phone}
                    </a>
                  </dd>
                  :
                  <span className="text-xs grid bg-gray-100 rounded-full px-5 place-items-center"> No Phone No. </span>}
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Email</span>
                  <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                {props.profile?.Phone ? <dd>
                  <a className="hover:text-gray-900" href={`mailto:${props.profile?.Email}`}>
                    {props.profile?.Email}
                  </a>
                </dd> :
                  <span className="text-xs grid bg-gray-100 rounded-full px-5 place-items-center"> No Email Address </span>}
              </div>
            </dl>
          </div>
        </div>
        <div className="flex w-full flex-grow mx-auto px-2  rounded-xl">
          <DataTabContainer />
        </div>
      </div>
    </div>
  )
}
