import React, { useContext } from "react";
import _ from "lodash";
import { isBrowser, isMobile } from "react-device-detect";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import CategoryBlock from "./CategoryBlock";
import ContentArea from "../ContentArea";
import EventCardList from "../EventCardList";

const features = [
  {
    name: "Accomodations",
    description:
      "Experience unforgettable moments with our diverse range of event categories. From weddings to corporate gatherings, outdoor celebrations to pool parties, we offer exceptional venues and services tailored to your specific needs. Create cherished memories and celebrate life's milestones in style at Basileia Palace.",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Weddings",
    description:
      "Step into a world of enchantment and say \"I do\" in the most captivating setting. Our breathtaking venue sets the stage for your dream wedding, whether it's an intimate ceremony or a lavish celebration. With elegant decor, impeccable service, and personalized attention to every detail, we ensure your special day is filled with cherished moments and unforgettable memories.",
    icon: LockClosedIcon,
  },
  {
    name: "Corporate Events",
    description:
      "Elevate your corporate events to new heights in our sophisticated and professional setting. From corporate conferences to product launches, our versatile spaces cater to all your business needs. With state-of-the-art technology, impeccable service, and customizable packages, we create an environment that fosters productivity, networking, and success.",
    icon: ArrowPathIcon,
  },
  {
    name: "Food & Catering",
    description:
      "Indulge in culinary excellence with our exquisite catering services. Our talented chefs create mouthwatering dishes using the finest ingredients, tailored to your preferences and event theme. From intimate dinners to large-scale banquets, our catering services add a touch of gastronomic delight to any occasion.",
    icon: FingerPrintIcon,
  },
];

const values = [
  {
    name: "Standard Rooms",
    description:
      "Discover comfort and style in our Standard Rooms. Featuring modern amenities, a cozy ambiance, and tasteful decor, these rooms offer a tranquil retreat for your stay. Unwind in a peaceful atmosphere, enjoy a restful sleep on our comfortable beds, and make use of the convenient in-room facilities. Immerse yourself in an exceptional experience where every detail is designed to enhance your comfort and satisfaction.",
  },
  {
    name: "Deluxe Double Room",
    description:
      "Indulge in luxury and sophistication in our Deluxe Double Rooms. With stylish and modern decor, attention to detail, and spacious layouts, these rooms provide a truly elevated experience. Enjoy the convenience of double beds, a well-appointed en-suite bathroom, and a dedicated workspace. Stay connected with high-speed Wi-Fi, unwind with a variety of entertainment options, and relish personalized comfort with individually controlled air conditioning. Experience a perfect blend of elegance and comfort in our Deluxe Double Rooms.",
  },
  {
    name: "Luxury Studio Suite",
    description:
      "Experience the pinnacle of luxury in our exquisite Luxury Studio Suites. These spacious and beautifully designed suites offer a harmonious blend of comfort and elegance. Indulge in the lavish amenities, relax in the separate living area, and enjoy the convenience of a fully equipped kitchenette or pantry. Immerse yourself in the ultimate sanctuary of style and sophistication, where every moment is crafted to perfection.",
  },
  {
    name: "Food and Catering",
    description:
      "Delight your taste buds with our exceptional food and catering services. Our talented culinary team creates exquisite dishes using the finest ingredients, ensuring a gastronomic experience like no other. Whether you're enjoying a meal in our elegant restaurant or hosting a special event, our catering services will impress even the most discerning palates. Sit back, savor the flavors, and let us take care of your culinary desires.",
  },
  {
    name: "Events",
    description:
      "Make your special occasions truly memorable with our exceptional event services. Whether you're planning a corporate conference, a wedding, or a social gathering, our dedicated team will ensure every detail is taken care of. From venue setup to audiovisual equipment, we offer a seamless and personalized experience, allowing you to focus on creating unforgettable moments.",
  },
  {
    name: "Parties",
    description:
      "Celebrate in style with our exceptional party services. Whether it's a birthday celebration, anniversary, or any other special occasion, our team will create a vibrant and festive atmosphere for you and your guests. From personalized decorations to delectable catering options, we'll ensure your party is a resounding success. Let us take care of the details while you enjoy a memorable and enjoyable event.",
  },
];
const d = (
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-2xl lg:text-center">
      <p className="mt-2 text-3xl font-thin tracking-tight text-gray-900 sm:text-4xl">
        Perfect Get Away
      </p>
      <p className="mt-6 leading-16 text-justify text-3xl font-thin text-gray-600">
        At Basileia Palace, your most important celebrations unfolds. Whether
        you’re planning to say your "I Do's" in the court yard or toast your
        newlywed status on a sun-kissed pool, we promise to make sure everything
        - from fresh flowers to sparkling wine - is absolutely perfect
      </p>
    </div>
    <div className="my-5">
      <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            5-STAR ACCOMMODATIONS
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            All of our bedrooms are characterized by antique wooden furniture
            with warm lighting and colors to create the perfect setting at
            night, rich plush textures and a peaceful ambiance throughout the
            hotel ensures optimum comfort for every guest.
          </p>
        </div>
        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {values.map((value) => (
            <div key={value.name}>
              <dt className="font-semibold text-gray-900">{value.name}</dt>
              <dd className="mt-1 text-gray-600">{value.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
    <div className="mx-auto max-w-2xl px-4 py-4 sm:px-6 sm:py-24 lg:max-w-7xl">
      <div className="relative overflow-hidden rounded-lg lg:h-96">
        <div className="absolute inset-0">
          <img
            src="https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_6.jpg"
            alt=""
            className="h-full w-full object-cover object-center"
          />
        </div>
        <div aria-hidden="true" className="relative h-96 w-full lg:hidden" />
        <div aria-hidden="true" className="relative h-32 w-full lg:hidden" />
        <div className="absolute inset-x-0 bottom-0 rounded-bl-lg rounded-br-lg bg-black bg-opacity-75 p-6 backdrop-blur backdrop-filter sm:flex sm:items-center sm:justify-between lg:inset-x-auto lg:inset-y-0 lg:w-96 lg:flex-col lg:items-start lg:rounded-br-none lg:rounded-tl-lg">
          <div className="w-full grid place-items-center">
            <img
              className="sm:h-12 md:h-24 lg:h-64"
              src="https://cdn.basileiapalaceja.com/media/basileia_palace_logo.png"
              alt="Basileia Palace"
            />
          </div>
          <a
            href="#"
            className="mt-6 flex flex-shrink-0 items-center justify-center rounded-md border border-amber-100 border-opacity-25 bg-white bg-opacity-0 px-4 py-3 uppercase font-medium text-amber-200 hover:bg-opacity-10 sm:ml-8 sm:mt-0 lg:ml-0 lg:w-full"
          >
            View Rooms
          </a>
        </div>
      </div>
    </div>

    <div className="overflow-hidden bg-white py-32">
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-thin tracking-tight text-gray-900 sm:text-4xl">
              "Our Kingdom"
            </h2>
            <p className="mt-6 text-sm leading-8 text-gray-600">
              Basileia Palace, meaning the “Kingdom Palace” was given its name
              by the late Dr. Myles Munroe being led by the Holy Spirit on the
              June 22, 2014 during his visit to the island and tour of the
              unfinished property.    Mrs. Millicent Drummond, owner of Basileia
              Palace recalled, “After he and his late pilot tour the
              unfinished building with zinc fence still up then, he was sitting
              in the van at the gate, when he said the Holy Spirit said to name
              it Basileia Palace“, I said, “Sir, what does that mean?”  then he
              said, “Kingdom Palace “.
            </p>
          </div>
          <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
              <img
                src="https://cdn.basileiapalaceja.com/media/building/building_1.jpg"
                alt=""
                className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
              />
            </div>
            <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
              <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                <img
                  src="https://cdn.basileiapalaceja.com/media/building/building_4.jpg"
                  alt=""
                  className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
              <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                <img
                  src="https://cdn.basileiapalaceja.com/media/building/building_14.jpg"
                  alt=""
                  className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
              <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                <img
                  src="https://cdn.basileiapalaceja.com/media/building/building_24.jpg"
                  alt=""
                  className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section className="shadow-2xl rounded-lg bg-white">
      <CategoryBlock type="birthday" />
      <CategoryBlock type="corporate" />
      <CategoryBlock type="wedding" />
      <CategoryBlock type="babyshower" />
      <EventCardList />
    </section>
  </div>
);
export default function HomeContentBlock(props) {
  return (
    <>
      <ContentArea>
        <div
          className="bg-cover bg-fixed"
          style={{
            backgroundImage: `url('https://cdn.basileiapalaceja.com/media/building/building_30.jpg')`,
          }}
        >
          {props.children}
        </div>
        {d}
        
      </ContentArea>
    </>
  );
}
