import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { MainLayout } from "../layouts/mainLayout";
import { MobileLayout } from "../layouts/mobileLayout";
import Footer_alt from "../components/Footer/Footer_alt";
import ContentArea from "../components/ContentArea";
import WeddingContentBlock from "../components/ContentAreaBlocks/WeddingContentBlock"
import { useLocation } from "react-router-dom";
import CorporateContentBlock from "../components/ContentAreaBlocks/CorporateContentBlock";
import OutdoorCelebrationContentBlock from "../components/ContentAreaBlocks/OutdoorCelebrationContentBlock";
import PoolContentBlock from "../components/ContentAreaBlocks/PoolContentBlock";
import RepassContentBlock from "../components/ContentAreaBlocks/RepassContentBlock";
import BabyShowerContentBlock from "../components/ContentAreaBlocks/BabyShowerContentBlock";
import BirthdayContentBlock from "../components/ContentAreaBlocks/BirthdayContentBlock";
import CateringContentBlock from "../components/ContentAreaBlocks/CateringContentBlock";
import PhotoshootContentBlock from "../components/ContentAreaBlocks/PhotoshootContentBlock";

import { CorporateContentBlock as MobileCorporateContentBlock } from "../components/Mobile/MobileContentAreaBlocks/CorporateContentBlock";
import { WeddingContentBlock as MobileWeddingContentBlock } from "../components/Mobile/MobileContentAreaBlocks/WeddingContentBlock";
import { OutdoorCelebrationContentBlock as MobileOutdoorCelebrationContentBlock } from "../components/Mobile/MobileContentAreaBlocks/OutdoorCelebrationContentBlock";

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

function Temp(){
  return <>
    <div className="w-full grid justify-center mt-16 p-8 shadow-xl ring-1 ring-slate-200">
      <p>PAGE NOT AVAILABLE YET.</p>
    </div>
  </>
}

export default function EventPage() {

  const location = useLocation();

  const [category, setCategory] = useState("")
  const [categoryInitial, setCategoryInitial] = useState("")
  const [body, setBody] = useState(<></>)


  useEffect(() => {
    const ev = (_.split(location.search, '=')[1])
    switch (ev) {
      case 'wedding': setCategoryInitial("wedding"); break;
      case 'corporate': setCategoryInitial("corporate"); break;
      case 'OutdoorCelebration': setCategoryInitial("outdoor"); break;
      case 'Pool': setCategoryInitial("pool"); break;
      case 'Repass': setCategoryInitial("repass"); break;
      case 'BabyShower': setCategoryInitial("babyshower"); break;
      case 'Birthday': setCategoryInitial("birthday"); break;
      case 'Catering': setCategoryInitial("catering"); break;
      case 'photoshoot': setCategoryInitial("photoshoot"); break;
    }


  }, [])

  useEffect(() => {

    console.log("HIT")
    console.log(categoryInitial)

    switch (categoryInitial) {
      case 'wedding': setBody(isMobile ? <MobileWeddingContentBlock/> : <WeddingContentBlock />); break;
      case 'corporate': setBody(isMobile ? <MobileCorporateContentBlock /> : <CorporateContentBlock />); break;
      case 'outdoor': setBody(isMobile ? <MobileOutdoorCelebrationContentBlock/> : <OutdoorCelebrationContentBlock />); break;
      case 'pool': setBody(isMobile ? <Temp/> : <PoolContentBlock />); break;
      case 'repass': setBody(isMobile ? <Temp/> : <RepassContentBlock />); break;
      case 'babyshower': setBody(isMobile ? <Temp/> : <BabyShowerContentBlock />); break;
      case 'birthday': setBody(isMobile ? <Temp/> : <BirthdayContentBlock />); break;
      case 'catering': setBody(isMobile ? <Temp/> : <CateringContentBlock />); break;
      case 'photoshoot': setBody(isMobile ? <Temp/> : <PhotoshootContentBlock />); break;
      default: setBody(<Temp/>)
    }

  }, [categoryInitial])

  useEffect(() => {
    console.log(body)
  }, [body])

  return (
    <>
      <BrowserView>
        <MainLayout>
          {body}
          <Footer_alt />
        </MainLayout>
      </BrowserView>
      <MobileView>
        <MobileLayout>
          {body}
        </MobileLayout>
      </MobileView>
    </>
  );
}
