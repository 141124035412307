import React, { useContext } from "react";
import _ from "lodash";
import { isBrowser, isMobile } from "react-device-detect";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import ContentArea from "../../ContentArea";
import EventCardList from "../../EventCardList";

const features = [
  {
    name: "Accomodations",
    description:
      "Morbi viverra dui mi arcu sed. Tellus semper adipiscing suspendisse semper morbi. Odio urna massa nunc massa.",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Weddings",
    description:
      "Sit quis amet rutrum tellus ullamcorper ultricies libero dolor eget. Sem sodales gravida quam turpis enim lacus amet.",
    icon: LockClosedIcon,
  },
  {
    name: "Corporate Events",
    description:
      "Quisque est vel vulputate cursus. Risus proin diam nunc commodo. Lobortis auctor congue commodo diam neque.",
    icon: ArrowPathIcon,
  },
  {
    name: "Food & Catering",
    description:
      "Arcu egestas dolor vel iaculis in ipsum mauris. Tincidunt mattis aliquet hac quis. Id hac maecenas ac donec pharetra eget.",
    icon: FingerPrintIcon,
  },
];

const values = [
  {
    name: "Standard Rooms",
    description:
      "Aut illo quae. Ut et harum ea animi natus. Culpa maiores et sed sint et magnam exercitationem quia. Ullam voluptas nihil vitae dicta molestiae et. Aliquid velit porro vero.",
  },
  {
    name: "Deluxe Double Room",
    description:
      "Mollitia delectus a omnis. Quae velit aliquid. Qui nulla maxime adipisci illo id molestiae. Cumque cum ut minus rerum architecto magnam consequatur. Quia quaerat minima.",
  },
  {
    name: "Luxury Studio Suite",
    description:
      "Aut repellendus et officiis dolor possimus. Deserunt velit quasi sunt fuga error labore quia ipsum. Commodi autem voluptatem nam. Quos voluptatem totam.",
  },
  {
    name: "Food and Catering",
    description:
      "Magnam provident veritatis odit. Vitae eligendi repellat non. Eum fugit impedit veritatis ducimus. Non qui aspernatur laudantium modi. Praesentium rerum error deserunt harum.",
  },
  {
    name: "Events",
    description:
      "Sit minus expedita quam in ullam molestiae dignissimos in harum. Tenetur dolorem iure. Non nesciunt dolorem veniam necessitatibus laboriosam voluptas perspiciatis error.",
  },
  {
    name: "Parties",
    description:
      "Ipsa in earum deserunt aut. Quos minus aut animi et soluta. Ipsum dicta ut quia eius. Possimus reprehenderit iste aspernatur ut est velit consequatur distinctio.",
  },
];
const d = (
  <div className="mx-auto max-w-7xl px-6 lg:px-8">


    <section className="rounded-lg pt-20">
      <EventCardList />
    </section>
  </div>
);
export default function MobileHomeContentBlock(props) {
  return (
    <>
      <ContentArea>
        <div
          className=" h-screen bg-cover bg-fixed top center"
          style={{
            backgroundImage: `url('https://cdn.basileiapalaceja.com/media/building/building_30.jpg')`,
          }}
        >
          <div className="grid place-items-center content-center text-center w-full h-full px-6 bg-black/20 backdrop-blur-sm">
            <h2 className="font-thin  text-gray-100 text-4xl">
              "Our Kingdom"
            </h2>
            <p className="mt-6 text-2xl leading-8 text-white font-thin">
            At Basileia Palace, your most important celebrations unfolds. Whether you’re planning to say your "I Do's" in the court yard or toast your newlywed status on a sun-kissed pool, we promise to make sure everything - from fresh flowers to sparkling wine - is absolutely perfect
            </p>
          </div>
          {props.children}
        </div>
        {d}

      </ContentArea>
    </>
  );
}
