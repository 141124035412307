import React, { Fragment, Component, useEffect, useState, useContext } from 'react'

import NavigationBar from '../components/NavigationBar';
import { isBrowser, isMobile } from "react-device-detect";
import img from '../images/bg-out-1.jpg'
export class MainLayout extends Component {
    render() {
        return <>
            <div className="grid place-items-center flex-col  bg-slate-800  bg-cover  bg-fixed bg-center relative  flex h-screen w-screen overflow-hidden">
                <div className='absolute w-full h-full '></div>
                <div className='flex flex-row fixed h-full w-screen overflow-hidden transparent' >
                    <div className='flex flex-col  grow w-full  bg-white/0  rounded-lg'>
                        <div className='flex flex-col w-full'><NavigationBar /></div>
                        <main className='grow flex flex-col overflow-auto'>
                            {this.props.children}
                        </main>
                    </div>
                </div>
            </div>
        </>
    }
}