import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Bars3Icon, BuildingOfficeIcon, HomeIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import AuthWidget from '../AuthWidget'
import { PhoneIcon } from '@heroicons/react/24/solid'

const menu = [
    {
        name: 'What we Offer',
        description: 'Measure actions your users take',
        href: '/',
        icon: IconOne,
    },
    {
        name: 'About Basiliea Palace',
        description: 'Create your own targeted content',
        href: '/about',
        icon: IconTwo,
    },
    {
        name: 'Get In Touch',
        description: 'Keep track of your growth',
        href: '/contact',
        icon: IconThree,
    },
]

export default function PopOverMenu(props) {
    return (
        <div className="fixed z-20 top-0 w-full max-w-md  ">
            <Popover className="relative">
                {({ open }) => (
                    <>
                        <div className='grid grid-cols-2  bg-white backdrop-blur-sm  h-16 shadow-2xl px-5'>
                            <div>
                                <img
                                    className="h-16 h-16"
                                    src="https://cdn.basileiapalaceja.com/media/basileia_palace_logo.png"
                                    alt="Basileia Palace"
                                />
                            </div>
                            <div className='grid justify-end content-center'>
                                <Popover.Button
                                    className={`${open ? '' : 'text-opacity-90'}
                                                group inline-flex items-center rounded-md
                                                px-3 h-12 py-2 text-base font-medium text-Black
                                                hover:text-opacity-100 focus:outline-none
                                                focus-visible:ring-2 focus-visible:ring-white
                                                focus-visible:ring-opacity-75`}>
                                    <Bars3Icon className='h-8 text-gray-800 ring-1 ring-black rounded-sm' />
                                </Popover.Button>
                            </div>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen  max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                    <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
                                        {menu.map((item) => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                            >
                                                <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                                                    <item.icon aria-hidden="true" />
                                                </div>
                                                <div className="ml-4">
                                                    <p className="text-sm font-medium text-gray-900">
                                                        {item.name}
                                                    </p>
                                                    <p className="text-sm text-gray-500">
                                                        {item.description}
                                                    </p>
                                                </div>
                                            </a>
                                        ))}
                                    </div>
                                    <div className="bg-gray-50 p-4">
                                        <a
                                            href="/room?category=standard"
                                            className="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                        >
                                            <span className="flex items-center text-left">
                                                <span className="text-sm font-bold text-gray-900">
                                                    <span className='mr-5 text-[8pt] uppercase'>View Available Rooms</span>
                                                    <br/>
                                                    <span className='font-thin'>Standard . Deluxe . Luxury . Full House</span>
                                                </span>
                                            </span>
                                            <span className="block text-xs text-gray-500 leading-4">
                                                Immerse yourself in the epitome of luxury and privacy with our accommodations.
                                            </span>
                                        </a>
                                        <div className=' h-16 grid place-items-center pt-4 border-t'>
                                            <AuthWidget Auth={props.Auth} />
                                        </div>
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
        </div>
    )
}

function IconOne() {
    return (

        <div className='w-12 h-12 grid place-items-center bg-amber-000 rounded-xl'>
            <HomeIcon className='w-8 h-8 text-black' />
        </div>
    )
}

function IconTwo() {
    return (
        <div className='w-12 h-12 grid place-items-center bg-amber-000 rounded-xl'>
            <BuildingOfficeIcon className='w-8 h-8 text-black' />
        </div>
    )
}

function IconThree() {
    return (
        <div className='w-12 h-12 grid place-items-center bg-amber-000 rounded-xl'>
            <PhoneIcon className='w-8 h-8 text-black' />
        </div>
    )
}
