import React, { useContext } from "react";
import _ from "lodash";
import { isBrowser, isMobile } from "react-device-detect";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import CategoryBlock from "./CategoryBlock";
import ContentArea from "../ContentArea";
import { PriceModule, PriceModule_2 } from "../PriceModule"



const tiers = [
  {
    name: "Basic",
    id: "tier-basic",
    href: "#",
    price: { monthly: "$15", annually: "$12" },
    description: "Everything necessary to get started.",
    features: [
      "5 products",
      "Up to 1,000 subscribers",
      "Basic analytics",
      "48-hour support response time",
    ],
  },
  {
    name: "Essential",
    id: "tier-essential",
    href: "#",
    price: { monthly: "$30", annually: "$24" },
    description:
      "Everything in Basic, plus essential tools for growing your business.",
    features: [
      "25 products",
      "Up to 10,000 subscribers",
      "Advanced analytics",
      "24-hour support response time",
      "Marketing automations",
    ],
  },
  {
    name: "Growth",
    id: "tier-growth",
    href: "#",
    price: { monthly: "$60", annually: "$48" },
    description:
      "Everything in Essential, plus collaboration tools and deeper insights.",
    features: [
      "Unlimited products",
      "Unlimited subscribers",
      "Advanced analytics",
      "1-hour, dedicated support response time",
      "Marketing automations",
      "Custom reporting tools",
    ],
  },
];

const Elem = () => (
  <div className="bg-white/100 backdrop-blur-sm rounded-none">
    <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-6 px-4 py-4 sm:px-6 sm:py-2 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
      <div className="grid grid-cols-2 grid-rows-2 place-items-center gap-4 sm:gap-6 lg:gap-8">
        <img
          src="https://cdn.basileiapalaceja.com/media/photo_shoot/photoshoot_16.jpg"
          alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
          className="rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/photo_shoot/photoshoot_17.jpg"
          alt="Top down view of walnut card tray with embedded magnets and card groove."
          className="rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/photo_shoot/photoshoot_4.jpg"
          alt="Top down view of walnut card tray with embedded magnets and card groove."
          className="rounded-lg bg-gray-100 shadow-md shadow-gray-500"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/photo_shoot/photoshoot_51.jpg"
          alt="Side of walnut card tray with card groove and recessed card area."
          className="rounded-lg bg-gray-100 shadow-md shadow-gray-500"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/photo_shoot/photoshoot_23.jpg"
          alt="Walnut card tray filled with cards and card angled in dedicated groove."
          className="rounded-lg bg-gray-100 col-span-2"
        />
      </div>
      <div className="grid grid-cols-2 grid-rows-2 place-items-center gap-4 sm:gap-6 lg:gap-8">
        <img
          src="https://cdn.basileiapalaceja.com/media/photo_shoot/photoshoot_26.jpg"
          alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
          className="rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/photo_shoot/photoshoot_27.jpg"
          alt="Top down view of walnut card tray with embedded magnets and card groove."
          className="rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/photo_shoot/photoshoot_14.jpg"
          alt="Top down view of walnut card tray with embedded magnets and card groove."
          className="rounded-lg bg-gray-100 shadow-md shadow-gray-500"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/photo_shoot/photoshoot_61.jpg"
          alt="Side of walnut card tray with card groove and recessed card area."
          className="rounded-lg bg-gray-100 shadow-md shadow-gray-500"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/photo_shoot/photoshoot_63.jpg"
          alt="Walnut card tray filled with cards and card angled in dedicated groove."
          className="rounded-lg bg-gray-100 col-span-2"
        />
      </div>
    </div>
  </div>
);

function Hero() {
  return (
    <div className="overflow-hidden bg-white py-32">
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-thin uppercase tracking-tight text-gray-900 sm:text-4xl">
              Book Your Photo Shoot
            </h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">
              Whether you're a professional photographer or an individual seeking to capture precious moments, Basileia Palace provides the perfect backdrop for your photo shoot. Let our exquisite venue and dedicated team be the canvas for your creativity. Contact us today to discuss your photo shoot requirements and let us assist you in creating captivating images that will be treasured for a lifetime.
            </p>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Contact our team to reserve your preferred date and discuss your vision for the shoot. Experience the beauty and charm of Basileia Palace as you capture timeless moments in our stunning venue. Let us be a part of your photographic journey and create images that will stand the test of time.
            </p>
          </div>
          <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
              <img
                src="https://cdn.basileiapalaceja.com/media/photo_shoot/photoshoot_4.jpg"
                alt=""
                className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover object-top"
              />
            </div>
            <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
              <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                <img
                  src="https://cdn.basileiapalaceja.com/media/photo_shoot/photoshoot_3.jpg"
                  alt=""
                  className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover object-top"
                />
              </div>
              <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                <img
                  src="https://cdn.basileiapalaceja.com/media/photo_shoot/photoshoot_19.jpg"
                  alt=""
                  className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover  object-top"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const d = (
  <div className="mx-auto  max-w-7xl lg:px-4 py-0">
    <div
      className="w-full rounded-none h-full bg-black  overflow-auto  bg-fixed bg-contain bg-no-repeat bg-gradient-to-r from-violet-500 to-fuchsia-500"
      style={{
        backgroundImage: `url('https://cdn.basileiapalaceja.com/media/photo_shoot/photoshoot_65.jpg')`,
      }}
    >
      <div className="mx-auto max-w-7xl py-8  lg:max-w-none">
        <div className="grid grid-cols-1 items-center gap-x-16 gap-y-10 lg:grid-cols-2 py-0">
          <h2 className="text-8xl font-thin tracking-tight text-gray-100 px-10">
            <p>Photoshoots</p>
            <p className="text-sm  tracking-normal font-bold indent-3">Capturing Timeless Moments at Basileia Palace</p>
          </h2>
          <p className="m-4 bg-black/0 text-2xl text-gray-100 p-8  rounded-lg antialiased hover:subpixel-antialiased bg-black/80">
            Capture life's most precious moments in our picturesque venues, perfect for photo shoots. Whether it's a wedding, engagement, or a professional shoot, our elegant spaces provide a stunning backdrop for timeless photographs. From romantic settings to architectural marvels, let our venues enhance the beauty and essence of your visual story.</p>

          <div className="my-0 col-span-2 bg-white rounded-xl">
            <Hero />
            <div className="flex my-0 col-span-2 bg-white text-black gap-4 px-8">
              <span className="text-xl font-thin"><b>Welcome to Basileia Palace, </b>
                <br/>where we offer a stunning backdrop for your next photo shoot. Whether you're a professional photographer, a couple celebrating their love, or a family wanting to preserve precious memories, our exquisite venue provides the perfect setting to capture timeless moments. With its charming architecture, lush gardens, and captivating interiors, Basileia Palace offers a plethora of picturesque locations that will elevate your photo shoot to new heights.

                <img
                  src="https://cdn.basileiapalaceja.com/media/photo_shoot/photoshoot_53.jpg"
                  className="rounded-lg bg-gray-100 col-span-2 m-5"
                /></span>
              <span className="text-xl font-thin "><b>Beauty at its peak - </b>Step into a world of elegance and charm as you explore the enchanting spaces of Basileia Palace. From the moment you enter our grand entrance, you'll be surrounded by a sense of sophistication and refinement. Our meticulously designed interiors feature antique wooden furniture, rich textures, and warm lighting, creating a captivating ambiance that adds depth and character to your photographs.<img
                src="https://cdn.basileiapalaceja.com/media/photo_shoot/photoshoot_54.jpg"
                className="rounded-lg bg-gray-100 col-span-2 m-5"
              /></span>
            </div>
            <div className="flex my-0 col-span-2 bg-white text-black gap-4 px-8 border-b pb-16">
              <span className="text-3xl font-thin"><b>Breathtaking Gardens,</b>
                <br /><br />Indulge in the serenity of our flowering gardens, a true oasis of tranquility. With vibrant blooms, lush greenery, and peaceful walkways, our gardens provide a stunning backdrop for outdoor shoots. Whether you're capturing romantic moments, family portraits, or artistic compositions, the natural beauty of our gardens will enhance the aesthetic appeal of your photographs.
              </span>
            </div>
          </div>
        </div>
        <Elem />
        <div className="bg-white grid grid-cols-2 grid-flow p-8 gap-4">
          <div>
            <span className="font-bold flex-grow-1 mr-5"> Opulent Interiors:</span>
            Step inside our opulent interiors and discover a world of architectural splendor. From the intricately adorned ceilings to the ornate details of our furnishings, every corner of Basileia Palace exudes elegance and grandeur. Whether you prefer the regal charm of our banquet hall or the intimate ambiance of our cozy library, our interiors offer a diverse range of settings for your photo shoot, each with its own unique character and charm.
          </div>
          <div>
            <span className="font-bold flex-grow-1 mr-5"> Versatile Locations:</span>
            Basileia Palace provides a variety of versatile locations to suit your creative vision. Whether you're seeking a backdrop for a fashion shoot, an engagement session, or a family portrait, our venue offers a range of options to accommodate your needs. From sweeping staircases and arched doorways to sunlit courtyards and cozy sitting areas, you'll find the perfect spot to capture the essence of your story.
          </div>
          <div>
            <span className="font-bold flex-grow-1 mr-5"> Personalized Experience:</span>
            At Basileia Palace, we understand that each photo shoot is a unique expression of your style and vision. Our dedicated team is committed to ensuring that your experience is tailored to your specific requirements. From assisting with location selection to providing on-site support, we strive to make your photo shoot seamless and enjoyable. Our goal is to create a personalized experience that exceeds your expectations and results in stunning photographs that will be cherished for a lifetime.
          </div>
          <div>
            <span className="font-bold flex-grow-1 mr-5"> Professional Support:</span>
            We welcome professional photographers and offer support to enhance your creative process. Our team is experienced in collaborating with photographers and understands the importance of providing the necessary resources and assistance. From arranging lighting setups to recommending ideal shooting locations, we are here to ensure that your vision is realized with precision and excellence.
          </div>
          <div>
            <span className="font-bold flex-grow-1 mr-5"> Book Your Photo Shoot:</span>
            Whether you're a professional photographer or an individual seeking to capture precious moments, Basileia Palace provides the perfect backdrop for your photo shoot. Let our exquisite venue and dedicated team be the canvas for your creativity. Contact us today to discuss your photo shoot requirements and let us assist you in creating captivating images that will be treasured for a lifetime.
          </div>
        </div>
        <PriceModule category='photoshoot' />
      </div>
    </div>
  </div>
);

export default function PhotoshootContentBlock() {
  return <ContentArea>{d}</ContentArea>;
}
