import React, { useContext } from "react";
import _ from "lodash";
import { MainLayout } from "../../layouts/mainLayout";
import Footer_alt from "../../components/Footer/Footer_alt";
import ContentArea from "../../components/ContentArea";
import PoolContentBlock from "../../components/ContentAreaBlocks/PoolContentBlock"

export default function PoolPage() {
  return (
    <>
      <MainLayout>
        <PoolContentBlock />
        <Footer_alt />
      </MainLayout>
    </>
  );
}
