import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useLocation } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react'
import _ from "lodash";
import { MainLayout } from "../../../layouts/mainLayout";
import Footer_alt from "../../../components/Footer/Footer_alt";
import ContentArea from "../../../components/ContentArea";
import Hero_1 from "../../../components/Hero_1";
import HomeContentBlock from "../../../components/ContentAreaBlocks/HomeContentBlock";

import { ChevronRightIcon, PhoneIcon } from "@heroicons/react/20/solid";
import {
  AcademicCapIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function MobileRoomContentBlock() {
  const location = useLocation();
  const [category, setCategory] = useState("")
  const [categoryInitial, setCategoryInitial] = useState("")
  const [body, setBody] = useState(<></>)

  const ST_BODY = <>
    <p className="mt-6 text-lg leading-8 text-gray-600 ">
      <b>O</b>ur <b>S</b>tandard <b>R</b>ooms at Basileia Palace offer a seamless blend of comfort and elegance, providing you with a tranquil retreat during your stay.
      Designed with meticulous attention to detail, these rooms are thoughtfully crafted to meet the needs of both business and leisure travelers.
      Step into a world of refined simplicity, where every element has been carefully chosen to ensure a delightful and memorable experience.<br />
      It’s the perfect choice for <b>budget-minded travelers</b> who want a comfortable and convenient place to stay. Our rooms are spacious and well-appointed,
      and they come with all the amenities you need for a relaxing stay.
    </p>
  </>
  const DX_BODY = <>
    <p className="mt-6 text-lg leading-8 text-gray-600">
      Indulge in luxury and sophistication in our Deluxe Double Rooms. With stylish and modern decor, attention to detail, and spacious layouts, these rooms provide a truly elevated experience. Enjoy the convenience of double beds, a well-appointed en-suite bathroom, and a dedicated workspace.
      <br />Stay connected with high-speed Wi-Fi, unwind with a variety of entertainment options, and relish personalized comfort with individually controlled air conditioning. Experience a perfect blend of elegance and comfort in our Deluxe Double Rooms.
    </p>
  </>
  const LX_BODY = <>
    <p className="mt-6 text-lg leading-8 text-gray-600 ">
      Our Master Room is the epitome of luxury and comfort. With its spacious layout and elegant design, this room is sure to impress even the most discerning guests. The king-size bed is dressed in premium bedding and the en-suite bathroom is stocked with luxurious amenities.
      There is also a separate living area or lounge space, perfect for relaxing or entertaining guests. Whether you are looking for a romantic getaway or a luxurious stay for your next family vacation, our Master Room is the perfect choice. Book your stay today and experience the ultimate in luxury and comfort.
      Immerse yourself in luxury, comfort, and impeccable service in our Master Rooms at Basileia Palace. Experience a haven that exceeds expectations and creates unforgettable memories.
      Reserve your Master Room at Basileia Palace and embark on a journey of unrivaled elegance and comfort.

    </p>
  </>
  const FH_BODY = <>
    <p className="mt-6 text-lg leading-8 text-gray-600 ">
      Escape to an exclusive retreat with our Full House accommodations. This extraordinary option provides you with an entire house or villa reserved exclusively for you and your guests.
      Revel in the privacy and luxury that awaits you, along with an array of exceptional features and services. Immerse yourself in the epitome of luxury and privacy with our Full House accommodations.
      Indulge in a personalized and memorable stay, where every detail is designed to exceed your expectations. Book your Full House experience today and embrace the ultimate in exclusivity.
    </p>
  </>

  useEffect(() => {
    setCategory(_.split(location.search, '=')[1])
    switch (category) {
      case 'standard': setCategoryInitial("ST"); break;
      case 'deluxe': setCategoryInitial("DX"); break;
      case 'luxury': setCategoryInitial("LX"); break;
      case 'fullhouse': setCategoryInitial("FH"); break;
    }
  }, [])

  useEffect(() => {
    switch (category) {
      case 'standard': setCategoryInitial("ST"); setBody(ST_BODY); break;
      case 'deluxe': setCategoryInitial("DX"); setBody(DX_BODY); break;
      case 'luxury': setCategoryInitial("LX"); setBody(LX_BODY); break;
      case 'fullhouse': setCategoryInitial("FH"); setBody(FH_BODY); break;
    }
  }, [category])

  return (
    <>
      <Panel>
        <Hero categoryInitial={categoryInitial} />
        <section className='grid justify-center'>
          <div className='max-w-7xl grid grid-cols-1 gap-4'>
            <div className=''>
              {body}
            </div>
            <Prop_1 categoryInitial={categoryInitial} />

          </div>
        </section>

        <Features categoryInitial={categoryInitial} />
      </Panel>
    </>
  );
}


function ImageModal(props) {
  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <button
        type="button"
        onClick={openModal}
      >
        {props.children}
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">

                  <div className="mt-2">
                    <img
                      src={props.file.source}
                      alt=""
                      width="500px"
                      className="pointer-events-none object-cover"
                    />
                  </div>

                  <div className="mt-4 grid place-items-center">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-amber-100 px-4 py-2 text-sm font-medium text-amber-900 hover:bg-amber-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-amber-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Close Window
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}


export function ImageGallery() {
  const files = [
    {
      category: 1,
      source:
        "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_1.jpg",
    },
    {
      category: 1,
      source:
        "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_2.jpg",
    },
    {
      category: 1,
      source:
        "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_3.jpg",
    },
    {
      category: 1,
      source:
        "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_4.jpg",
    },
    {
      category: 1,
      source:
        "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_5.jpg",
    },
    {
      category: 1,
      source:
        "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_6.jpg",
    },
    {
      category: 1,
      source:
        "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_7.jpg",
    },
    {
      category: 1,
      source:
        "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_8.jpg",
    },
    // More files...
  ];
  return (
    <ul
      role="list"
      className="mx-auto max-w-7xl my-2 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
    >
      {files.map((file) => (
        <li key={file.source} className="relative">
          <ImageModal file={file} ><div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
            <Transition
              as={Fragment}
              show={true}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >

              <img
                src={file.source}
                alt=""
                className="pointer-events-none object-cover aspect-square group-hover:opacity-75"
              />
            </Transition>
          </div>
          </ImageModal >
        </li>
      ))}
    </ul>
  );
}

export function Panel(props) {
  return (
    <div className=" rounded-lg bg-white shadow">
      <div className="p-6">{props.children}</div>
    </div>
  );
}


export function Hero(props) {

  const [title, setTitle] = useState({ head: '', sub: '' })

  useEffect(() => {
    switch (props.categoryInitial) {
      case 'ST': setTitle({ head: "Welcome to our Standard Rooms", sub: "A Haven of Comfort and Elegance" }); break;
      case 'DX': setTitle({ head: "Discover our Deluxe Double Rooms", sub: "Luxury and Comfort Redefined" }); break;
      case 'LX': setTitle({ head: "Master Room/ Luxury studio suite at Basileia Palace", sub: "The Ultimate in Luxury and Comfort" }); break;
      case 'FH': setTitle({ head: "Full House accommodations", sub: "Indulge in a personalized and memorable stay" }); break;
    }
  }, [props.categoryInitial])

  return (
    <div className="">
      <div className="relative isolate overflow-hidden">
        <div className="mx-auto max-w-7xl ">
          <div className="px-6">
            <div className="mx-auto max-w-2xl">
              <div className="max-w-lg">
                <img
                  className="block h-[300px] w-auto"
                  src="https://cdn.basileiapalaceja.com/media/basileia_palace_logo.png"
                  alt="Basileia Palace"
                />
                <h1 className="mt-10 font-thin tracking-tight text-gray-900 text-2xl">
                  {title.head}
                  <p className='text-[12pt] mt-4 font-extrabold'>{title.sub}</p>
                </h1>
              </div>
            </div>
          </div>
          <div className="mt-4 border-t pt-5 ">
            <div
              className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg]"
              aria-hidden="true"
            />
            <div className="">
              <div className="">
                <div
                  className="absolute -inset-y-px left-1/2 -z-10 w-[200%] skew-x-[-30deg]"
                  aria-hidden="true"
                />
                <div className="relative">
                  <div className="mx-auto max-w-2xl">
                    <h2 className="font-bold tracking-tight text-gray-900 text-2xl">
                      5-STAR ACCOMMODATIONS
                    </h2>
                    <p className="mt-6 text-lg leading-8 text-gray-800">
                      All of our bedrooms are characterized by antique wooden furniture
                      with warm lighting and colors to create the perfect setting at
                      night, rich plush textures and a peaceful ambiance throughout the
                      hotel ensures optimum comfort for every guest.
                    </p>
                    <ImageGallery />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </div>
  );
}

function Prop_1(props) {

  let roomLinks = [
    {
      name: 'Standard Room',
      initial: "ST",
      nameStyle: 'bg-green-500 group hover:bg-green-400',
      initialStyle: 'bg-green-400  group-hover:bg-green-600',
      url: '/room?category=standard',
      current: false
    },
    {
      name: 'Deluxe Room',
      initial: "DX",
      nameStyle: 'bg-green-500 group hover:bg-green-400',
      initialStyle: 'bg-green-400 group-hover:bg-green-600',
      url: '/room?category=deluxe',
      current: false
    },
    {
      name: 'Luxury Room',
      initial: "LX",
      nameStyle: 'bg-green-500 group hover:bg-green-400',
      initialStyle: 'bg-green-400 group-hover:bg-green-600',
      url: '/room?category=luxury',
      current: false
    },
    {
      name: 'Full House',
      initial: "FH",
      nameStyle: 'bg-green-500 group hover:bg-green-400',
      initialStyle: 'bg-green-400 group-hover:bg-green-600',
      url: '/room?category=fullhouse',
      current: false
    }
  ]

  const [roomLinkMenu, setRoomLinkMenu] = useState(roomLinks)
  const [image, setImage] = useState()

  useEffect(() => {

    const newList = roomLinkMenu.map((item) => {
      if (item.initial === props.categoryInitial) {
        const updatedItem = {
          ...item,
          nameStyle: "bg-amber-500 group hover:bg-amber-400",
          initialStyle: "bg-amber-400 group-hover:bg-amber-600"
        };

        return updatedItem;
      }

      return item;
    });

    setRoomLinkMenu(newList);

    switch (props.categoryInitial) {
      case 'ST': setImage("https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_4.jpg"); break;
      case 'DX': setImage("https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_5.jpg"); break;
      case 'LX': setImage("https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_6.jpg"); break;
      case 'FH': setImage("https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_7.jpg"); break;
    }

  }, [props.categoryInitial])



  return (
    <figure className="flex flex-col items-center gap-y-8">
      <div className="grid relative col-span-1">

        <ul role="list" className="mt-3 grid grid-cols-1 gap-5 ">
          {
            roomLinkMenu.map((room) => (
              <li key={room.initial} className={classNames(
                room.nameStyle,
                'col-span-4 flex rounded-md shadow-sm'
              )}>
                <a href={room.url} className="col-span-4 flex font-medium text-gray-900 hover:text-amber-600">

                  <div
                    className={classNames(
                      room.initialStyle,
                      'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
                    )}
                  >
                    {room.initial}
                  </div>
                  <div className="flex flex-1  items-center justify-between truncate ">
                    <div className="flex-1 truncate px-4 py-2 text-sm text-white font-semibold">
                      {room.name}
                    </div>
                  </div>
                </a>
              </li>
            ))
          }
        </ul>
      </div>
      <div className="grid col-end-1 w-36 lg:row-span-4 lg:w-72 gap-4 ">
        <img
          className="aspect-square bg-indigo-50 rounded-full"
          src={image}
          alt=""
        />
      </div>
      <BookingCard categoryInitial={props.categoryInitial} />
    </figure>
  )
}

function Features(props) {

  const [headLine, setHeadLine] = useState()
  const [roomFeatures, setRoomFeatures] = useState([])

  useEffect(() => {
    switch (props.categoryInitial) {
      case 'ST': setHeadLine("At Basileia Palace, we strive to provide exceptional service and ensure that your stay in our Standard Rooms is comfortable and hassle-free."); break;
      case 'DX': setHeadLine(""); break;
      case 'LX': setHeadLine("In addition to the standard amenities, our Master Room also offers a number of enhanced features, such as"); break;
      case 'FH': setHeadLine(""); break;
    }
  }, [props.categoryInitial])

  const features = [
    {
      room_category: 'ST',
      name: '',
      description:
        'Comfortable queen-size or twin beds for a restful sleep',
    },
    {
      room_category: 'ST',
      name: '',
      description:
        'Private bathroom with modern fixtures for your convenience',
    },
    {
      room_category: 'ST',
      name: '',
      description:
        'In-room safe provided for securing your valuable belongings',
    },
    {
      room_category: 'ST',
      name: '',
      description:
        'Individually controlled air conditioning, allowing you to set your desired temperature',
    },
    {
      room_category: 'ST',
      name: '',
      description:
        'Daily housekeeping service to keep your room tidy and fresh',
    },
    {
      room_category: 'ST',
      name: '',
      description:
        ' Enjoy the convenience of 24-hour room service for any additional needs or requests'
    },
    {
      room_category: 'ST',
      name: '',
      description:
        ' Mini refrigerator or minibar for added convenience, providing storage for your snacks or beverages'
    },
    {
      room_category: 'ST',
      name: '',
      description:
        'Individually controlled air conditioning, allowing you to personalize the room temperature to your preference'
    },
    {
      room_category: 'ST',
      name: '',
      description:
        'Option for connecting rooms, ideal for families or larger groups seeking to stay together'
    },
    {
      room_category: 'DX',
      name: 'Beds X2',
      description:
        'Two comfortable double beds or queen-size beds, providing ample space for a restful sleep',
    },
    {
      room_category: 'DX',
      name: 'Comfortable Atmosphere',
      description:
        'Stylish and modern décor with meticulous attention to detail, creating a contemporary ambiance',
    },
    {
      room_category: 'DX',
      name: 'Business Savvy',
      description:
        'Dedicated workspace with a desk and ergonomic chair, perfect for productivity or planning your adventures',
    },
    {
      room_category: 'DX',
      name: 'High Speed WiFi',
      description:
        'High-speed Wi-Fi connectivity for multiple devices, keeping you connected throughout your stay',
    },
    {
      room_category: 'DX',
      name: 'Room Ammenities',
      description:
        'In-room coffee and tea making facilities, allowing you to enjoy a refreshing beverage at any time',
    },
    {
      room_category: 'DX',
      name: 'Mini Bar',
      description:
        ' Mini refrigerator or minibar for added convenience, providing storage for your snacks or beverages'
    },
    {
      room_category: 'DX',
      name: 'Smart TV',
      description:
        ' Mini refrigerator or minibar for added convenience, providing storage for your snacks or beverages'
    },
    {
      room_category: 'DX',
      name: 'Air Conditioner',
      description:
        'Individually controlled air conditioning, allowing you to personalize the room temperature to your preference'
    },
    {
      room_category: 'DX',
      name: 'Family Options',
      description:
        'Option for connecting rooms, ideal for families or larger groups seeking to stay together'
    },
    {
      room_category: 'LX',
      name: '',
      description:
        'A larger flat-screen TV or home theater system for enhanced entertainment options'
    },
    {
      room_category: 'LX',
      name: '',
      description:
        'Complimentary access to the hotel\'s wellness facilities or exclusive amenities'
    },
    {
      room_category: 'LX',
      name: '',
      description:
        'Enhanced views or prime location within the hotel'
    },
    {
      room_category: 'LX',
      name: '',
      description:
        'Personalized concierge service for tailored assistance'
    },
    {
      room_category: 'LX',
      name: '',
      description:
        'The Master Room is located on the top floor of the hotel, offering stunning views of the surrounding area'
    },
    {
      room_category: 'LX',
      name: '',
      description:
        'The room is decorated in a modern style with neutral colors and luxurious furnishings.'
    },
    {
      room_category: 'LX',
      name: '',
      description:
        'The bathroom features a walk-in shower, soaking tub, and double vanity.'
    },
    {
      room_category: 'LX',
      name: '',
      description:
        'The concierge service can help you with anything you need, from booking reservations to arranging transportation.'
    },
    {
      room_category: 'FH',
      name: '',
      description:
        'Multiple bedrooms with various bed configurations, ensuring comfort and flexibility for all guests.'
    },
    {
      room_category: 'FH',
      name: '',
      description:
        'Each bedroom is equipped with a private bathroom, often featuring luxurious amenities for your indulgence.'
    },
    {
      room_category: 'FH',
      name: '',
      description:
        'Spacious living and dining areas provide the perfect setting for gatherings, relaxation, and quality time together.'
    },
    {
      room_category: 'FH',
      name: '',
      description:
        'A fully equipped kitchen allows for self-catering or the option to hire a private chef to create culinary delights for you.'
    },
    {
      room_category: 'FH',
      name: '',
      description:
        'Enjoy the luxury of a private swimming pool, garden, or outdoor space, perfect for unwinding and soaking up the sun.'
    },
    {
      room_category: 'FH',
      name: '',
      description:
        'Dedicated staff, including housekeeping, a butler, and a chef if desired, ensure that your every need is met with utmost care.'
    },
    {
      room_category: 'FH',
      name: '',
      description:
        'Stay connected with high-speed Wi-Fi and enjoy entertainment systems throughout the house for your enjoyment.'
    },
    {
      room_category: 'FH',
      name: '',
      description:
        'Personalized concierge services are available to assist you in arranging activities, excursions, and any additional requests'
    },
    {
      room_category: 'FH',
      name: '',
      description:
        'Experience the utmost privacy and security, allowing you to unwind and create unforgettable memories in a truly exclusive setting.'
    },
  ]

  useEffect(() => {
    if (props.categoryInitial !== "") {
      setRoomFeatures(_.filter(features, (f) => { return f.room_category == props.categoryInitial }))
    }
  }, [props.categoryInitial])

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 border-b">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Key Features</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600 font-semibold">
            {headLine}
          </p>
        </div>
        <dl className="mx-auto mt-16 grid   gap-y-1 text-base leading-7 grid-cols-1 ">
          {roomFeatures.map((feature, $index) => (
            <div key={$index} className='p-3 shadow-lg rounded-lg bg-gradient-to-b from-white to-green-400/20'>
              <dt className="font-semibold text-gray-900">{feature.name}</dt>
              <dd className="mt-1 text-gray-600">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}

export function BookingCard(props) {
  const [text, setText] = useState("")

  useEffect(() => {
    switch (props.categoryInitial) {
      case 'ST': setText("Book your stay in our Standard Rooms and immerse yourself in a world of comfort, style, and relaxation"); break;
      case 'DX': setText("Book your Deluxe Double Room today and embark on a journey of relaxation and indulgence at Basileia Palace."); break;
      case 'LX': setText("Book your Luxury Room today and embark on a journey of relaxation and indulgence at Basileia Palace."); break;
      case 'FH': setText("Book the Full House today and embark on a journey of relaxation and indulgence at Basileia Palace."); break;
    }
  }, [props.categoryInitial])

  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg  shadow flex sm:gap-px sm:divide-y-0">
      <div className="sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>{text}</p>
          </div>
          <div className="mt-5">
            <a className="hover:text-gray-900" href="https://wa.me/18768522152">
              <button className='flex font-bold uppercase gap-x-2 text-green-400'>
                <PhoneIcon className="rounded-full  p-0.5 ring-1 ring-green-400 bg-gradient-to-r from-green-400 to-green-500 w-6 h-6 text-white" />
                <span>Book via WhatsApp</span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

