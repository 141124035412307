import React, { useContext } from "react";
import _ from "lodash";
import { MainLayout } from "../../layouts/mainLayout";
import { MobileLayout } from "../../layouts/mobileLayout";
import WeddingContentBlock from "../../components/ContentAreaBlocks/WeddingContentBlock"
import {WeddingContentBlock as MobileWeddingContentBlock} from "../../components/Mobile/MobileContentAreaBlocks/WeddingContentBlock";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Footer_alt from "../../components/Footer/Footer_alt";
import ContentArea from "../../components/ContentArea";
export default function CorporatePage() {
  return (
    <>
      <BrowserView>
        <MainLayout> 
          <Footer_alt />
        </MainLayout>
      </BrowserView>  
      <MobileView>
        <MobileLayout>
          <MobileWeddingContentBlock />
        </MobileLayout>
      </MobileView>
    </>
  );
}
