import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'


const products = [
    {
        name: "Weddings",
        description: "Get a better understanding where your traffic is coming from",
        href: "/events?category=wedding",
        qoute_href: "/quote?v=wedding",
        title: 'Paradigm Representative',
        role: 'Admin',
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://cdn.basileiapalaceja.com/media/wedding/wedding_3.jpg',
    },
    {
        name: "Corporate Events",
        description: "Get a better understanding where your traffic is coming from",
        href: "/events?category=corporate", 
        qoute_href: "/quote?v=corporate",
        title: 'Paradigm Representative',
        role: 'Admin',
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://cdn.basileiapalaceja.com/media/indoor_image_4.jpg',
    },
    {
        name: "Outdoor Celebrations",
        description: "Get a better understanding where your traffic is coming from",
        href: "/events?category=OutdoorCelebration",
        qoute_href: "/quote?v=outdoor",
        title: 'Paradigm Representative',
        role: 'Admin',
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://cdn.basileiapalaceja.com/media/building/building_4.jpg',
    },
    {
        name: "Pool Party",
        description: "Speak directly to your customers with our engagement tool",
        href: "/events?category=Pool",
        qoute_href: "/quote?v=pool",
        title: 'Paradigm Representative',
        role: 'Admin',
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://cdn.basileiapalaceja.com/media/building/building_9.jpg',
    },
    /* {
        name: "Graduation",
        description: "Your customers’ data will be safe and secure",
        href: "/events?category=Graduation",
        qoute_href: "/quote?v=graduation",
        title: 'Paradigm Representative',
        role: 'Admin',
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://assets.teenvogue.com/photos/5e66a34f7ac2c30008a4819d/16:9/w_2560%2Cc_limit/GettyImages-1062799214.jpg',
    }, */
    {
        name: "Repass & Memorial",
        description: "Your customers’ data will be safe and secure",
        href: "/events?category=Repass",
        qoute_href: "/quote?v=repass",
        title: 'Paradigm Representative',
        role: 'Admin',
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:'https://cdn.basileiapalaceja.com/media/repass/repass_4.jpg',
    },
    {
        name: "Baby Shower",
        description: "Get a better understanding where your traffic is coming from",
        href: "/events?category=BabyShower",
        qoute_href: "/quote?v=babyshower",
        title: 'Paradigm Representative',
        role: 'Admin',
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://cdn.basileiapalaceja.com/media/baby_shower/babyshower_15.jpg',
    },
    {
        name: "Birthday Party",
        description: "Speak directly to your customers with our engagement tool",
        href: "/events?category=Birthday",
        qoute_href: "/quote?v=birthday",
        title: 'Paradigm Representative',
        role: 'Admin',
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://cdn.basileiapalaceja.com/media/birthday/birthday_55.jpg',
    },
    {
        name: "Catering Services",
        description: "Your customers’ data will be safe and secure",
        href: "/events?category=Catering",
        qoute_href: "/quote?v=catering",
        title: 'Paradigm Representative',
        role: 'Admin',
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://cdn.basileiapalaceja.com/media/Corporate/corporate_6.jpg',
    },
    /* {
        name: "Conferences",
        description: "Your customers’ data will be safe and secure",
        href: "/events?category=Conference",
        qoute_href: "/quote?v=conference",
        title: 'Paradigm Representative',
        role: 'Admin',
        email: 'janecooper@example.com',
        telephone: '+1-202-555-0170',
        imageUrl:
            'https://cdn.basileiapalaceja.com/media/building/building_3.jpg',
    }, */
];


export default function EventCardList() {
    return (
        <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mb-12 py-12 mx-8">
            {products.map((event) => (
                <li
                    key={event.href}
                    className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-xl overflow-hidden"
                >
                    <div className="relative flex flex-1 flex-col">
                        <img className="mx-auto h-full w-full flex-shrink-0 aspect-square object-top object-cover" src={event.imageUrl} alt="" />
                        <h3 className="absolute mt-0 h-10 p-3 bg-black/20 backdrop-blur-sm text-lg font-thin text-center w-full text-gray-100 uppercase">{event.name}</h3>
                        <dl className="absolute mt-1 flex flex-grow flex-col justify-between">
                            <dt className="sr-only">Title</dt>
                        </dl>
                    </div>
                    <div>
                        <div className="-mt-px flex divide-x divide-gray-200">
                            <div className="-ml-px flex w-0 flex-1 hover:bg-gradient-to-tr from-amber-500 to-amber-200">
                                <a
                                    href={`${event.href}`}
                                    className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                                >
                                    More
                                </a>
                            </div>
                            <div className="-ml-px flex w-0 flex-1 hover:bg-gradient-to-tl from-amber-500 to-amber-200">
                                <a
                                    href={`${event.qoute_href}`}
                                    className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                                >
                                    Get Quote
                                </a>
                            </div>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    )
}
