import React, { useContext } from "react";
import _ from "lodash";
import { isBrowser, isMobile } from "react-device-detect";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import CategoryBlock from "./CategoryBlock";
import ContentArea from "../ContentArea";
import { PriceModule, PriceModule_2 } from "../PriceModule"

const incentives = [
  {
    name: "Podium",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "Basileia Palace offers podiums in their meeting rooms that are equipped with state-of-the-art technology and designed for the comfort of the speaker, providing a platform for effective and clear presentations during conferences and meetings.",
  },
  {
    name: "PA Systems",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "The PA system features advanced technology and equipment, including microphones, speakers, and amplifiers, that ensure clear and crisp sound quality. With the PA system, guests can enjoy seamless and effective communication during their meetings or events.",
  },
  {
    name: "WIFI Internet",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Basileia Palace provides secure and high-speed WiFi for guests in all areas of the hotel, enabling them to stay connected and productive during their stay.",
  },
  {
    name: "Projector",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "Basileia Palace offers projectors for use in their meeting rooms and outdoor celebrations venue, providing a high-quality audio-visual experience for presentations and entertainment. ",
  },
  {
    name: "Mini Bar",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "Basileia Palace offers mini-bar providing a convenient and refreshing selection of beverages and snacks for guests.",
  },
  {
    name: "Catering",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Guests can enjoy their meals in the dining areas or outdoor venue, which is a picturesque and tranquil oasis. The catering service is designed to provide guests with a memorable and enjoyable dining experience.",
  },
];

const tiers = [
  {
    name: "Basic",
    id: "tier-basic",
    href: "#",
    price: { monthly: "$15", annually: "$12" },
    description: "Everything necessary to get started.",
    features: [
      "5 products",
      "Up to 1,000 subscribers",
      "Basic analytics",
      "48-hour support response time",
    ],
  },
  {
    name: "Essential",
    id: "tier-essential",
    href: "#",
    price: { monthly: "$30", annually: "$24" },
    description:
      "Everything in Basic, plus essential tools for growing your business.",
    features: [
      "25 products",
      "Up to 10,000 subscribers",
      "Advanced analytics",
      "24-hour support response time",
      "Marketing automations",
    ],
  },
  {
    name: "Growth",
    id: "tier-growth",
    href: "#",
    price: { monthly: "$60", annually: "$48" },
    description:
      "Everything in Essential, plus collaboration tools and deeper insights.",
    features: [
      "Unlimited products",
      "Unlimited subscribers",
      "Advanced analytics",
      "1-hour, dedicated support response time",
      "Marketing automations",
      "Custom reporting tools",
    ],
  },
];

const Elem = () => (
  <div className="bg-white/100 backdrop-blur-sm">
    <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
      <div>

        <dl className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
          {incentives.map((incentive) => (
            <div key={incentive.name} className="sm:flex lg:block">
              <div className="sm:flex-shrink-0">
                <img
                  className="hidden h-16 w-16"
                  src={incentive.imageSrc}
                  alt=""
                />
              </div>
              <div className="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                <h3 className="text-sm font-bold underline text-gray-800">
                  {incentive.name}
                </h3>
                <p className="mt-2 text-sm text-gray-800">
                  {incentive.description}
                </p>
              </div>
            </div>
          ))}
        </dl>
      </div>
      <div className="grid grid-cols-3 grid-rows-2 place-items-center gap-4 sm:gap-6 lg:gap-8">
        <img
          src="https://cdn.basileiapalaceja.com/media/pool_party/poolparty_26.jpg"
          alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
          className="rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/pool_party/poolparty_27.jpg"
          alt="Top down view of walnut card tray with embedded magnets and card groove."
          className="rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/pool_party/poolparty_20.jpg"
          alt="Top down view of walnut card tray with embedded magnets and card groove."
          className="rounded-lg bg-gray-100 shadow-md shadow-gray-500"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/pool_party/poolparty_5.jpg"
          alt="Side of walnut card tray with card groove and recessed card area."
          className="rounded-lg bg-gray-100 shadow-md shadow-gray-500"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/pool_party/poolparty_36.jpg"
          alt="Walnut card tray filled with cards and card angled in dedicated groove."
          className="rounded-lg bg-gray-100 col-span-2"
        />
      </div>
    </div>
  </div>
);

function Hero() {  
  return (
    <div className="overflow-hidden bg-white/0">
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 justify-items-center gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8 ">
          <div className="grid grid-cols-4 w-full gap-10 space-around">

            <img
              src="https://cdn.basileiapalaceja.com/media/building/building_18.jpg"
              alt=""
              className="aspect-[4/4] w-[15rem] max-w-none ring-4 ring-white bg-gray-50 object-cover object-center rounded-full drop-shadow-md"
            />

            <img
              src="https://cdn.basileiapalaceja.com/media/building/building_16.jpg"
              alt=""
              className="aspect-[4/4] w-[15rem] max-w-none ring-4 ring-white bg-gray-50 object-cover object-center rounded-full drop-shadow-md"
            />

            <img
              src="https://cdn.basileiapalaceja.com/media/building/building_14.jpg"
              alt=""
              className="aspect-[4/4] w-[15rem] max-w-none ring-4 ring-white bg-gray-50 object-cover object-center rounded-full drop-shadow-md"
            />

            <img
              src="https://cdn.basileiapalaceja.com/media/pool_party/poolparty_7.jpg"
              alt=""
              className="aspect-[4/4] w-[15rem] max-w-none ring-4 ring-white bg-gray-50 object-cover object-top rounded-full drop-shadow-md"
            />
          </div>

          <div className="w-full max-w-lg  p-0 bg-slate-700 rounded-2xl mb-10 overflow-hidden">
            <img
              src="https://cdn.basileiapalaceja.com/media/building/building_25.jpg"
              alt=""
              className="aspect-[18/4] w-full max-w-none ring-0 ring-white bg-gray-50 object-cover object-center rounded-t-2xl drop-shadow-md"
            />
            <p className="mt-6 text-xl leading-8 text-sky-400 text-wrap p-4">

              At Basileia Palace, we understand the importance of creating unforgettable memories. Our dedicated team is committed to ensuring that every detail of your pool party is taken care of, from the setup to the entertainment. Let us handle the logistics while you relax and enjoy the company of your guests, knowing that your pool party is in capable hands.
            </p>
            <p className="mt-6 text-base leading-7 text-sky-400 p-4 ">
              Get ready to make a splash and have a splashing good time at Basileia Palace's Pool Party! Whether you're celebrating a birthday, hosting a corporate event, or simply looking for a fun summer activity, our pool parties are the perfect choice. With a picturesque outdoor celebrations venue, spacious pool area, and a range of exciting activities, we'll help you create an unforgettable experience for you and your guests.
            </p>
          </div>
        </div>

      </div>
      <div
        className="relative w-full rounded-none h-full  overflow-auto bg-fixed bg-bottom  bg-contain bg-no-repeat backdrop-blur-lg"

      >
        <div className="overflow-hidden rounded-none p-0 mx-auto grid max-w-2xl grid-cols-1 justify-items-center gap-x-0 gap-y-0 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-0 ">

          <div className="flex flex-wrap items-start justify-end gap-0 sm:gap-0 lg:contents">
            <div className="w-0 flex gap-0 lg:ml-auto lg:w-auto lg:flex-none lg:self-start ">
              <img
                src="https://cdn.basileiapalaceja.com/media/pool_party/poolparty_4.jpg"
                alt=""
                className="aspect-[7/5] w-[20rem] max-w-none  bg-gray-50 object-cover object-top shadow-md shadow-gray-500"
              /> <img
                src="https://cdn.basileiapalaceja.com/media/pool_party/poolparty_16.jpg"
                alt=""
                className="aspect-[7/5] w-[20rem] max-w-none bg-gray-50 object-cover object-top shadow-md shadow-gray-500"
              /> <img
                src="https://cdn.basileiapalaceja.com/media/pool_party/poolparty_7.jpg"
                alt=""
                className="aspect-[7/5] w-[40rem] max-w-none  bg-gray-50 object-cover object-top shadow-md shadow-gray-500"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const d = (
  <div className="mx-auto  max-w-7xl lg:px-4 py-0">
    <div
      className="w-full rounded-none h-full  overflow-auto bg-fixed   bg-cover bg-no-repeat bg-gradient-to-r from-violet-500 to-fuchsia-500"
      style={{
        backgroundImage: `url('https://images.hdqwalls.com/wallpapers/swimming-pool-water-dt.jpg')`,
      }}
    >
      <div className="mx-auto max-w-7xl py-8  lg:max-w-none">
        <div className="grid grid-cols-1 items-center gap-x-16 gap-y-0 lg:grid-cols-2 py-0">
          <h2 className="text-8xl font-thin tracking-tight text-gray-100 px-10">
            <p>Pool Party</p>
            <p className="text-lg tracking-normal font-bold indent-3">@ Basiliea Palace</p>
          </h2>
          <p className="m-4 bg-white/100 text-2xl text-sky-400 p-8  rounded-lg antialiased hover:subpixel-antialiased bg-black/20">
            Dive into the ultimate poolside experience with our vibrant and energetic pool parties. Beat the heat and dance to the rhythm of the music as you enjoy refreshing cocktails and delicious bites. With a lively atmosphere, luxurious poolside loungers, and dedicated staff to cater to your needs, our pool parties are the epitome of summer fun.
          </p>
          <div className="my-0 col-span-2 bg-white/0 rounded-xl">
            <Hero />
          </div>
        </div>
        <Elem />
        <PriceModule category='pool' />
      </div>
    </div>
  </div>
);

export default function BirthdayContentBlock() {
  return <ContentArea>{d}</ContentArea>;
}
