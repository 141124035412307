import React, { Fragment, Component, useEffect, useState, useContext } from 'react'

import NavigationBar from '../components/NavigationBar';
import { isBrowser, isMobile } from "react-device-detect";
import img from '../images/bg-out-1.jpg'
import { HomeIcon } from '@heroicons/react/20/solid';
import { Bars4Icon } from '@heroicons/react/24/outline';
import PopOverMenu from '../components/Mobile/PopOverMenu';
export class MobileLayout extends Component {
    render() {
        return <>
            <div className='flex flex-row fixed h-full w-screen overflow-hidden transparent' >
                <PopOverMenu />
                <div className='pt-2 flex flex-col  grow w-full    rounded-lg'>

                    <main className='mt-0 grow flex flex-col overflow-auto'>
                        {this.props.children}
                    </main>

                </div>
            </div>
        </>
    }
}