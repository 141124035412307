import { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition, Switch, Popover } from '@headlessui/react'
import _ from "lodash";
import {
    Bars3Icon,
    BellIcon,
    CalendarIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon, PhotoIcon, UserCircleIcon, CheckIcon, TrashIcon, InformationCircleIcon, ChatBubbleLeftRightIcon, PaperAirplaneIcon, EnvelopeIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon, XCircleIcon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { Storage } from "@aws-amplify/storage"
import { DataStore } from '@aws-amplify/datastore';
import { EventRequest } from '../models';
import awsExports from '../aws-exports';
import { Amplify } from 'aws-amplify';
import NewEventRequest from "../components/NewEventRequest";
import '@aws-amplify/ui-react/styles.css';
import moment from "moment"
import { Divider } from '@aws-amplify/ui-react'
Amplify.configure(awsExports);

const statuses = {
    Paid: 'text-green-700 bg-green-50 ring-green-600/20',
    Withdraw: 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Overdue: 'text-red-700 bg-red-50 ring-red-600/10',
}
const clients = [
    {
        id: 1,
        name: 'Tuple',
        imageUrl: 'https://tailwindui.com/img/logos/48x48/tuple.svg',
        lastInvoice: { date: 'December 13, 2022', dateTime: '2022-12-13', amount: '$2,000.00', status: 'Overdue' },
    },
    {
        id: 2,
        name: 'SavvyCal',
        imageUrl: 'https://tailwindui.com/img/logos/48x48/savvycal.svg',
        lastInvoice: { date: 'January 22, 2023', dateTime: '2023-01-22', amount: '$14,000.00', status: 'Paid' },
    },
    {
        id: 3,
        name: 'Reform',
        imageUrl: 'https://tailwindui.com/img/logos/48x48/reform.svg',
        lastInvoice: { date: 'January 23, 2023', dateTime: '2023-01-23', amount: '$7,600.00', status: 'Paid' },
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join('')
}

function returnTimeElapsed(start) {
    let duration = moment.duration(moment(start).diff(moment()));
    let hours = duration.asHours();

    return moment.duration(hours, 'hours').humanize(true);
}

function SwitchToggle(props) {
    const [enabled, setEnabled] = useState(false)

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        props.isAdmin &&
        <span className='flex gap-4'>
            <Switch
                checked={enabled}
                onChange={setEnabled}
                className={classNames(
                    enabled ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                )}
            >
                <span className="sr-only">Use setting</span>
                <div className='w-1/4'>
                    <span
                        className={classNames(
                            enabled ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        )}
                    >
                        <span
                            className={classNames(
                                enabled ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
                                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                            )}
                            aria-hidden="true"
                        >
                            <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                <path
                                    d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </span>
                        <span
                            className={classNames(
                                enabled ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
                                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                            )}
                            aria-hidden="true"
                        >
                            <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                            </svg>
                        </span>

                    </span></div>
            </Switch>

            <div className='flex flex-grow'>approve</div>
        </span>

    )
}

const DeleteMessageBtn = (props) => {
    const [toggle, setToggle] = new useState(false)

    function toggleState() {
        setToggle(!toggle)
    }

    function removeMessage() {

        const modelToDelete = async () => await DataStore.query(EventRequest, props.quote?.id).then((model) => {
            DataStore.delete(model);
            toggleState();
        })
        modelToDelete()
    }

    useEffect(() => {
        console.log(props.quote?.email, props.userAttributes?.email)
    }, [])

    if (props.quote?.email === props.userAttributes?.email || props.isAdmin) {
        return <div className='w-full h-8 flex justify-content-start'>
            <Transition.Root show={!toggle} as={Fragment}>

                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-100"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <button className='flex align-center hover:text-red-500 text-xs' onClick={() => { toggleState() }}>
                        <span><TrashIcon className='h-4' /></span><span>Delete</span>
                    </button>
                </Transition.Child>
            </Transition.Root>

            <Transition.Root show={toggle} as={Fragment}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="w-full h-14 grid grid-cols-2 inset-0 bg-opacity-75 transition-opacity bg-white shadow-lg p-2 rounded-b-xl">
                        <span className="text-xs">Delete this record?</span>
                        <div className="flex ">
                            <div className="flex-grow  grid justify-items-center">
                                <button className='hover:text-red-500 text-xs' onClick={() => { removeMessage() }}>Yes</button>
                            </div>
                            <div className="flex-grow grid justify-items-center">
                                <button className='hover:text-red-500 text-xs' onClick={() => { toggleState() }}>No</button>
                            </div>
                        </div>
                    </div>
                </Transition.Child>
            </Transition.Root>
        </div>
    } else {
        return <></>
    }

}

export default function RequestedEventGridList(props) {

    const [ds, setDs] = new useState(DataStore.state)
    const [i, setI] = new useState(null)
    const [quotes, addQuote] = useState([])

    async function fetchEvents() {
        const sub = DataStore.observeQuery(EventRequest).subscribe(({ items }) => {
            let list = _.map(_.filter(items, (m) => { return props.isAdmin || m.email == props.userAttributes.email }), (item) => { return { ...item } })
            _.forEach(list,(item)=>{
                item.mailText = `mailto:${item?.email}?subject= ${item?.name}'s ${item.eventType} event request for ${moment(item.date).format("MMMM Do")} &body=Dear ${item?.name},`
            })
            addQuote(_.orderBy(list, 'updatedAt', 'desc'))
        });
        return () => {
            sub.unsubscribe();
        };
        /* const models = await DataStore.query(EventRequest);
        addQuote(models) */
    }





    useEffect(() => {
        if (ds === 'Starting' || ds === 'Running') {
            fetchEvents()
            setI(null)
            clearInterval(i);
        }
    }, [ds])

    useEffect(() => {


        setI(setInterval(() => {
            setDs(DataStore.state)
        }, 1000))


    }, [])

    const sortedData = quotes.sort((a, b) => a.name - b.name);
    const qouteFeatures = (quote) => {
        let features = [];
        if (quote.check_bar) features.push(<li className="grid grid-cols-2 gap-x-1 place-items-center">
            <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
            Mini Bar
        </li>)


        if (quote.check_catering) features.push(<li className="grid grid-cols-2 gap-x-1 place-items-center">
            <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
            Catering
        </li>)



        if (quote.check_indoor) features.push(<li className="grid grid-cols-2 gap-x-1 place-items-center">
            <CheckIcon className="h-6 flex-none text-indigo-600" aria-hidden="true" />
            Indoor
        </li>)


        if (quote.check_outdoor) features.push(<li className="grid grid-cols-2 gap-x-1 place-items-center">
            <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
            Outdoor
        </li>)


        if (quote.check_pas) features.push(<li className="grid grid-cols-2 gap-x-1 place-items-center">
            <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
            PA System
        </li>)


        if (quote.check_podium) features.push(<li className="grid grid-cols-2 gap-x-1 place-items-center">
            <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
            Podium
        </li>)


        if (quote.check_projector) features.push(<li className="grid grid-cols-2 gap-x-1 place-items-center">
            <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
            Projector
        </li>)

        return features.length > 0 ? features : <><p className='ml-8 text-gray-400'>No Options</p></>;

    }
    return (
        <>
            <NewEventRequest quotes={quotes} user={props.user} userAttributes={props.userAttributes} isAdmin={props.isAdmin} />
            <ul role="list" className="divide-y divide-gray-200   gap-x-6 gap-y-8 lg:grid-cols-1 xl:gap-x-8">
                {sortedData.map((quote) => (
                    <li key={quote?.id} className="overflow-hidden">
                        <div className="flex min-h-[220px] items-center gap-x-4  p-6">
                            <div className="flex-grow relative mx-5">

                                <div className="relative flex justify-start">
                                    <div className="flex justify-between gap-x-4 py-3 uppercase font-bold place-items-center">
                                        <dd className="text-gray-500">
                                            <div
                                                className="relative overflow-hidden   p-1 h-18"
                                            >
                                                <div>
                                                    <div className="absolute top-3 left-4 rounded-md bg-indigo-500 p-1">
                                                        <UsersIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                                    </div>
                                                    <span className="ml-16 truncate text-xs font-medium text-gray-500">Guests</span>
                                                </div>
                                                <div className="ml-16 pl-2 flex items-baseline">
                                                    <span className="text-xl font-semibold text-gray-900">{quote.partySize ? quote.partySize : 0}</span>
                                                </div>
                                            </div>
                                        </dd>
                                        <dd className="grid">
                                            <div className="text-sky-500">
                                                {quote?.eventType}
                                            </div>
                                            <div className="text-gray-700 flex flex-col align-center  content-start justify-start space-x-3">
                                                {moment(quote?.date).format("MMM Do YYYY")}
                                            </div>
                                        </dd>

                                    </div>




                                </div>
                                <div className="flex justify-between gap-x-4 py-3 px-8 bg-gray-50 rounded-full text-xs">
                                    <div className='flex gap-4'>
                                        <dt className="text-gray-500 font-bold">{quote?.name ? quote?.name : '[ no name attached ]'}</dt>
                                        <dt className="text-gray-500">{quote?.email ? quote?.email: '[ no email attached ]'}</dt>
                                        <dt className="text-gray-500">{quote?.phone ? quote?.phone : '[ no phone attached ]'}</dt>
                                    </div>
                                </div>
                                <div className="flex justify-between gap-x-4 mt-2 py-3 px-8  rounded-full text-xs">
                                    <div className='flex gap-4'>
                                        <ul className='flex flex-row space-x-3'>
                                            {
                                                qouteFeatures(quote)
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                    {quote?.details &&
                                        <div className="relative bg-gray-50 p-4">
                                            <span className='absolute top-1 left-4 text-[7pt] text-gray-400'>comment</span>
                                            <span className="block text-sm text-gray-500">
                                                {quote?.details}
                                            </span>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='flex flex-col gap-4 h-full w-48 border-l border-gray-200/80'>
                                <div className='flex flex-grow-0  relative space-x-4 px-3 w-full place-items-start  justify-start'>

                                    <DeleteMessageBtn quote={quote} userAttributes={props.userAttributes} isAdmin={props.isAdmin} />

                                </div>
                                {props.isAdmin &&
                                    <a href={quote.mailText}>
                                        <span className='grid grid-cols-2 w-16 pl-2 place-items-center text-[9pt]'> <EnvelopeIcon className='h-4' /> Reply  </span>
                                    </a>
                                }
                                {/* <div className='flex flex-grow-0 space-x-4 px-3 w-full place-items-center  justify-center'>
                                <div className='w-1/4'><TrashIcon className='h-6' /></div>
                                <div className='flex-grow'>edit</div>
                            </div>
                            <div className='flex flex-grow space-x-4 px-3 w-full place-items-center  justify-center bg-gray-200'>
                                <div className='w-1/4'><ChatBubbleLeftRightIcon className='h-6' /></div>
                                <div className='flex-grow bg-gray-200'>comments</div>
                            </div>
                            <div className='flex flex-grow space-x-4 px-3 w-full place-items-center  justify-center bg-gray-200'>
                                <div className='flex-grow'><SwitchToggle {...props} /></div>
                            </div> */}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    )
}
