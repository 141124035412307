import { ChevronRightIcon } from "@heroicons/react/24/outline";

export default function CategoryBlock(props) {
  const Wedding = (props) => {
    const title = 'Weddings'
    const link = "/events/wedding";
    return <>
      <main>
        <div className="relative isolate">
          <svg
            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
            />
          </svg>
          <div
            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  "polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
              }}
            />
          </div>
          <div className="overflow-hidden">
            <div className="mx-auto max-w-7xl px-6 pb-2 pt-6 sm:pt-20 lg:px-8 lg:pt-2">
              <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex flex-row-reverse lg:max-w-none lg:items-center">
                <div className="w-fit h-fit ring-1 ring-gray-200 bg-gradient-to-br from-green-800 to-green-500 rounded-xl backdrop-blur-lg py-10 px-4 shadow-2xl">
                  <h1 className="text-4xl font-bold tracking-tight text-yellow-400 sm:text-6xl">
                    Weddings
                  </h1>
                  <p className="mt-6 text-lg leading-8 text-amber-200">
                  Contact our wedding specialists to schedule a consultation and start planning the wedding of your dreams. Discover the elegance and beauty of Basileia Palace as you embark on a romantic journey filled with love, laughter, and cherished memories. Let us be a part of your love story and create an enchanting wedding experience that surpasses your wildest dreams.
                  </p>

                  <div className="m-3 bg-yellow-400 shadow-xl sm:rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                      <h3 className="text-base font-semibold leading-6 text-gray-900">
                        We accomodate large events up to 150 patrons
                      </h3>
                      <div className="mt-2 max-w-xl text-sm text-gray-800">
                        <p>
                          we provide chairs, tables, podiums and PA systems.
                          If you want we also provide catering and
                          refreshments and many more.
                        </p>
                      </div>
                      <div className="flex  mt-3 leading-6 bg-white/30 ring-1 ring-white/50 rounded-full">
                        <div className="grow grid place-items-center text-amber-700 text-xs indent-6 uppercase text-bold justify-start">
                           More Information on {title}</div>
                        <a
                          href={link}
                          className="font-semibold  cursor-pointer"
                        >
                          <div className="absolute w-12 h-12 rounded-full ring-1 ring-yellow-100 animate-ping"></div>
                          <div className="grid place-items-center w-12 h-12 rounded-full bg-white hover:bg-green-800 text-amber-500 hover:text-white">
                            <span aria-hidden="true" className="animate-ping text-xl"> &rarr;</span>
                          </div>
                        </a>
                      </div>{" "}
                    </div>
                  </div>
                </div>
                <div className="mt-14 flex flex-row-reverse justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                  <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                    <div className="relative">
                      <img
                        src="https://cdn.basileiapalaceja.com/media/wedding/wedding_1.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                    <div className="relative">
                      <img
                        src="https://cdn.basileiapalaceja.com/media/wedding/wedding_2.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src="https://cdn.basileiapalaceja.com/media/wedding/wedding_3.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                    <div className="relative">
                      <img
                        src="https://cdn.basileiapalaceja.com/media/wedding/wedding_4.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src="https://cdn.basileiapalaceja.com/media/wedding/wedding_5.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  };
  const Birthday = (props) => {
    const title = 'Birthday Parties'
    const link = "/events/birthday";
    return <>
      <div
        className="relative"
        style={{
          backgroundImage: `url('images/balloon_canvas.jpg')`,
          backgroundSize: "300px 100px",
          backgroundRepeat: "no-repeat",
          backgroundOrigin: "content-box",
          backgroundPosition: "20% 90%",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="relative">
          <div className="mx-auto max-w-7xl">
            <div className="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
              <svg
                className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block "
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                aria-hidden="true"
              >
                <polygon points="0,0 90,0 50,100 0,100" />
              </svg>

              <div className="relative px-6 py-12 sm:py-20 lg:px-8 lg:py-36 lg:pr-0">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                  <div className="w-fit h-fit ring-1 ring-gray-200 bg-gradient-to-tr from-red-800 to-red-400 rounded-xl backdrop-blur-lg py-10 px-4">
                    <h1 className="text-4xl font-bold tracking-tight text-yellow-400 sm:text-6xl">
                      Birthday Parties
                    </h1>
                    <p className="mt-6 text-lg leading-8 text-amber-200">
                    Get in touch with us now to discuss your birthday or graduation celebration requirements and secure your preferred date. Let Basileia Palace be the venue of choice for creating cherished memories and celebrating life's milestones in style.
                    </p>

                    <div className="m-3 bg-yellow-400 shadow-xl sm:rounded-lg">
                      <div className="px-4 py-5 sm:p-6">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">
                          We accomodate large events up to 150 patrons
                        </h3>
                        <div className="mt-2 max-w-xl text-sm text-gray-800">
                          <p>
                            we provide chairs, tables, podiums and PA systems.
                            If you want we also provide catering and
                            refreshments and many more.
                          </p>
                        </div>
                        <div className="flex  mt-3 leading-6 bg-white/30 ring-1 ring-white/50 rounded-full">
                          <div className="grow grid place-items-center text-amber-700 text-xs indent-6 uppercase text-bold justify-start">
                             More Information on {title}</div>
                          <a
                            href={link}
                            className="font-semibold  cursor-pointer"
                          >
                            <div className="absolute w-12 h-12 rounded-full ring-1 ring-yellow-100 animate-ping"></div>
                            <div className="grid place-items-center w-12 h-12 rounded-full bg-white hover:bg-pink-500 text-amber-500 hover:text-white">
                              <span aria-hidden="true" className="animate-ping text-xl"> &rarr;</span>
                            </div>
                          </a>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-500 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img
              className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
              src="https://cdn.basileiapalaceja.com/media/otherImages/image_21.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  };
  const Corporate = (props) => {
    const title = 'Corporate Meetings'
    const link = "/events/corporate";
    return <>
      <main>
        <div className="relative isolate">
          <svg
            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
            />
          </svg>
          <div
            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  "polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
              }}
            />
          </div>
          <div className="overflow-hidden">
            <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
              <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                <div className="w-fit h-fit ring-1 ring-gray-200 bg-gradient-to-tr from-slate-500 to-zinc-700 rounded-xl backdrop-blur-lg py-10 px-4 shadow-2xl">
                  <h1 className="text-4xl font-bold tracking-tight text-yellow-400 sm:text-6xl">
                    Corporate Meetings
                  </h1>
                  <p className="mt-6 text-lg leading-8 text-amber-200">
                  Plan Your Corporate Meeting: Contact us now to discuss your corporate event requirements and reserve our state-of-the-art meeting rooms. Experience unparalleled service, top-notch amenities, and a tranquil setting that will elevate your corporate gatherings to new heights.
                  </p>

                  <div className="m-3 bg-zinc-400 shadow-xl sm:rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                      <h3 className="text-base font-semibold leading-6 text-gray-900">
                        We accomodate large events up to 100 patrons
                      </h3>
                      <div className="mt-2 max-w-xl text-sm text-gray-700">
                        <p>
                          we provide chairs, tables, podiums and PA systems.
                          If you want we also provide catering and
                          refreshments and many more.
                        </p>
                      </div>
                      <div className="flex  mt-3 leading-6 bg-white/30 ring-1 ring-white/50 rounded-full">
                        <div className="grow grid place-items-center text-amber-700 text-xs indent-6 uppercase text-bold justify-start">
                           More Information on {title}</div>
                        <a
                          href={link}
                          className="font-semibold  cursor-pointer"
                        >
                          <div className="absolute w-12 h-12 rounded-full ring-1 ring-yellow-100 animate-ping"></div>
                          <div className="grid place-items-center w-12 h-12 rounded-full bg-white hover:bg-amber-500 text-amber-500 hover:text-white">
                            <span aria-hidden="true" className="animate-ping text-xl"> &rarr;</span>
                          </div>
                        </a>
                      </div>{" "}
                    </div>
                  </div>
                </div>
                <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                  <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                    <div className="relative">
                      <img
                        src="https://cdn.basileiapalaceja.com/media/indoor_image_1.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                    <div className="relative">
                      <img
                        src="https://cdn.basileiapalaceja.com/media/indoor_image_2.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src="https://cdn.basileiapalaceja.com/media/indoor_image_3.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                    <div className="relative">
                      <img
                        src="https://cdn.basileiapalaceja.com/media/indoor_image_4.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src="https://cdn.basileiapalaceja.com/media/indoor_image_5.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  };
  const Babyshower = (props) => {
    const title = 'Baby Showers'
    const link = "/events/babyshower";
    return <>
      <main>
        <div className="relative isolate">
          <svg
            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
            />
          </svg>
          <div
            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  "polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
              }}
            />
          </div>
          <div className="overflow-hidden">
            <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
              <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                <div className="w-fit h-fit ring-1 ring-gray-200 bg-gradient-to-tr from-cyan-500 to-sky-600 rounded-xl backdrop-blur-lg py-10 px-4 shadow-2xl">
                  <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                    Baby Showers
                  </h1>
                  <p className="mt-6 text-lg leading-8 text-amber-200">
                  Plan Your Baby Shower: Contact us now to discuss your baby shower requirements and reserve our venue. Let us help you create an enchanting celebration that honors the expectant parents and their little bundle of joy..
                  </p>

                  <div className="m-3 bg-gradient-to-r from-pink-400 to-pink-400 shadow-xl sm:rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                      <h3 className="text-base font-semibold leading-6 text-white">
                        We accomodate large events up to 150 patrons
                      </h3>
                      <div className="mt-2 max-w-xl text-sm text-white">
                        <p>
                          we provide chairs, tables, podiums and PA systems.
                          If you want we also provide catering and
                          refreshments and many more.
                        </p>
                      </div>
                      <div className="flex  mt-3 leading-6 bg-white/30 ring-1 ring-white/50 rounded-full">
                        <div className="grow grid place-items-center text-white text-xs indent-6 uppercase text-bold justify-start">
                          More Information on {title}</div>
                        <a
                          href={link}
                          className="font-semibold  cursor-pointer"
                        >
                          <div className="absolute w-12 h-12 rounded-full ring-1 ring-yellow-100 animate-ping"></div>
                          <div className="grid place-items-center w-12 h-12 rounded-full bg-white hover:bg-sky-500 text-amber-500 hover:text-white">
                            <span aria-hidden="true" className="animate-ping text-xl"> &rarr;</span>
                          </div>
                        </a>
                      </div>{" "}
                    </div>
                  </div>
                </div>
                <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                  <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                    <div className="relative">
                      <img
                        src="https://cdn.basileiapalaceja.com/media/baby_shower/babyshower_16.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                    <div className="relative">
                      <img
                        src="https://cdn.basileiapalaceja.com/media/baby_shower/babyshower_17.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src="https://cdn.basileiapalaceja.com/media/baby_shower/babyshower_15.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                    <div className="relative">
                      <img
                        src="https://cdn.basileiapalaceja.com/media/baby_shower/babyshower_12.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src="https://cdn.basileiapalaceja.com/media/baby_shower/babyshower_8.jpg"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  }

  switch (props.type) {
    case "birthday":
      return <Birthday />;
    case "wedding":
      return <Wedding />;
      break;
    case "corporate":
      return <Corporate />;
      break;
    case "babyshower":
      return <Babyshower />;
      break;
  }
}
