import React, { useContext } from "react";
import _ from "lodash";
import { isBrowser, isMobile } from "react-device-detect";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import CategoryBlock from "./CategoryBlock";
import ContentArea from "../../ContentArea";
import { PriceModule, PriceModule_2 } from "../../PriceModule"

const incentives = [
  {
    name: "Podium",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "Basileia Palace offers podiums in their meeting rooms that are equipped with state-of-the-art technology and designed for the comfort of the speaker, providing a platform for effective and clear presentations during conferences and meetings.",
  },
  {
    name: "PA Systems",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "The PA system features advanced technology and equipment, including microphones, speakers, and amplifiers, that ensure clear and crisp sound quality. With the PA system, guests can enjoy seamless and effective communication during their meetings or events.",
  },
  {
    name: "WIFI Internet",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Basileia Palace provides secure and high-speed WiFi for guests in all areas of the hotel, enabling them to stay connected and productive during their stay.",
  },
  {
    name: "Projector",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "Basileia Palace offers projectors for use in their meeting rooms and outdoor celebrations venue, providing a high-quality audio-visual experience for presentations and entertainment. ",
  },
  {
    name: "Mini Bar",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "Basileia Palace offers a mini-bar for their guests, providing a convenient and refreshing selection of beverages and snacks for guests.",
  },
  {
    name: "Catering",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Guests can enjoy their meals in the dining areas or outdoor venue, which is a picturesque and tranquil oasis. The catering service is designed to provide guests with a memorable and enjoyable dining experience.",
  },
];

const tiers = [
  {
    name: "Basic",
    id: "tier-basic",
    href: "#",
    price: { monthly: "$15", annually: "$12" },
    description: "Everything necessary to get started.",
    features: [
      "5 products",
      "Up to 1,000 subscribers",
      "Basic analytics",
      "48-hour support response time",
    ],
  },
  {
    name: "Essential",
    id: "tier-essential",
    href: "#",
    price: { monthly: "$30", annually: "$24" },
    description:
      "Everything in Basic, plus essential tools for growing your business.",
    features: [
      "25 products",
      "Up to 10,000 subscribers",
      "Advanced analytics",
      "24-hour support response time",
      "Marketing automations",
    ],
  },
  {
    name: "Growth",
    id: "tier-growth",
    href: "#",
    price: { monthly: "$60", annually: "$48" },
    description:
      "Everything in Essential, plus collaboration tools and deeper insights.",
    features: [
      "Unlimited products",
      "Unlimited subscribers",
      "Advanced analytics",
      "1-hour, dedicated support response time",
      "Marketing automations",
      "Custom reporting tools",
    ],
  },
];

const Elem = () => (
  <div className="bg-white/100 backdrop-blur-sm rounded-none">
    <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-6 px-4 py-4 sm:px-6 sm:py-2 lg:px-8">
      <div>

        <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-2">
          {incentives.map((incentive) => (
            <div key={incentive.name} className="sm:flex lg:block ring-1 ring-slate-200/80  shadow-xl rounded-xl p-4 bg-gradient-to-b from-white/80 to-white/10">
              <div className="sm:flex-shrink-0">
                <img
                  className="hidden h-16 w-16"
                  src={incentive.imageSrc}
                  alt=""
                />
              </div>
              <div className="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                <h3 className="text-sm font-bold underline text-gray-800">
                  {incentive.name}
                </h3>
                <p className="mt-2 text-sm text-gray-800">
                  {incentive.description}
                </p>
              </div>
            </div>
          ))}
        </dl>
      </div>
    </div>
  </div>
);

function Hero() {
  return (
    <div className="overflow-hidden bg-white py-3">
      <div className="mx-auto max-w-7xl px-6">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-3">
          <p className="text-xl leading-8 text-gray-600">
            Celebrate your special occasion in style at Basileia Palace's breathtaking Outdoor Celebrations venue. Surrounded by lush greenery and offering a picturesque setting, our outdoor space is the perfect backdrop for weddings, birthdays, and other memorable events. With personalized service from our experienced staff, we ensure that every detail is taken care of, allowing you to relax and enjoy your celebration to the fullest.
          </p>
          <p className="text-base leading-7 text-gray-600">
            We can seat up to 150 guests, we provide chairs, tables, podiums
            and PA systems. If you want we also provide catering and
            refreshments and many more.
          </p>
          <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
              <img
                src="https://cdn.basileiapalaceja.com/media/building/building_1.jpg"
                alt=""
                className="aspect-[5/5] w-full max-w-none rounded-full bg-gray-50 object-cover object-top"
              />
            </div>
            <div className="contents lg:col-span-1">
              <div className="flex w-full flex-auto justify-end lg:w-auto lg:flex-none">
                <img
                  src="https://cdn.basileiapalaceja.com/media/building/building_2.jpg"
                  alt=""
                  className="aspect-[5/5] w-full max-w-none flex-none rounded-full bg-gray-50 object-cover object-top"
                />
              </div>
              <div className="mx-auto">
                <img
                  src="https://cdn.basileiapalaceja.com/media/building/building_6.jpg"
                  alt=""
                  className="aspect-[3/3] w-[20rem] max-w-none rounded-full bg-gray-50 object-cover  object-top"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const d = (
  <div className="mx-auto  max-w-7xl lg:px-4 py-0">
    <div
      className="w-full rounded-none h-full  overflow-auto   bg-contain bg-no-repeat bg-gradient-to-r from-violet-500 to-fuchsia-500"
      style={{
        backgroundImage: `url('https://cdn.basileiapalaceja.com/media/building/building_3.jpg')`,
      }}
    >
      <div className="mx-auto max-w-7xl pt-16  lg:max-w-none">
        <div className="grid grid-cols-1 items-center gap-x-16 gap-y-20">
          <h2 className="text-4xl font-thin tracking-tight text-gray-100 px-10">
            <p className="shadow-xl bg-gray-50/50 text-black">Outdoor Events</p>
            <p className="text-lg tracking-normal font-bold indent-3">@ Basiliea Palace</p>
          </h2>
          <div className="my-0 col-span-2 bg-white rounded-xl">
            <Hero />
          </div>
        </div>
        <Elem />
        <PriceModule category='outdoor' />
      </div>
    </div>
  </div>
);

export function OutdoorCelebrationContentBlock() {
  return <ContentArea>{d}</ContentArea>;
}
