import React, { useContext } from "react";
import _ from "lodash";
import { isBrowser, isMobile } from "react-device-detect";

import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";

const navigation = [
  { name: "What We Offer?", href: "/", current: false },
  { name: "About Basiliea Palace", href: "/about", current: false },
  { name: "Get In Touch", href: "/contact", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function Nav() {
  return (
    <Disclosure
      as="nav"
      className="grow bg-white  m-0 rounded-none"
    >
      {({ open }) => (
        <>
          <div className="mx-auto  max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex  flex-1 place-items-center justify-center sm:items-stretch sm:justify-start">
                <div className="relative flex flex-shrink-0 items-center">
                  <img
                    className="block h-8 w-auto lg:hidden"
                    src="https://cdn.basileiapalaceja.com/media/basileia_palace_logo.png"
                    alt="Basileia Palace"
                  />
                  <img
                    className="hidden lg:h-16  lg:left-4 w-auto lg:block"
                    src="https://cdn.basileiapalaceja.com/media/basileia_palace_logo.png"
                    alt="Basileia Palace"
                  />
                </div>
                <div className="flex-grow sm:ml-6 sm:block">
                  <div className="h-full grid leading-20 place-items-center block overflow-hidden pr-24">
                    <div className="h-full max-w-lg grid grid-flow-col space-x-8 grid leading-20 place-items-center">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-amber-500 hover:bg-gray-700 hover:text-white",
                            "rounded-md  p-2 text-sm font-medium "
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default function NavigationBar() {
  return (
    <>
      <header className="h-24  flex flex-col overflow-hidden h-auto">
        <section className="grow flex flex-col w-full">
          <Nav />
        </section>
        <div className="h-[2px] w-full bg-gradient-to-r from-amber-400 via-amber-100 to-green-600"></div>
      </header>
    </>
  );
}
