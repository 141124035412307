import React, { useContext } from "react";
import _ from "lodash";
import { isBrowser, isMobile } from "react-device-detect";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import { ServerIcon } from "@heroicons/react/20/solid";
import ContentArea from "../../ContentArea";
import {
  BuildingOffice2Icon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import {
  MapPinIcon,
  PhoneIcon,
} from "@heroicons/react/24/solid";

const faqs = [
  {
    question: "How do you make holy water?",
    answer:
      "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: "Can I make a reservation?",
    answer:
      "Yes! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: "Do you need reservations for only one night",
    answer:
      "Yes you do. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  // More questions...
];

export function FAQ() {
  return (
    <div className="hidden lg:grid lg:grid-cols-12 lg:gap-8">
      <div className="lg:col-span-12">
        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
          Frequently asked questions
        </h2>
      </div>
      <div className="mt-10 lg:col-span-7 lg:mt-0">
        <dl className="space-y-10">
          {faqs.map((faq) => (
            <div key={faq.question}>
              <dt className="text-base font-semibold leading-7 text-gray-900">
                {faq.question}
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">
                {faq.answer}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}

const d = (
  <div className="mx-auto max-w-7xl px-6 pb-6 lg:px-8">
    <div className="mx-auto grid gap-10 max-w-7xl grid-cols-1 lg:grid-cols-2">
      <div className="relative px-6 pb-0 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
        <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
          <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
            <svg
              className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                  width={200}
                  height={200}
                  x="100%"
                  y={-1}
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M130 200V.5M.5 .5H200" fill="none" />
                </pattern>
              </defs>
              <rect width="100%" height="100%" strokeWidth={0} fill="white" />
              <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
              </svg>
              <rect
                width="100%"
                height="100%"
                strokeWidth={0}
                fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
              />
            </svg>
          </div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900">
            Get in touch
          </h2>
          <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
            <div className="flex gap-x-4">
              <dt className="flex">
                <a href="https://goo.gl/maps/jbVZkXicX2goTGpk9">
                  <div
                    className="relative  grid justify-items-center w-24  h-full  overflow-auto  bg-cover rounded-lg bg-center bg-no-repeat p-4"
                    style={{
                      backgroundImage: `url('https://cdn.basileiapalaceja.com/media/building/building_13.jpg')`,
                    }}
                  >
                    <div className="absolute w-full h-full"/>
                    <div className="absolute z-10 w-full h-full  grid place-items-center bg-black/20">
                    <span className="text-white text-center  uppercase font-extrabold text-xs">
                      View map</span>
                      <MapPinIcon  className="text-red-600/80 h-8 animate-bounce"/>
                    </div>
                    
                  </div>

                </a>
              </dt>
              <dd>
                182 Patterson Avenue Ironshore
                <br />
                Montego Bay, Jamaica
              </dd>
            </div>
            <div className="border-t border-t-gray-500/20  mt-3 pt-3 lg:flex xs:grid gap-x-4">
              <dt className="flex">
                <span className="text-center text-lg font-bold uppercase">Contact Details</span>

              </dt>
              <dd>
                <a className="hover:text-gray-900" href="tel:+1 (876) 953-2543">
                  +1 876.953.2543
                </a>
              </dd>
              <dd>
                <a className="hover:text-gray-900" href="tel:+1 (876) 852-2152">
                  +1 876.852.2152
                </a>
              </dd>
              <dd>
                <a className="hover:text-gray-900" href="tel:+1 (876) 616-4558">
                  +1 876.616.4558
                </a>
              </dd> 
              <dd>
                <a className="hover:text-gray-900" href="tel:+1 (954) 323-9640">
                  +1 954.323.9640
                </a>
              </dd>

              <dd className="grid grid-cols-2 gap-x-4 border-t border-t-gray-500/20 mt-3 pt-3">
                <div className="w-full grid grid-cols-3 place-items-center p-1  rounded-full bg-white ">
                  <a className="hover:text-gray-900" href="tel:+1 (876) 852-2152">
                    <PhoneIcon className="rounded-full  p-0.5 ring-1 ring-blue-400 bg-gradient-to-r from-blue-400 to-sky-500 w-6 h-6 text-white" />
                  </a>
                  <a className="col-span-2 text-blue-400 uppercase text-xs" href="tel:+1 (876) 953-2543">
                    Main line
                  </a>
                </div>
                <div className="w-full grid grid-cols-3 place-items-center p-1  rounded-full bg-white ">
                  <a className="hover:text-gray-900" href="https://wa.me/18768522152">
                    <PhoneIcon className="rounded-full  p-0.5 ring-1 ring-green-400 bg-gradient-to-r from-green-400 to-green-500 w-6 h-6 text-white" />
                  </a>

                  <a className="col-span-2 text-green-400 uppercase text-xs" href="https://wa.me/18768522152">
                    WhatsApp
                  </a>
                </div>
              </dd>
            </div>
            <div className="flex gap-x-4">
              <dt className="flex-none">
                <span className="sr-only">Email</span>
                <EnvelopeIcon
                  className="h-7 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </dt>
              <dd>
                <a
                  className="hover:text-gray-900"
                  href="mailto:basileia.palace@gmail.com"
                >
                  basileia.palace@gmail.com
                </a>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="border-t border-gray-400/20" />
      <FAQ />
    </div>
    <div className="mx-auto grid max-w-7xl grid-cols-1 mb-40 lg:grid-cols-1" />
  </div>
);

export default function ContactContentBlock() {
  return <ContentArea>{d}</ContentArea>;
}
