import React, { useContext, useRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom"
import _, { uniq } from "lodash";
import { MainLayout } from "../layouts/mainLayout";
import Footer_alt from "../components/Footer/Footer_alt";
import ContentArea from "../components/ContentArea";
import Hero_1 from "../components/Hero_1";
import HomeContentBlock from "../components/ContentAreaBlocks/HomeContentBlock";
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition, Combobox } from '@headlessui/react'
import {
    Bars3Icon,
    BellIcon,
    CalendarIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon, PhotoIcon, UserCircleIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, ExclamationTriangleIcon, MagnifyingGlassIcon, XCircleIcon, CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

import { Amplify } from 'aws-amplify';

import { Divider, withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { PlusIcon } from '@heroicons/react/20/solid'
import {
    EventRequestCreateForm
} from '../ui-components';
import { Storage } from "@aws-amplify/storage"
import { DataStore } from '@aws-amplify/datastore';
import { EventRequest } from '../models';
import awsExports from '../aws-exports';
Amplify.configure(awsExports);

const products = [
    {
        name: "weddings",
        displayName: "Weddings",
        description: "Get a better understanding where your traffic is coming from",
        href: "/events?category=wedding",
    },
    {
        name: "general",
        displayName: "Corporate Events",
        description: "Get a better understanding where your traffic is coming from",
        href: "/events?category=corporate",
    },
    {
        name: "outdoor",
        displayName: "Outdoor Celebrations",
        description: "Get a better understanding where your traffic is coming from",
        href: "/events?category=OutdoorCelebration",
    },
    {
        name: "pool",
        displayName: "Pool Party",
        description: "Speak directly to your customers with our engagement tool",
        href: "/events?category=Pool",
    },
    {
        name: "repass",
        displayName: "Repass & Memorial",
        description: "Your customers’ data will be safe and secure",
        href: "/events?category=Repass",
    },
    {
        name: "babyshower",
        displayName: "Baby Shower",
        description: "Get a better understanding where your traffic is coming from",
        href: "/events?category=BabyShower",
    },
    {
        name: "birthday",
        displayName: "Birthday Party",
        description: "Speak directly to your customers with our engagement tool",
        href: "/events?category=Birthday",
    },
    {
        name: "catering",
        displayName: "Catering Services",
        description: "Your customers’ data will be safe and secure",
        href: "/events?category=Catering",
    },
    {
        name: "photoshoot",
        displayName: "Photo Shoot",
        description: "Your customers’ data will be safe and secure",
        href: "/events?category=photoshoot",
    },
];


const features = [
    { id: 1, name: 'Podium' },
    { id: 2, name: 'PA System' },
    { id: 3, name: 'Projector' },
    { id: 4, name: 'Mini Bar' },
    { id: 5, name: 'Catering' },
    { id: 6, name: 'Indoor' },
    { id: 6, name: 'Outdoor' },
]

export function EventOptionSelect(props) {
    const [query, setQuery] = useState('')

    const filteredProducts =
        query === ''
            ? products
            : products.filter((product) =>
                product.name
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    return (
        <div className="w-72">
            <Combobox value={props.selected} onChange={(e) => { props.handle(e) }}>
                <div className="relative mt-1">
                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                        <Combobox.Input
                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                            displayValue={(product) => product.displayName}
                            onChange={(event) => setQuery(event.target.value)}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </Combobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredProducts.length === 0 && query !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                </div>
                            ) : (
                                filteredProducts.map((product) => (
                                    <Combobox.Option
                                        key={product.id}
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                            }`
                                        }
                                        value={product}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                        }`}
                                                >
                                                    {product.displayName}
                                                </span>
                                                {selected ? (
                                                    <span
                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                            }`}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    )
}

function Features(props) {

    return (
        <fieldset>
            <legend className="text-base font-semibold leading-6 text-blue-400 my-4">Venue Ammenities and Options</legend>
            <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
                {features.map((feature, featureIdx) => (
                    <div key={featureIdx} className="relative flex items-start py-4">
                        <div className="min-w-0 flex-1 text-sm leading-6">
                            <label htmlFor={`product-${feature.name}`} className="select-none font-medium text-gray-900">
                                {feature.name}
                            </label>
                        </div>
                        <div className="ml-3 flex h-6 items-center">
                            <input
                                id={`product-${feature.name}`}
                                name={`product-${feature.name}`}
                                type="checkbox"
                                value={feature.name}
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                onChange={(e) => props.handle(e.target)}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </fieldset>
    )
}

export default function NewEventRequest(props) {
    const [open, setOpen] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [type, setType] = useState("")
    const cancelButtonRef = useRef(null)

    const v = new URLSearchParams(window.location.search).get("v")

    const [productInput, setProductInput] = useState(_.find(products, (p) => { return p.name == v }));
    const [dateInput, setDateInput] = useState('');
    const [partyCountInput, setPartyCountInput] = useState(0);
    const [detailInput, setDetailInput] = useState('');
    const [selectedFeatures, setSelectedFeatures] = useState([]);

    const handleProductInputChange = (value) => {
        setProductInput(value);
    };
    const handleDateInputChange = (value) => {
        setDateInput(value);
    };
    const handlePartyCountInputChange = (value) => {
        setPartyCountInput(value);
    };
    const handleDetailInputChange = (value) => {
        setDetailInput(value);
    };
    const handleFeaturesChange = (target) => {
        console.log(target.value)
        if (target.checked) {
            setSelectedFeatures(_.uniq([
                ...selectedFeatures, target.value
            ]))
        } else {
            setSelectedFeatures(selectedFeatures.filter(a => a !== target.value))
        }
        //setSelectedFeatures(value);
    };

    useEffect(() => { console.log(productInput) }, [productInput])
    useEffect(() => { console.log(dateInput) }, [dateInput])
    useEffect(() => { console.log(partyCountInput) }, [partyCountInput])
    useEffect(() => { console.log(detailInput) }, [detailInput])
    useEffect(() => { console.log(selectedFeatures) }, [selectedFeatures])

    useEffect(() => {
        const str_type = searchParams.get("v")
        console.log(str_type)
        setType(str_type)
    }, []);

    async function submitNewEvent() {

        await DataStore.save(
            new EventRequest({
                "eventType": productInput.name,
                "date": dateInput,
                "details": detailInput,
                "email": props.userAttributes.email,
                "phone": props.userAttributes.phone,
                "partySize": parseInt(partyCountInput),
                "check_pas": _.includes(selectedFeatures, 'PA System'),
                "check_catering": _.includes(selectedFeatures, 'Catering'),
                "check_indoor": _.includes(selectedFeatures, 'Indoor'),
                "check_outdoor": _.includes(selectedFeatures, 'Outdoor'),
                "check_podium": _.includes(selectedFeatures, 'Podium'),
                "check_projector": _.includes(selectedFeatures, 'Projector'),
                "check_bar": _.includes(selectedFeatures, 'Mini Bar'),
                "name": props.userAttributes.name,
                "status": "",
                "price": ""
            })
        ).then(() => {
            setOpen(false)
        });
    }

    let disabled = !props.isAdmin && props.quotes.length >= 1

    return (
        <> {props.quotes.length === 0 ?
            <div className="text-center p-6 w-[300px] rounded-xl ring-1 ring-gray-200 bg-zinc-100 shadow-lg">
                <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                >
                    <path
                        vectorEffect="non-scaling-stroke"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                    />
                </svg>
                <h3 className="mt-2 text-sm font-semibold text-gray-900">Hi, welcome! {props.userAttributes.name}</h3>
                <p className="mt-1 text-sm text-gray-500">Get started by requesting a new quote.</p>

                <div className="mt-6">
                    <button
                        type="button"
                        onClick={() => setOpen(true)}
                        className="inline-flex items-center rounded-md bg-gradient-to-br from-amber-500 to-yellow-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                        <span className="uppercase">Request a Quote</span>
                    </button>
                </div>
            </div> :
            <>
                <div className="relative w-3/4">

                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-gray-300" />
                    </div>

                    <div className="relative flex items-center justify-between">
                        <span className="pr-3 text-base font-semibold leading-6 text-gray-900 border-gray-400 my-8 bg-white">{props.isAdmin ? 'All' : 'My'} Quotes</span>
                        {!props.isAdmin &&
                            <button
                                type="button"
                                disabled={disabled}
                                onClick={() => setOpen(true)}
                                aria-disabled={disabled}
                                className="inline-flex items-center gap-x-1.5 rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                <PlusIcon className="-ml-1 -mr-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                <span className={`${disabled ? "opacity-10" : ''} `}>Request New Quote</span>
                            </button>
                        }
                    </div>
                </div>
                {disabled && <div className="rounded-md bg-yellow-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-yellow-800">Attention: request limit reached.</h3>
                            <div className="mt-2 text-sm text-yellow-700">
                                <p>
                                    You can have <strong><u>only one</u></strong> quote active, please wait on an email response from our administrator.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>}
            </>
        }
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 bg-gradient-to-br from-amber-300 to-yellow-500">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>

                                        <div className=" absolute right-0 -top-5 zIndex-10 mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense  grid  place-items-start mx-4">
                                            <button
                                                type="button"
                                                className="flex flex-row gap-4 mt-3 bg-gray-300  justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                                onClick={() => setOpen(false)}
                                                ref={cancelButtonRef}
                                            >
                                                <XMarkIcon width={24} />
                                            </button>
                                        </div>
                                        <div className="border-b border-gray-900/10 pb-12 ">

                                            <h2 className="text-base font-semibold leading-7 text-gray-900">Venue Request Form</h2>
                                            <Divider className="my-4" />
                                            <EventOptionSelect selected={productInput} handle={handleProductInputChange} />
                                            <div className="my-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                                <div className="sm:col-span-4">
                                                    <label htmlFor="date" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Date of Event
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            id="date"
                                                            name="date"
                                                            type="date"
                                                            autoComplete="email"
                                                            onChange={(e) => handleDateInputChange(e.target.value)}
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-3">
                                                    <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Number of Guests (150 limit)
                                                    </label>
                                                    <div className="mt-2">
                                                        <input id="partycount"
                                                            name="partycount"
                                                            type="number"
                                                            max={150}
                                                            className="rounded-lg border-slate-300"
                                                            onChange={(e) => handlePartyCountInputChange(e.target.value)} />
                                                    </div>
                                                </div>

                                                <div className="col-span-full">
                                                    <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Details
                                                    </label>
                                                    <div className="mt-2">
                                                        <textarea
                                                            rows={4}
                                                            name="street-address"
                                                            id="street-address"
                                                            autoComplete="street-address"
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            onChange={(e) => handleDetailInputChange(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <Features selectedItems={selectedFeatures} handle={handleFeaturesChange} />
                                        </div>
                                        <div className="grid grid-cols-2 h-18 bg-gray-50 rounded-xl p-4 m-1">
                                            <button onClick={() => setOpen(false)}
                                                ref={cancelButtonRef} className="hover:font-semibold">Cancel</button>
                                            <button className="font-semibold hover:text-sky-400" onClick={() => { submitNewEvent() }}>Submit</button>
                                        </div>

                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root></>
    )
}
