import React, { useContext } from "react";
import _ from "lodash";
import { isBrowser, isMobile } from "react-device-detect";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import CategoryBlock from "./CategoryBlock";
import ContentArea from "../ContentArea";
import { PriceModule, PriceModule_2 } from "../PriceModule"

const incentives = [
  {
    name: "Podium",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "Basileia Palace offers podiums in their meeting rooms that are equipped with state-of-the-art technology and designed for the comfort of the speaker, providing a platform for effective and clear presentations during conferences and meetings.",
  },
  {
    name: "PA Systems",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "The PA system features advanced technology and equipment, including microphones, speakers, and amplifiers, that ensure clear and crisp sound quality. With the PA system, guests can enjoy seamless and effective communication during their meetings or events.",
  },
  {
    name: "WIFI Internet",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Basileia Palace provides secure and high-speed WiFi for guests in all areas of the hotel, enabling them to stay connected and productive during their stay.",
  },
  {
    name: "Projector",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "Basileia Palace offers projectors for use in their meeting rooms and outdoor celebrations venue, providing a high-quality audio-visual experience for presentations and entertainment. ",
  },
  {
    name: "Mini Bar",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "Basileia Palace offers a mini-bar for their guests, providing a convenient and refreshing selection of beverages and snacks for guests.",
  },
  {
    name: "Catering",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Guests can enjoy their meals in the dining areas or outdoor venue, which is a picturesque and tranquil oasis. The catering service is designed to provide guests with a memorable and enjoyable dining experience.",
  },
];

const tiers = [
  {
    name: "Basic",
    id: "tier-basic",
    href: "#",
    price: { monthly: "$15", annually: "$12" },
    description: "Everything necessary to get started.",
    features: [
      "5 products",
      "Up to 1,000 subscribers",
      "Basic analytics",
      "48-hour support response time",
    ],
  },
  {
    name: "Essential",
    id: "tier-essential",
    href: "#",
    price: { monthly: "$30", annually: "$24" },
    description:
      "Everything in Basic, plus essential tools for growing your business.",
    features: [
      "25 products",
      "Up to 10,000 subscribers",
      "Advanced analytics",
      "24-hour support response time",
      "Marketing automations",
    ],
  },
  {
    name: "Growth",
    id: "tier-growth",
    href: "#",
    price: { monthly: "$60", annually: "$48" },
    description:
      "Everything in Essential, plus collaboration tools and deeper insights.",
    features: [
      "Unlimited products",
      "Unlimited subscribers",
      "Advanced analytics",
      "1-hour, dedicated support response time",
      "Marketing automations",
      "Custom reporting tools",
    ],
  },
];

const Elem = () => (
  <div className="bg-white/100 backdrop-blur-sm rounded-none">
    <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-6 px-4 py-4 sm:px-6 sm:py-2 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
      <div>

        <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
          {incentives.map((incentive) => (
            <div key={incentive.name} className="sm:flex lg:block">
              <div className="sm:flex-shrink-0">
                <img
                  className="hidden h-16 w-16"
                  src={incentive.imageSrc}
                  alt=""
                />
              </div>
              <div className="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                <h3 className="text-sm font-bold underline text-gray-800">
                  {incentive.name}
                </h3>
                <p className="mt-2 text-sm text-gray-800">
                  {incentive.description}
                </p>
              </div>
            </div>
          ))}
        </dl>
      </div>
      <div className="grid grid-cols-3 grid-rows-2 place-items-center gap-4 sm:gap-6 lg:gap-8">
        <img
          src="https://cdn.basileiapalaceja.com/media/wedding/wedding_26.jpg"
          alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
          className="rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/wedding/wedding_27.jpg"
          alt="Top down view of walnut card tray with embedded magnets and card groove."
          className="rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/wedding/wedding_20.jpg"
          alt="Top down view of walnut card tray with embedded magnets and card groove."
          className="rounded-lg bg-gray-100 shadow-md shadow-gray-500"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/wedding/wedding_5.jpg"
          alt="Side of walnut card tray with card groove and recessed card area."
          className="rounded-lg bg-gray-100 shadow-md shadow-gray-500"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/wedding/wedding_36.jpg"
          alt="Walnut card tray filled with cards and card angled in dedicated groove."
          className="rounded-lg bg-gray-100 col-span-2"
        />
      </div>
    </div>
  </div>
);

function Hero() {
  return (
    <div className="overflow-hidden bg-white py-32">
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
      </div>
      <div className="mx-auto mt-10 max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid place-items-center max-w-2xl grid-cols-2 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
              <img
                src="https://cdn.basileiapalaceja.com/media/wedding/wedding_3.jpg"
                alt=""
                className="aspect-[10/5] w-[32rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
              />
            </div>
          </div>
          <div className="lg:col-end-2 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-thin uppercase tracking-tight text-gray-900 sm:text-4xl">
              A Venue of Timeless Elegance
            </h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">
              Nestled amidst lush gardens and boasting charming architecture, Basileia Palace offers a captivating backdrop for your dream wedding.
              Our venue combines timeless elegance with modern sophistication, creating an atmosphere that exudes romance and grandeur.
              Whether you envision an intimate ceremony or a lavish celebration, our versatile spaces can be tailored to suit your vision and accommodate your guests with grace and style
            </p>
          </div>
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-thin uppercase tracking-tight text-gray-900 sm:text-4xl">Exchange Vows in Paradise</h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">
              Imagine saying "I do" amidst the beauty of our flowering gardens, where nature becomes a witness to your love story. The fragrance of blooming flowers,
              the gentle rustle of leaves, and the soft caress of a gentle breeze create an ethereal ambiance that adds a touch of magic to your special day.
              Our outdoor ceremony spaces provide the perfect setting for an unforgettable wedding ceremony, where you and your beloved can start your journey as
              a married couple surrounded by the splendor of nature.</p>
          </div>
          <div className="lg:col-end-2 lg:w-full lg:max-w-lg lg:pb-8">
            <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
              <img
                src="https://cdn.basileiapalaceja.com/media/wedding/wedding_8.jpg"
                alt=""
                className="aspect-[12/5] w-[34rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
              />
            </div>
          </div>
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
              <img
                src="https://cdn.basileiapalaceja.com/media/wedding/wedding_3.jpg"
                alt=""
                className="aspect-[5/5] w-[27rem] max-w-none flex-none rounded-full bg-gray-50 object-cover"
              />
            </div>
          </div>
          <div className="lg:col-end-2 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-thin uppercase tracking-tight text-gray-900 sm:text-4xl">Celebrate in Grandeur</h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">
              Continue your wedding festivities in our opulent reception areas, where luxury and refinement are at the forefront.
              Whether you prefer an intimate gathering or a grand celebration, our banquet halls offer elegant settings to host your reception.
              From sophisticated table settings and exquisite lighting to impeccable service and culinary delights, every detail is meticulously
              crafted to create a truly unforgettable experience for you and your guests.
            </p>

          </div>
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">

            <h2 className="text-3xl font-thin uppercase tracking-tight text-gray-900 sm:text-4xl">
              Exquisite Cuisine
            </h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">
              Indulge in a culinary journey designed to tantalize your taste buds and delight your senses.
              Our talented team of chefs is dedicated to creating a bespoke menu that reflects your personal preferences and captures the essence of your love story.
              From delectable appetizers and sumptuous main courses to mouthwatering desserts, our culinary creations will impress even the most discerning palates.
              Whether you desire a sit-down dinner, a buffet-style feast, or a combination of both, we will curate a dining experience that surpasses your expectations.
            </p>

          </div>
          <div className="lg:col-end-2 lg:w-full lg:max-w-lg lg:pb-8">
            <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
              <img
                src="https://cdn.basileiapalaceja.com/media/wedding/wedding_3.jpg"
                alt=""
                className="aspect-[5/5] w-[27rem] max-w-none flex-none rounded-full bg-gray-50 object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const d = (
  <div className="mx-auto  max-w-7xl lg:px-4 py-0">
    <div
      className="w-full rounded-none h-full  overflow-auto  bg-fixed bg-contain bg-no-repeat bg-gradient-to-r from-violet-500 to-fuchsia-500"
      style={{
        backgroundImage: `url('https://cdn.basileiapalaceja.com/media/wedding/wedding_56.jpg')`,
      }}
    >
      <div className="mx-auto max-w-7xl py-8  lg:max-w-none">
        <div className=" grid grid-cols-1 items-center gap-x-16 gap-y-0 lg:grid-cols-2 py-0">
          <h2 className="text-8xl font-thin tracking-tight text-gray-100 px-10">
            <p>Weddings</p>
            <p className="text-lg tracking-normal font-bold indent-3">@ Basiliea Palace</p>
          </h2>
          <p className="m-4 bg-black/0 text-2xl text-gray-100 p-8  rounded-lg antialiased hover:subpixel-antialiased bg-black/50">Step into a world of enchantment and say "I do" in the most captivating setting. Our breathtaking venue sets the stage for your dream wedding, whether it's an intimate ceremony or a lavish celebration. With elegant decor, impeccable service, and personalized attention to every detail, we ensure your special day is filled with cherished moments and unforgettable memories.</p>

          <div className="my-0 col-span-2 bg-white rounded-xl">
            <Hero />
          </div>
          <div className="grid px-3 my-0  col-span-2 gap-3 bg-black/40 backdrop-blur min-h-[15rem]">

            <p class="text-white f  bnont-thin text-6xl">Make Your Dream Wedding a Reality</p>
            <p class="text-white text-xl">Your dream wedding begins at Basileia Palace.
              Let our dedicated team of professionals guide you through every step of the planning process,
              from selecting the perfect venue spaces to coordinating with our trusted vendors.
              We believe that your wedding day should be a reflection of your unique love story,
              and we are here to bring your vision to life. Contact us today to embark on an unforgettable journey towards your happily ever after</p>
          </div>
        </div>
        <Elem />
        <PriceModule category='wedding' />
      </div>
    </div>
  </div>
);

export default function BirthdayContentBlock() {
  return <ContentArea>{d}</ContentArea>;
}
