import React, { useContext } from "react";
import _ from "lodash";
import { MainLayout } from "../layouts/mainLayout";
import { MobileLayout } from "../layouts/mobileLayout";
import Footer_alt from "../components/Footer/Footer_alt";
import ContentArea from "../components/ContentArea";
import Hero_1 from "../components/Hero_1";
import RoomContentBlock from "../components/ContentAreaBlocks/RoomContentBlock";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import MobileRoomContentBlock from "../components/Mobile/MobileContentAreaBlocks/MobileRoomContentBlock";

export default function RoomPage() {
  return (
    <>
      <BrowserView>
        <MainLayout>
          <RoomContentBlock />
          <Footer_alt />
        </MainLayout>
      </BrowserView>
      <MobileView>
        <MobileLayout>
          <MobileRoomContentBlock />
        </MobileLayout>
      </MobileView>
    </>
  );
}
