import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { MainLayout } from "../layouts/mainLayout";
import Footer_alt from "../components/Footer/Footer_alt";
import ContentArea from "../components/ContentArea";
import Hero_1 from "../components/Hero_1";
import HomeContentBlock from "../components/ContentAreaBlocks/HomeContentBlock";

import { Amplify, Auth } from 'aws-amplify';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from '../aws-exports';
import {
    LockClosedIcon,
    MagnifyingGlassIcon,
    ShoppingBagIcon,
} from "@heroicons/react/24/outline";
import { ShieldCheckIcon } from "@heroicons/react/20/solid";
Amplify.configure(awsExports);

async function signOut() {
    try {
        await Auth.signOut();
    } catch (error) {
        console.log('error signing out: ', error);
    }
}

function Elem() {

    const [isAdmin, setIsAdmin] = new useState(false)
    const navigate = useNavigate();

    const fx = async () => {
        const user = await Auth.currentAuthenticatedUser();
        // Returns an array of groups
        const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
        const result = _.includes(groups, 'web-admin')
        console.log(result)
        setIsAdmin(result)
    }

    fx()
    return (<>
        <section className="">
            <div>

                <div className="flex items-center">
                    <div>
                        <img
                            className="inline-block h-9 w-9 rounded-full"
                            src="https://cdn.basileiapalaceja.com/media/basileia_palace_logo.png"
                            alt=""
                        />
                    </div>
                    <div className="ml-3">

                        <div className="flex flex-row gap-4 place-items-center px-0 my-2 overflow-hidden">
                            {Auth.user ?
                                <div className={`${!Auth.user && 'hidden'} flex  gap-4 grow  justify-center uppercase text-[8pt]`} >
                                    <a className="" href="/auth">
                                        <p className="flex content-center place-items-center align-center space-x-2 text-sm font-medium text-gray-700 group-hover:text-gray-900">
                                            <span className="uppercase text-[7pt]"> {Auth.user?.attributes.name}</span>
                                            {isAdmin ? <span >
                                                <ShieldCheckIcon className="text-green-500 h-[12pt]" />
                                            </span> : ''}</p>
                                    </a>
                                    <button onClick={() => signOut().then(() => {
                                        navigate("/");
                                    })}>SIGN OUT</button></div> :
                                <div className="flex grow  justify-center"><a href="/auth" className="group block flex-shrink-0 uppercase text-[8pt] px-2">My Account</a></div>
                            }
                        </div>

                    </div>
                </div>
            </div>

        </section>
    </>);
}


export default function AuthWidget(props) {
    return (<>
        <section className={`${props.footer ? '' : ''} h-[50px]  pa-0`}>
            <Elem />
        </section>
    </>);
};

