import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ArrowUturnLeftIcon,
  Bars3Icon,
  ChatBubbleBottomCenterTextIcon,
  ChatBubbleLeftRightIcon,
  DocumentChartBarIcon,
  InboxIcon,
  PencilSquareIcon,
  QuestionMarkCircleIcon,
  SquaresPlusIcon,
  TrashIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ContextServiceApi } from "./ContextServiceApi";

export default class ProviderServiceApi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [
        
      ],
      products: [
        {
          name: "Weddings",
          description: "Get a better understanding where your traffic is coming from",
          href: "/events?category=wedding",
          icon: SquaresPlusIcon,
        },
        {
          name: "Corporate Events",
          description: "Get a better understanding where your traffic is coming from",
          href: "/events?category=corporate",
          icon: SquaresPlusIcon,
        },
        {
          name: "Outdoor Celebrations",
          description: "Get a better understanding where your traffic is coming from",
          href: "/events?category=OutdoorCelebration",
          icon: SquaresPlusIcon,
        },
        {
          name: "Pool Party",
          description: "Speak directly to your customers with our engagement tool",
          href: "/events?category=Pool",
          icon: SquaresPlusIcon,
        },
        /* {
          name: "Graduation",
          description: "Your customers’ data will be safe and secure",
          href: "/events?category=Graduation",
          icon: SquaresPlusIcon,
        }, */
        {
          name: "Repass & Memorial",
          description: "Your customers’ data will be safe and secure",
          href: "/events?category=Repass",
          icon: SquaresPlusIcon,
        },
        {
          name: "Baby Shower",
          description: "Get a better understanding where your traffic is coming from",
          href: "/events?category=BabyShower",
          icon: SquaresPlusIcon,
        },
        {
          name: "Birthday Party",
          description: "Speak directly to your customers with our engagement tool",
          href: "/events?category=Birthday",
          icon: SquaresPlusIcon,
        },
        {
          name: "Catering Services",
          description: "Your customers’ data will be safe and secure",
          href: "/events?category=Catering",
          icon: SquaresPlusIcon,
        },
        /* {
          name: "Conferences",
          description: "Your customers’ data will be safe and secure",
          href: "/events?category=Conference",
          icon: SquaresPlusIcon,
        }, */
        {
          name: "Photo Shoot",
          description: "Your customers’ data will be safe and secure",
          href: "/events?category=photoshoot",
          icon: SquaresPlusIcon,
        },
      ]
    };
  }

  componentDidMount() { }

  render() {
    return (
      <>
        <ContextServiceApi.Provider value={this.state}>
          {this.props.children}
        </ContextServiceApi.Provider>
      </>
    );
  }
}
