import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const roomCategory = {
  name: "Rooms",
  clothing: [
    [
      { name: "Dress Shirts", href: "#" },
      { name: "Pants", href: "#" },
      { name: "Jackets", href: "#" },
      { name: "T-Shirts", href: "#" },
      { name: "Jeans", href: "#" },
      { name: "Hoodies", href: "#" },
    ],
    [
      { name: "Vests", href: "#" },
      { name: "Kilts", href: "#" },
      { name: "Outdoors", href: "#" },
      { name: "Capes", href: "#" },
      { name: "Browse All", href: "#" },
    ],
  ],
  accessories: [
    { name: "Watches", href: "#" },
    { name: "Boots", href: "#" },
    { name: "Fanny Packs", href: "#" },
    { name: "Sunglasses", href: "#" },
    { name: "Browse All", href: "#" },
  ],
  categories: [
    { name: "Just Added", href: "#" },
    { name: "Clearance", href: "#" },
    { name: "Graphic Tees", href: "#" },
  ],
};
const rooms = [
  {
    id: 1,
    name: "Standard Rooms",
    href: "/room?category=standard",
    imageSrc: "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_1.jpg",
    imageAlt: "Standard Rooms",
    price: "$80.00 USD",
    color: "Black",
  },
  {
    id: 2,
    name: "Deluxe Double Room",
    href: "/room?category=deluxe",
    imageSrc: "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_14.jpg",
    imageAlt: "Deluxe Double Room",
    price: "$100.00 USD",
    color: "Black",
  },
  {
    id: 3,
    name: "Luxury Studio Suite",
    href: "/room?category=luxury",
    imageSrc: "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_15.jpg",
    imageAlt: "Luxury Studio Suite",
    price: "$135.00 USD",
    color: "Black",
  },
  {
    id: 4,
    name: "Full House",
    href: "/room?category=fullhouse",
    imageSrc: "https://cdn.basileiapalaceja.com/media/building/building_18.jpg",
    imageAlt: "Full House",
    price: "$80.00 USD",
    color: "Black",
  }
];
export function RoomImageRate() {
  return (
    <div className="relative ">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div
          className="w-full overflow-x-auto grid grid-flow-col gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8
                      snap-x snap-mandatory"
        >
          {rooms.map((room) => (
            <a href={room.href}>
              <div
                key={room.id}
                className="snap-normal snap-center group w-[200px]  relative  overflow-hidden"
              >
                <div className="grid place-items-end min-h-[200px] bg-cover rounded-lg ring-1 overflow-hidden aspect-h-1 aspect-w-1  w-full   lg:aspect-none group-hover:opacity-75 lg:h-fit "
                  style={{
                    backgroundImage: `url(${room.imageSrc})`,
                  }}>

                  <div className="h-[50px] flex flex-cols bottom-0 w-full backdrop-blur-none bg-black/60">

                    <div className="grow grid place-items-center">
                      <span className="indent-8 text-[8pt] uppercase text-gray-100 font-bold">
                        <a href={room.href}>
                          <span aria-hidden="true" className="absolute inset-0 " />
                          {room.name}
                        </a>
                      </span>
                    </div>

                    <div className="shrink px-2 grid place-items-center">
                      <ArrowSmallRightIcon className="h-8 text-white" />
                    </div>

                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default function RoomPopOver(props) {
  return (
    <>
      <Popover className="flex">
        {({ open }) => (
          <>
            <div className="relative flex">
              <Popover.Button
                className={classNames(
                  open
                    ? "border-amber-600 text-amber-600"
                    : "border-transparent text-gray-700 hover:text-gray-800",
                  "relative z-10 -mb-px flex items-center border-b-4 pt-px text-sm font-medium transition-colors duration-200 ease-out"
                )}
              >
                {roomCategory.name}
              </Popover.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Popover.Panel className="absolute inset-x-0 bottom-full text-gray-500 sm:text-sm">
                {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                <div
                  className="absolute inset-0 bottom-1/2 bg-white shadow"
                  aria-hidden="true"
                />

                <div className="relative bg-gradient-to-t from-white to-amber-400 ">
                  <div className="place-items-center mx-auto max-w-7xl px-4 ">
                    <RoomImageRate />
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
}
