import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  LockClosedIcon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
} from "@heroicons/react/24/outline";
import EventPopOver from "./EventPopOver";
import RoomPopOver from "./RoomPopOver";
import  AuthWidget  from "../AuthWidget";

const navigation = {
  other: [
    { name: "Company", href: "/about" },
  ],
};


export default function Footer_alt(props) {
  return (
    <div className="bg-white/100 backdrop-blur-sm fixed z-10 bottom-0 w-full border-t-4 border-amber-600">
      <header className="relative">
        <nav aria-label="Bottom" className="mx-auto  max-w-7xl sm:px-6 lg:px-8">
          <div className="border-b border-amber-200 px-4 pb-14 sm:px-0 sm:pb-0">
            <div className="flex h-16 items-center justify-between">
              {/* Flyout menus */}
              <Popover.Group className="absolute inset-x-0 bottom-0 sm:static sm:flex-1 sm:self-stretch">
                <div className="flex h-14 space-x-8 overflow-x-auto border-t px-4 pb-px sm:h-full sm:justify-center sm:overflow-visible sm:border-t-0 sm:pb-0">
                  <EventPopOver />
                  <RoomPopOver />
                  {navigation.other.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </Popover.Group>

              <div className="flex flex-1 items-center justify-end">

                {/* Cart */}
                <div className="ml-4 flow-root lg:ml-8">
                    <AuthWidget footer={true} Auth={props.Auth}/>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}
