import React, { useContext } from "react";
import _ from "lodash";
import { isBrowser, isMobile } from "react-device-detect";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import CategoryBlock from "./CategoryBlock";
import ContentArea from "../ContentArea";
import { PriceModule, PriceModule_2 } from "../../components/PriceModule"

const incentives = [
  {
    name: "Unparalleled Culinary Delights:",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "At Basileia Palace, we believe that every dish should be a masterpiece. Our skilled chefs combine their expertise with the finest ingredients to craft exquisite menus that cater to diverse palates and dietary preferences. From delectable appetizers and mouthwatering main courses to decadent desserts, each dish is prepared with meticulous attention to detail, ensuring an explosion of flavors that will leave a lasting impression.",
  },
  {
    name: "Customized Menus",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "We understand that every event is unique, and our catering services are tailored to reflect your vision and requirements. Our experienced event planners will work closely with you to curate a personalized menu that aligns with your theme, preferences, and budget. Whether you prefer a formal plated dinner, an elegant buffet, or interactive food stations, we will bring your culinary dreams to life.",
  },
  {
    name: "Exquisite Presentations",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "We believe that presentation is just as important as taste. Our culinary team takes great pride in the artistry of food presentation, ensuring that each dish is visually appealing and showcases the culinary expertise behind it. From beautifully garnished plates to creative displays and elegant table settings, our attention to detail enhances the overall dining experience and adds a touch of sophistication to your event.",
  },
  {
    name: "Seamless Service",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "At Basileia Palace, we go above and beyond to provide impeccable service that exceeds your expectations. Our highly trained waitstaff is committed to delivering a seamless and professional dining experience. From the moment your guests arrive until the last bite is savored, our attentive team will ensure that every aspect of the catering service runs smoothly, allowing you to relax and enjoy the event.",
  },
  {
    name: "Uncompromising Quality",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "We are committed to using only the freshest, highest quality ingredients in our culinary creations. From locally sourced produce to premium cuts of meat and sustainable seafood, we prioritize the integrity and freshness of our ingredients. This commitment to quality is evident in the exceptional flavors and exquisite taste that our dishes offer.",
  },
  {
    name: "Flexible Catering Options",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Whether you're hosting an intimate gathering or a grand celebration, Basileia Palace can accommodate events of various sizes. Our flexible catering options can be customized to suit your needs, whether you require catering for a small business meeting or a lavish wedding reception. We are equipped to handle events both on-site at our luxurious venue or at a location of your choice.",
  },
];

const tiers = [
  {
    name: "Basic",
    id: "tier-basic",
    href: "#",
    price: { monthly: "$15", annually: "$12" },
    description: "Everything necessary to get started.",
    features: [
      "5 products",
      "Up to 1,000 subscribers",
      "Basic analytics",
      "48-hour support response time",
    ],
  },
  {
    name: "Essential",
    id: "tier-essential",
    href: "#",
    price: { monthly: "$30", annually: "$24" },
    description:
      "Everything in Basic, plus essential tools for growing your business.",
    features: [
      "25 products",
      "Up to 10,000 subscribers",
      "Advanced analytics",
      "24-hour support response time",
      "Marketing automations",
    ],
  },
  {
    name: "Growth",
    id: "tier-growth",
    href: "#",
    price: { monthly: "$60", annually: "$48" },
    description:
      "Everything in Essential, plus collaboration tools and deeper insights.",
    features: [
      "Unlimited products",
      "Unlimited subscribers",
      "Advanced analytics",
      "1-hour, dedicated support response time",
      "Marketing automations",
      "Custom reporting tools",
    ],
  },
];

const Elem = () => (
  <div className="bg-white rounded-none
  ">
    <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-0 sm:px-6 sm:py-0 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
      <div>

        <dl className="mt-0 py-15 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
          {incentives.map((incentive) => (
            <div key={incentive.name} className="sm:flex lg:block">
              <div className="sm:flex-shrink-0">
                <img
                  className="hidden h-16 w-16"
                  src={incentive.imageSrc}
                  alt=""
                />
              </div>
              <div className="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                <h3 className="text-sm font-bold underline text-gray-800">
                  {incentive.name}
                </h3>
                <p className="mt-2 text-sm text-gray-800">
                  {incentive.description}
                </p>
              </div>
            </div>
          ))}
        </dl>
      </div>
      <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
        <img
          src="https://cdn.basileiapalaceja.com/media/Corporate/corporate_4.jpg"
          alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
          className="aspect-[4/3] w-[24rem] rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/Corporate/corporate_6.jpg"
          alt="Top down view of walnut card tray with embedded magnets and card groove."
          className="rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/Corporate/corporate_8.jpg"
          alt="Side of walnut card tray with card groove and recessed card area."
          className="aspect-[4/3] w-[24rem] rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/Corporate/corporate_10.jpg"
          alt="Walnut card tray filled with cards and card angled in dedicated groove."
          className="aspect-[4/3] w-[24rem] rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/Corporate/corporate_11.jpg"
          alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
          className="aspect-[4/3] w-[24rem] rounded-lg bg-gray-100"
        />
        <img
          src="https://cdn.basileiapalaceja.com/media/Corporate/corporate_1.jpg"
          alt="Top down view of walnut card tray with embedded magnets and card groove."
          className="aspect-[4/3] w-[24rem] rounded-lg bg-gray-100"
        />
      </div>
    </div>
  </div>
);

function Hero() {
  return (
    <div className="overflow-hidden bg-white py-32">
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-thin uppercase tracking-tight text-gray-900 sm:text-4xl">
              Exquisite Catering Services at Basileia Palace,
            </h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">
                Welcome to Basileia Palace, where we pride ourselves on delivering exceptional catering services for a wide range of events and special occasions. Whether you're planning a corporate luncheon, a wedding reception, or a private celebration, our culinary team is dedicated to creating an unforgettable dining experience that will delight your guests.
            </p>
          </div>
          <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
              <img
                src="https://cdn.basileiapalaceja.com/media/Corporate/corporate_5.jpg"
                alt=""
                className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
              />
            </div>
            <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
              <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                <img
                  src="https://cdn.basileiapalaceja.com/media/Corporate/corporate_8.jpg"
                  alt=""
                  className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover shadow-xl"
                />
              </div>
              <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                <img
                  src="https://cdn.basileiapalaceja.com/media/Corporate/corporate_9.jpg"
                  alt=""
                  className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover shadow-lg"
                />
              </div>
              <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                <img
                  src="https://cdn.basileiapalaceja.com/media/Corporate/corporate_10.jpg"
                  alt=""
                  className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover shadow-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const d = (
  <div className="mx-auto n max-w-7xl lg:px-4 py-0">
  <div
    className="w-full rounded-none h-full  overflow-auto  bg-cover bg-fixed bg-center bg-no-repeat"
    style={{
      backgroundImage: `url('https://cdn.basileiapalaceja.com/media/Corporate/corporate_5.jpg')`,
    }}
  >
  <div className="mx-auto max-w-7xl py-8  lg:max-w-none">
    <div className="grid grid-cols-1 items-center gap-x-16 gap-y-10 lg:grid-cols-2 py-0">
      <h2 className="text-8xl font-thin tracking-tight text-gray-100 px-10">
        <p className="shadow-lg  bg-gradient-to-r from-sky-400 to-indigo-500/40">Catering</p>
        <p className="text-lg tracking-normal font-bold indent-3">@ Basiliea Palace</p>
      </h2>
      <p className="m-4 bg-gradient-to-l from-sky-800/40 to-indigo-800/40 text-2xl text-gray-100 p-8  rounded-lg antialiased hover:subpixel-antialiased">
      Ignite the spirit of celebration with our exciting and vibrant birthday party venues. Whether it's a milestone event or a themed extravaganza, our venues offer a lively and engaging ambiance for unforgettable birthday festivities. Dance, laugh, and make memories that will be treasured for years to come.
      </p>
      <div className="my-0 col-span-2 bg-white rounded-xl">
        <Hero />
      </div>
    </div>
    <Elem />
    <PriceModule category='catering' />
  </div>
</div>
</div>
);

export default function CateringContentBlock() {
  return <ContentArea>{d}</ContentArea>;
}
