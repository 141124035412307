import React, { useContext } from "react";
import _ from "lodash";
import { MainLayout } from "../../layouts/mainLayout";
import Footer_alt from "../../components/Footer/Footer_alt";
import ContentArea from "../../components/ContentArea";
import BabyShowerContentBlock from "../../components/ContentAreaBlocks/BabyShowerContentBlock"

export default function BabyShowerPage() {
  return (
    <>
      <MainLayout>
        <BabyShowerContentBlock />
        <Footer_alt />
      </MainLayout>
    </>
  );
}
