import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useLocation } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react'
import _ from "lodash";
import { MainLayout } from "../../layouts/mainLayout";
import Footer_alt from "../../components/Footer/Footer_alt";
import ContentArea from "../../components/ContentArea";
import Hero_1 from "../../components/Hero_1";
import HomeContentBlock from "../../components/ContentAreaBlocks/HomeContentBlock";

import { ChevronRightIcon, PhoneIcon } from "@heroicons/react/20/solid";
import {
  AcademicCapIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AuthPage() {
  return (
    <>
      <MainLayout>
        <Panel>
          <Hero />
          <section className='grid justify-center'>
            <div className='max-w-7xl grid grid-cols-2 gap-4'>
              <div className=''>
                <p className="mt-6 text-lg leading-8 text-gray-600 ">
                  <b>O</b>ur <b>S</b>tandard <b>R</b>ooms at Basileia Palace offer a seamless blend of comfort and elegance, providing you with a tranquil retreat during your stay.
                  Designed with meticulous attention to detail, these rooms are thoughtfully crafted to meet the needs of both business and leisure travelers.
                  Step into a world of refined simplicity, where every element has been carefully chosen to ensure a delightful and memorable experience.<br />
                  It’s the perfect choice for <b>budget-minded travelers</b> who want a comfortable and convenient place to stay. Our rooms are spacious and well-appointed,
                  and they come with all the amenities you need for a relaxing stay.
                </p>
              </div>
              <div className=''>
                <Prop_1 />
              </div>
            </div>
          </section>

          <Features />
        </Panel>
        <Footer_alt />
      </MainLayout>
    </>
  );
}


function ImageModal(props) {
  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <button
        type="button"
        onClick={openModal}
      >
        {props.children}
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">

                  <div className="mt-2">
                    <img
                      src={props.file.source}
                      alt=""
                      width="500px"
                      className="pointer-events-none object-cover"
                    />
                  </div>

                  <div className="mt-4 grid place-items-center">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-amber-100 px-4 py-2 text-sm font-medium text-amber-900 hover:bg-amber-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-amber-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Close Window
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}


export function ImageGallery() {
  const files = [
    {
      category: 1,
      source:
        "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_1.jpg",
    },
    {
      category: 1,
      source:
        "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_2.jpg",
    },
    {
      category: 1,
      source:
        "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_3.jpg",
    },
    {
      category: 1,
      source:
        "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_4.jpg",
    },
    {
      category: 1,
      source:
        "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_5.jpg",
    },
    {
      category: 1,
      source:
        "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_6.jpg",
    },
    {
      category: 1,
      source:
        "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_7.jpg",
    },
    {
      category: 1,
      source:
        "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_8.jpg",
    },
    // More files...
  ];
  return (
    <ul
      role="list"
      className="mx-auto max-w-7xl my-2 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
    >
      {files.map((file) => (
        <li key={file.source} className="relative">
          <ImageModal file={file} ><div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
            <Transition
              as={Fragment}
              show={true}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >

              <img
                src={file.source}
                alt=""
                className="pointer-events-none object-cover aspect-square group-hover:opacity-75"
              />
            </Transition>
          </div>
          </ImageModal >
        </li>
      ))}
    </ul>
  );
}

export function Panel(props) {
  return (
    <div className=" rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:p-6">{props.children}</div>
    </div>
  );
}


export function Hero() {
  return (
    <div className="bg-amber-600">
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20">
        <div className="mx-auto max-w-7xl pb-24 pt-10 sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-40">
          <div className="px-6 lg:px-0 lg:pt-4">
            <div className="mx-auto max-w-2xl">
              <div className="max-w-lg">
                <img
                  className="block h-[300px] w-auto"
                  src="https://cdn.basileiapalaceja.com/media/basileia_palace_logo.png"
                  alt="Basileia Palace"
                />
                <h1 className="mt-10 text-4xl font-thin tracking-tight text-gray-900 sm:text-6xl">
                  Welcome to our Standard Rooms
                  <p className='text-[12pt] mt-4 font-extrabold'>A Haven of Comfort and Elegance</p>
                </h1>
              </div>
            </div>
          </div>
          <div className="mt-20 sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen">
            <div
              className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-2xl shadow-amber-600/90 ring-1 ring-indigo-50 md:-mr-20 lg:-mr-36"
              aria-hidden="true"
            />
            <div className="shadow-lg md:rounded-3xl">
              <div className="bg-amber-400  [clip-path:inset(5)] md:[clip-path:inset(0_round_theme(borderRadius.3xl))]">
                <div
                  className="absolute -inset-y-px left-1/2 -z-10 ml-10 w-[200%] skew-x-[-30deg] bg-sky-100 opacity-20 ring-1 ring-inset ring-white md:ml-20 lg:ml-36"
                  aria-hidden="true"
                />
                <div className="relative px-6 pt-8 sm:pt-16 md:pl-16 md:pr-0">
                  <div className="mx-auto max-w-2xl md:mx-0 md:max-w-none p-8">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                      5-STAR ACCOMMODATIONS
                    </h2>
                    <p className="mt-6 text-lg leading-8 text-gray-800">
                      All of our bedrooms are characterized by antique wooden furniture
                      with warm lighting and colors to create the perfect setting at
                      night, rich plush textures and a peaceful ambiance throughout the
                      hotel ensures optimum comfort for every guest.
                    </p>
                    <ImageGallery />
                  </div>
                  <div
                    className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 md:rounded-3xl"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </div>
  );
}
function Prop_1() {

  const location = useLocation();

  const [currentloc, setCurrentLoc] = useState(location.pathname)

  let roomLinks = [
    {
      name: 'Standard Room',
      initial: "ST",
      nameStyle: 'bg-green-500 group hover:bg-green-400',
      initialStyle: 'bg-green-400  group-hover:bg-green-600',
      url: '/room/standard',
      current: false
    },
    {
      name: 'Deluxe Room',
      initial: "DX",
      nameStyle: 'bg-green-500 group hover:bg-green-400',
      initialStyle: 'bg-green-400 group-hover:bg-green-600',
      url: '/room/deluxe',
      current: false
    },
    {
      name: 'Luxury Room',
      initial: "LX",
      nameStyle: 'bg-green-500 group hover:bg-green-400',
      initialStyle: 'bg-green-400 group-hover:bg-green-600',
      url: '/room/luxury',
      current: false
    },
    {
      name: 'Full House',
      initial: "FH",
      nameStyle: 'bg-green-500 group hover:bg-green-400',
      initialStyle: 'bg-green-400 group-hover:bg-green-600',
      url: '/room/fullhouse',
      current: false
    }
  ]

  const [roomLinkMenu, setRoomLinkMenu] = useState(roomLinks)


  useEffect(() => {
    console.log(currentloc)

    const newList = roomLinkMenu.map((item) => {
      if (item.url === currentloc) {
        const updatedItem = {
          ...item,
          nameStyle: "bg-amber-500 group hover:bg-amber-400",
          initialStyle: "bg-amber-400 group-hover:bg-amber-600"
        };

        return updatedItem;
      }

      return item;
    });

    setRoomLinkMenu(newList);
    console.log(roomLinkMenu)

  }, [currentloc])


  return (
    <section className="isolate overflow-hidden bg-white px-0 lg:px-1">
      <div className="relative mx-auto  py-4 sm:py-4 lg:max-w-4xl">
        <figure className="grid grid-cols-1 items-start gap-x-6 gap-y-8 lg:gap-x-10">
          <div className="grid relative col-span-1  lg:col-start-1 lg:row-start-2">
            <svg
              viewBox="0 0 162 128"
              fill="none"
              aria-hidden="true"
              className="absolute -top-12 left-0 -z-10 h-32 stroke-gray-900/10"
            >
              <path
                id="b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb"
                d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z"
              />
              <use href="#b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" x={86} />
            </svg>
            <ul role="list" className="mt-3 grid grid-cols-1 gap-5 ">
              {
                roomLinkMenu.map((room) => (
                  <li key={room.initial} className={classNames(
                    room.nameStyle,
                    'col-span-4 flex rounded-md shadow-sm'
                  )}>
                    <a href={room.url} className="col-span-4 flex font-medium text-gray-900 hover:text-amber-600">

                      <div
                        className={classNames(
                          room.initialStyle,
                          'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
                        )}
                      >
                        {room.initial}
                      </div>
                      <div className="flex flex-1  items-center justify-between truncate ">
                        <div className="flex-1 truncate px-4 py-2 text-sm text-white font-semibold">
                          {room.name}
                        </div>
                      </div>
                    </a>
                  </li>
                ))
              }
            </ul>
          </div>
          <div className="grid col-end-1 w-16 lg:row-span-4 lg:w-72 gap-4 ">
            <img
              className="rounded-xl aspect-square bg-indigo-50 lg:rounded-full"
              src="https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_4.jpg"
              alt=""
            />
          </div>
          <Example />
        </figure>
      </div>
    </section>
  )
}

function Features() {
  const features = [
    {
      room_category: 'ST',
      name: '',
      description:
        'Comfortable queen-size or twin beds for a restful sleep',
    },
    {
      room_category: 'ST',
      name: '',
      description:
        'Private bathroom with modern fixtures for your convenience',
    },
    {
      room_category: 'ST',
      name: '',
      description:
        'In-room safe provided for securing your valuable belongings',
    },
    {
      room_category: 'ST',
      name: '',
      description:
        'Individually controlled air conditioning, allowing you to set your desired temperature',
    },
    {
      room_category: 'ST',
      name: '',
      description:
        'Daily housekeeping service to keep your room tidy and fresh',
    },
    {
      room_category: 'ST',
      name: '',
      description:
        ' Enjoy the convenience of 24-hour room service for any additional needs or requests'
    },
    {
      room_category: 'ST',
      name: '',
      description:
        ' Mini refrigerator or minibar for added convenience, providing storage for your snacks or beverages'
    },
    {
      room_category: 'ST',
      name: '',
      description:
        'Individually controlled air conditioning, allowing you to personalize the room temperature to your preference'
    },
    {
      room_category: 'ST',
      name: '',
      description:
        'Option for connecting rooms, ideal for families or larger groups seeking to stay together'
    },
    {
      room_category: 'DX',
      name: 'Beds X2',
      description:
        'Two comfortable double beds or queen-size beds, providing ample space for a restful sleep',
    },
    {
      room_category: 'DX',
      name: 'Comfortable Atmosphere',
      description:
        'Stylish and modern décor with meticulous attention to detail, creating a contemporary ambiance',
    },
    {
      room_category: 'DX',
      name: 'Business Savvy',
      description:
        'Dedicated workspace with a desk and ergonomic chair, perfect for productivity or planning your adventures',
    },
    {
      room_category: 'DX',
      name: 'High Speed WiFi',
      description:
        'High-speed Wi-Fi connectivity for multiple devices, keeping you connected throughout your stay',
    },
    {
      room_category: 'DX',
      name: 'Room Ammenities',
      description:
        'In-room coffee and tea making facilities, allowing you to enjoy a refreshing beverage at any time',
    },
    {
      room_category: 'DX',
      name: 'Mini Bar',
      description:
        ' Mini refrigerator or minibar for added convenience, providing storage for your snacks or beverages'
    },
    {
      room_category: 'DX',
      name: 'Smart TV',
      description:
        ' Mini refrigerator or minibar for added convenience, providing storage for your snacks or beverages'
    },
    {
      room_category: 'DX',
      name: 'Air Conditioner',
      description:
        'Individually controlled air conditioning, allowing you to personalize the room temperature to your preference'
    },
    {
      room_category: 'DX',
      name: 'Family Options',
      description:
        'Option for connecting rooms, ideal for families or larger groups seeking to stay together'
    },
  ]

  const [roomFeatures, setRoomFeatures] = useState([])

  useEffect(() => {
    setRoomFeatures(_.filter(features, (f) => { return f.room_category == 'ST' }))
  }, [])

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Key Features</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            At Basileia Palace, we strive to provide exceptional service and ensure that your stay in our Standard Rooms is comfortable and hassle-free.
          </p>
        </div>
        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {roomFeatures.map((feature, $index) => (
            <div key={$index}>
              <dt className="font-semibold text-gray-900">{feature.name}</dt>
              <dd className="mt-1 text-gray-600">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}




export function Example() {
  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg  shadow flex sm:gap-px sm:divide-y-0">
      <div className="sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>Book your stay in our Standard Rooms and immerse yourself in a world of comfort, style, and relaxation</p>
          </div>
          <div className="mt-5">
            <a className="hover:text-gray-900" href="https://wa.me/18768522152">
              <PhoneIcon className="rounded-full  p-0.5 ring-1 ring-green-400 bg-gradient-to-r from-green-400 to-green-500 w-6 h-6 text-white" />
            </a>

            <a className="col-span-2 text-green-400 uppercase text-xs" href="https://wa.me/18768522152">
              WhatsApp
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

