/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import _ from 'lodash'
import {
  HandThumbUpIcon, UserIcon,
  PaperClipIcon,
  XMarkIcon
} from '@heroicons/react/20/solid'
import { Fragment, useEffect, useState, useRef } from 'react'
import { Dialog, Transition, Listbox } from '@headlessui/react'
import { Amplify, DataStore, Auth, Hub, syncExpression, Predicates } from 'aws-amplify';
import { EventRequest, RoomRequests, Messages, Responses } from '../models';
import awsExports from '../aws-exports';
import { CheckIcon, ChevronUpDownIcon, ChatBubbleBottomCenterIcon, QueueListIcon, ExclamationTriangleIcon, InformationCircleIcon, ChatBubbleLeftRightIcon, TrashIcon, XCircleIcon, PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import moment from 'moment/moment';
import { Divider } from '@aws-amplify/ui-react';
Amplify.configure(awsExports);




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function EventDataList(props) {

  const [events, setEventDataList] = new useState([])
  const [ds, setDs] = new useState(DataStore.state)
  const [i, setI] = new useState(null)
  const [isOwner, setIsOwner] = new useState(false)

  const fetchEvents = () => {
    const sub = DataStore.observeQuery(EventRequest).subscribe(({ items }) => {
      let list = _.map(_.filter(items, (m) => { return props.isAdmin || m.email == props.userAttributes.email }), (item) => { return { ...item } })
      setEventDataList(_.orderBy(list, 'updatedAt', 'desc'))
    });
    return () => {
      sub.unsubscribe();
    };
  }

  useEffect(() => {
    if (ds === 'Starting' || ds === 'Running') {
      fetchEvents()
      setI(null)
      clearInterval(i);
    }
  }, [ds])

  useEffect(() => {


    setI(setInterval(() => {
      setDs(DataStore.state)
    }, 1000))


  }, [])


  return (
    <div className="flex flex-grow flow-root px-5 overflow-auto place-items-start">
      {events.length > 0 ?
      <ul role="list" className="-mb-8">
        {events.map((event, eventIdx) => (
          <li key={event?.id}>
            <div className="relative pb-8">
              {eventIdx !== events.length - 1 ? (
                <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex space-x-3">

                <div className="flex-grow rounded-md bg-indigo-100 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between flex-col">
                      <div classname="flex-grow">
                        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5  p-3">
                          <div>
                            <p className="text-sm text-gray-500 text-sm font-bold uppercase text-sky-300 shadow-sm bg-slate-600 rounded-full px-3">
                              {event?.eventType}
                            </p>
                          </div>
                          <div className="whitespace-nowrap text-right text-sm text-gray-500">
                            <time dateTime={event?.date}>{moment(event?.date).format('MMM Do')}</time>
                          </div>
                        </div>
                        <div className="flex place-items-center min-w-0 flex-1 justify-between space-x-4 pt-1.5 bg-gray-50 p-3 rounded-md shadow-sm">
                          <div className="flex place-items-start min-w-0 flex-1 justify-between space-x-4 pt-1.5 bg-white p-3 rounded-md shadow-lg">
                            <div className='flex flex-col place-items-start'> <span className='text-[8pt]'>requested by:</span>
                              {event?.name}
                            </div>
                            <div className='grid h-10 w-16 bg-zinc-100  rounded-md place-items-center group hover:bg-sky-100 hover:shadow-md'>
                              <EventRequestWindow event={event} userAttributes={props.userAttributes} isAdmin={props.isAdmin} />
                            </div>
                          </div>
                          <div>
                            <p className="flex text-sm text-gray-500 space-x-2 place-items-center">
                              <UserIcon className='h-4' />
                              <span className='grid h-8 content-center'>Party of </span>
                              <span className="grid place-items-center  text-blue-600 p-1 h-8 font-extrabold ">{event?.partySize}</span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="whitespace-nowrap text-left text-xs text-gray-500 uppercase my-1">
                        {event?.createdAt != event?.updatedAt && <span className='text-xs text-gray-300'>EDITED</span>}
                        <span className='px-2 text-xs'>POSTED</span>
                        <time dateTime={event?.createdAt}>{moment(event?.createdAt).fromNow()}</time>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      :
      <>
          <div className="rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700">No Event Requests</p>
              </div>
            </div>
          </div>

        </>}
    </div>
  )
}

function RoomDataList(props) {

  const [rooms, setRoomDataList] = new useState([])
  const [ds, setDs] = new useState(DataStore.state)
  const [i, setI] = new useState(null)
  const [isOwner, setIsOwner] = new useState(false)

  const fetchRooms = () => {
    const sub = DataStore.observeQuery(RoomRequests).subscribe(({ items }) => {
      let list = _.map(_.filter(items, (m) => { return props.isAdmin || m.email == props.userAttributes.email }), (item) => { return { ...item } })
      setRoomDataList(_.orderBy(list, 'updatedAt', 'desc'))
    });
    return () => {
      sub.unsubscribe();
    };
  }

  useEffect(() => {
    if (ds === 'Starting' || ds === 'Running') {
      fetchRooms()
      setI(null)
      clearInterval(i);
    }
  }, [ds])

  useEffect(() => {


    setI(setInterval(() => {
      setDs(DataStore.state)
    }, 1000))


  }, [])


  return (
    <div className="flex flex-grow flow-root px-5 overflow-auto place-items-start">
      {rooms.length > 0 ?
      <ul role="list" className="-mb-8">
        {rooms.map((room, roomIdx) => (
          <li key={room?.id}>
            <div className="relative pb-8">
              {roomIdx !== rooms.length - 1 ? (
                <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex space-x-3">

                <div className="flex-grow rounded-md bg-blue-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between flex-col">
                      <div classname="flex-grow">
                        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5  p-3">
                          <div>
                            <p className="text-sm text-gray-500 text-sm font-bold uppercase text-sky-300 shadow-sm bg-slate-600 rounded-full px-3">
                              {room?.roomType}
                            </p>
                          </div>
                          <div className="whitespace-nowrap text-right text-sm text-gray-500">
                            <time dateTime={room?.date}>{moment(room?.date).format('MMM Do')}</time>
                          </div>
                        </div>
                        <div className="flex place-items-center min-w-0 flex-1 justify-between space-x-4 pt-1.5 bg-gray-50 p-3 rounded-md shadow-sm">
                          <div className="flex place-items-start min-w-0 flex-1 justify-between space-x-4 pt-1.5 bg-white p-3 rounded-md shadow-lg">
                            <div className='flex flex-col place-items-start'> <span className='text-[8pt]'>requested by:</span>
                              {room?.name}
                            </div>
                            <div className='grid h-10 w-16 bg-zinc-100  rounded-md place-items-center group hover:bg-sky-100 hover:shadow-md'>
                              <RoomRequestWindow room={room} userAttributes={props.userAttributes} isAdmin={props.isAdmin} />
                            </div>
                          </div>
                          <div>
                            <p className="flex text-sm text-gray-500 space-x-2 place-items-center">
                              <UserIcon className='h-4' />
                              <span className='grid h-8 content-center'>Party of </span>
                              <span className="grid place-items-center  text-blue-600 p-1 h-8 font-extrabold ">{room?.partySize}</span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="whitespace-nowrap text-left text-xs text-gray-500 uppercase my-1">
                        {room?.createdAt != room?.updatedAt && <span className='text-xs text-gray-300'>EDITED</span>}
                        <span className='px-2 text-xs'>POSTED</span>
                        <time dateTime={room?.createdAt}>{moment(room?.createdAt).fromNow()}</time>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      :
      <>
          <div className="rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700">No Room Requests</p>
              </div>
            </div>
          </div>

        </>}
    </div>
  )
}

function MessageDataList(props) {

  const [messages, setMessageDataList] = new useState([])
  const [parsedMessages, setParseMessages] = new useState([])
  const [isOwner, setIsOwner] = new useState(false)

  function MessageResponseCounter(props) {
    const [responseCount, setResponseCount] = new useState(0)
    const fx = async () => {
      const models = DataStore.observeQuery(Responses, (c) =>
        c.messageId.eq(props.message.id)
      ).subscribe(({ items }) => {
        setResponseCount(items.length);
      });
      return () => {
        models.unsubscribe();
      };
    }

    useEffect(() => {
      fx()
    }, [])

    return (<><ChatBubbleBottomCenterIcon className='h-10 w-10 relative bg-white' />
      <span className='isolate absolute  z-10 h-5 w-5 grid place-items-center  text-sky-700 text-sm font-bold'>{responseCount}</span></>)

  }

  const fetchMessages = async () => {
    const models = DataStore.observeQuery(Messages, (c) => c).subscribe(({ items }) => {
      setMessageDataList(items);
    });
    return () => {
      models.unsubscribe();
    };
  }

  useEffect(() => {
    fetchMessages()
  }, [])

  useEffect(() => {
    parseMessages()
  }, [messages])


  function parseMessages() {
    let parentList = _.map(_.filter(messages, (m) => { return props.isAdmin || m.email == props.userAttributes.email }), (item) => { return { ...item } })
    setParseMessages(_.orderBy(parentList, 'updatedAt', 'desc'))
  }

  const DeleteMessageBtn = (props) => {
    const [toggle, setToggle] = new useState(false)

    function toggleState() {
      setToggle(!toggle)
    }

    function removeMessage() {

      const modelToDelete = async () => await DataStore.query(Messages, props.message.id).then((model) => {
        DataStore.delete(model);
        toggleState();
      })
      modelToDelete()
    }

    if (props.message?.email === props.userAttributes.email) {
      return <section className={`${isOwner ? 'hidden' : ''} relative`}>
        <Transition.Root show={!toggle} as={Fragment}>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <button className='absolute flex  align-center  bottom-2 right-0 hover:text-red-500 text-xs' onClick={() => { toggleState() }}>
              <span><TrashIcon className='h-4' /></span><span>Delete</span>
            </button>
          </Transition.Child>
        </Transition.Root>

        <Transition.Root show={toggle} as={Fragment}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="grid grid-cols-2 inset-0 bg-opacity-75 transition-opacity bg-white shadow-lg p-2 rounded-b-xl">
              <span className="text-xs">Delete this message?</span>
              <div className="flex ">
                <div className="flex-grow  grid justify-items-center">
                  <button className='hover:text-red-500 text-xs' onClick={() => { removeMessage() }}>Yes</button>
                </div>
                <div className="flex-grow grid justify-items-center">
                  <button className='hover:text-red-500 text-xs' onClick={() => { toggleState() }}>No</button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </Transition.Root>
      </section >
    } else {
      return <></>
    }

  }

  return (
    <div className="flex flex-grow flow-root px-5 overflow-auto place-items-start">
      {parsedMessages.length > 0 ?
        <ul role="list" className="-mb-8 space-y-4">
          {parsedMessages.map((message, messageIdx) => (
            <li key={message?.id} className='bg-white rounded-xl p-4'>
              <div className="relative pb-0">
                {messageIdx !== messages.length - 1 ? (
                  <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={classNames(
                        message?.iconBackground,
                        'h-8 w-8 rounded-full grid place-items-center justify-center ring-8 ring-white relative'
                      )}
                    >
                      <MessageResponseCounter message={message} />
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                    <div>
                      <p className="text-sm text-gray-500">
                        {message?.messageType}{' '}
                        <a href={message?.href} className="font-medium text-gray-900">
                          {message?.subject}
                        </a>
                      </p>
                    </div>
                    <div className="whitespace-nowrap text-right text-sm text-gray-500">
                      <time dateTime={moment(message?.updatedAt).format()}>{moment(message?.updatedAt).format("MMMM Do h:mm a")}</time>
                    </div>

                  </div>

                </div>
                <dt className='grid grid-cols-2  space-x-3 ml-12 text-slate-500 text-sm rounded-lg p-1 justify-space-between font-bold'>
                  <span> {message?.name}</span> <span className='grid justify-end'>{message?.email}</span>
                </dt>
                <dt className={`${message?.email !== props.userAttributes.email ? 'bg-slate-100' : 'bg-green-100'}  flex space-x-3 ml-12 text-slate-500 text-sm rounded-lg p-1 `}>{message?.details}</dt>
              </div>
              <dt className='flex justify-end space-x-4'>
                <DeleteMessageBtn message={message} userAttributes={props.userAttributes} />{/* 
              <button className='hover:text-blue-500 text-sm'> {message.responses.length} Replies</button> */}
                <MessageWindow message={message} userAttributes={props.userAttributes} />
              </dt>
            </li>
          ))}
        </ul> :
        <>
          <div className="rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700">No messages</p>
              </div>
            </div>
          </div>

        </>}
    </div>
  )
}

function NewMessageForm(props) {
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)
  const [phone, setPhone] = useState("")
  const [details, setDetails] = useState("")
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")

  const subjectTypes = [
    { id: 1, name: 'Seeking information or clarification on Rooms' },
    { id: 2, name: 'Seeking information or clarification on the Venue' },
    { id: 3, name: 'Providing feedback or suggestions' },
    { id: 4, name: 'There is something specific I would like to discuss?' },
    { id: 5, name: 'Reporting a bug or technical issue' },
  ]

  const [subject, setSubject] = useState("no subject")
  const [selected, setSelected] = useState(subjectTypes[3])

  useEffect(() => {
    setSubject(selected.name)
  }, [selected])



  useEffect(() => {
    setPhone(props.userAttributes.phone ? props.userAttributes.phone : '');
    setDetails(details);
    setEmail(props.userAttributes.email ? props.userAttributes.email : '');
    setName(props.userAttributes.name);
    setSubject(subject);
  }, [])

  const newMessage = async () => await DataStore.save(
    new Messages({
      "phone": phone,
      "details": details,
      "email": email,
      "name": name,
      "subject": subject
    })
  );

  function handleSubjectChange(e) {
    setSubject(e.target.value);
  }
  function handleDetailChange(e) {
    setDetails(e.target.value);
  }

  function submitNewMessage() {
    console.log({
      "phone": phone,
      "details": details,
      "email": email,
      "name": name,
      "subject": subject
    })

    const errors = []

    if (details.length == 0) {
      errors.push("Message is Empty")
    }
    if (subject.length == 0) {
      errors.push("Subject is Empty")
    }

    if (errors.length == 0) {
      newMessage().then(() => {
        setOpen(false);
        setPhone(props.userAttributes.phone);
        setDetails(details);
        setEmail(props.userAttributes.email);
        setName(props.userAttributes.name);
        setSubject(subject);
      })
    } else {
      alert(_.flatten(errors, " | "))
    }
  }

  function SubjectTypeList() {

    return (
      <Listbox value={selected} onChange={setSelected}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm leading-6 text-gray-900 my-2 text-left font-thin uppercase">Subject</Listbox.Label>
            <div className="relative my-2">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <span className="block truncate">{selected.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {subjectTypes.map((subjectType) => (
                    <Listbox.Option
                      key={subjectType.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9'
                        )
                      }
                      value={subjectType}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                            {subjectType.name}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    )
  }

  return (
    <>
      <button onClick={() => { setOpen(true) }}
        className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 cursor-pointer"
      >{props.tab.action}
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-5 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-screen transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all mb-12 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="grid grid-col-2 text-base font-semibold leading-6 text-gray-900">
                        <div className='flex flex-row'>
                          <div className='flex-grow'>New Message</div>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-fit justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            onClick={() => setOpen(false)}
                            ref={cancelButtonRef}
                          >
                            <XMarkIcon className='text-black h-8' />
                          </button></div>
                      </Dialog.Title>
                      <div className="mt-2">
                        <div>
                          <div className="my-2 hidden">
                            <label htmlFor="subject" className="sr-only">
                              Subject
                            </label>
                            <input
                              id="subject"
                              name="subject"
                              type="text"
                              placeholder='Subject of this message'
                              value={subject}
                              onChange={handleSubjectChange}
                              required
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>

                          <SubjectTypeList />
                        </div>

                        <div className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                          <label htmlFor="message" className="sr-only">
                            Add your message
                          </label>
                          <textarea
                            rows={3}
                            name="details"
                            id="details"
                            className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="Add your message..."
                            onChange={handleDetailChange}
                            value={details}
                          />

                          {/* Spacer element to match the height of the toolbar */}
                          <div className="py-2" aria-hidden="true">
                            {/* Matches height of button in toolbar (1px border + 36px content height) */}
                            <div className="py-px">
                              <div className="h-9" />
                            </div>
                          </div>
                        </div>

                        <div className="absolute inset-x-0 bottom-0 flex justify-end py-2 pl-3 pr-2">
                          <div className="flex-shrink-0">
                            <button
                              type="submit"
                              className="inline-flex items-center rounded-full bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              onClick={() => submitNewMessage()}
                              ref={cancelButtonRef}
                            >Post</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

function TabForm(props) {

  const [Form, setForm] = new useState(<></>)

  useEffect(() => {

  }, [])
  useEffect(() => {
    switch (props.tab?.name) {
      case 'Messages': setForm(<NewMessageForm tab={props.tab} userAttributes={props.userAttributes} />); break;
      default: setForm(<></>)
    }
  }, [props.tab?.name])

  return (<>
    {Form}
  </>)
}

function DataListContainer(props) {
  const [DataList, setDataList] = new useState(<></>)


  new useEffect(() => {
    console.log(props.tab)
    setDataList(props.tab.datalist)
  }, [props.tab])
  return (
    <div className='flex-grow flex flex-col px-0 gap-3 overflow-hidden'>
      {DataList}
      <nav
        className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
        </div>
        <div className="flex flex-1 justify-between sm:justify-end">
          <TabForm tab={props.tab} userAttributes={props.userAttributes} isAdmin={props.isAdmin} />
        </div>
      </nav>
    </div>
  )
}

function MessageWindow(props) {
  const [open, setOpen] = useState(false)
  const [responseText, setResponseText] = useState("")
  const [responses, setResponses] = useState([])

  const fx = async () => {
    const models = DataStore.observeQuery(Responses, (c) =>
      c.messageId.eq(props.message.id)
    ).subscribe(({ items }) => {
      setResponses(_.orderBy(items, 'createdAt', 'asc'));
    });
    return () => {
      models.unsubscribe();
    };

  }




  function closeModal() {
    setOpen(false)
  }

  function openModal() {
    setOpen(true)
  }

  function handleChange(e) {
    setResponseText(e.target.value);
  }

  useEffect(() => {
    if (open) fx()
  }, [open])


  function sendResponse(message) {
    console.log(message)
    const send = async () => await DataStore.save(
      new Responses({
        "messageId": message.id,
        "userId": props.userAttributes.sub,
        "details": responseText
      })
    );
    send().then((result) => {
      setResponseText("")
      setOpen(false)
    })
  }

  const RemoveResponseBtn = (props) => {
    const [toggle, setToggle] = new useState(false)

    function toggleState() {
      setToggle(!toggle)

    }

    function removeResponse() {

      const modelToDelete = async () => await DataStore.query(Responses, props.response.id).then((model) => {
        DataStore.delete(model);
        toggleState();
      })
      modelToDelete()
    }

    if (props.response?.userId === props.userAttributes.sub) {
      return <section className='relative'>
        <Transition.Root show={!toggle} as={Fragment}>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <button className='absolute flex  align-center  bottom-2 right-0 hover:text-red-500 text-xs' onClick={() => { toggleState() }}>
              <span><TrashIcon className='h-4' /></span><span>remove</span>
            </button>
          </Transition.Child>
        </Transition.Root>

        <Transition.Root show={toggle} as={Fragment}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="grid grid-cols-2 inset-0 bg-opacity-75 transition-opacity bg-white shadow-lg p-2 rounded-b-xl">
              <span className="text-xs">remove this comment?</span>
              <div className="flex ">
                <div className="flex-grow  grid justify-items-center">
                  <button className='hover:text-red-500 text-xs' onClick={() => { removeResponse() }}>Yes</button>
                </div>
                <div className="flex-grow grid justify-items-center">
                  <button className='hover:text-red-500 text-xs' onClick={() => { toggleState() }}>No</button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </Transition.Root>
      </section >
    } else {
      return <></>
    }

  }


  return (
    <>
      <div className="inset-0 h-8 flex items-center justify-center">
        <button
          type="button"
          onClick={openModal}
          className="hover:text-blue-500 text-xs"
        >
          View Message
        </button>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10 h-screen" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ChatBubbleLeftRightIcon className="h-6 w-6 text-sky-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {props.message?.subject}
                      </Dialog.Title>
                      <div className="relative flex space-x-3 mt-8">
                        <div>
                          <span
                            className={classNames(
                              props.message?.iconBackground,
                              'h-8 w-8 rounded-full grid place-items-center justify-center ring-8 ring-white relative'
                            )}
                          >
                            <ChatBubbleBottomCenterIcon className='h-10 w-10 relative bg-white' />
                            <span className='isolate absolute  z-10 h-5 w-5 grid place-items-center  text-sky-700 text-sm font-bold'>{responses.length}</span>
                          </span>
                        </div>
                        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                          <div>
                            <p className="text-sm text-gray-500">
                              {props.message?.messageType}{' '}
                              <a href={props.message?.href} className="font-medium text-gray-900">
                                {props.message?.name}
                              </a>
                            </p>
                          </div>
                          <div className="whitespace-nowrap text-right text-sm text-gray-500">
                            <time dateTime={moment(props.message?.updatedAt).format()}>{moment(props.message?.updatedAt).format("MMMM Do h:mm a")}</time>
                          </div>

                        </div>

                      </div>
                      <dt className='flex space-x-3 ml-12 text-slate-500 text-sm rounded-lg p-1 bg-slate-100'>{props.message?.details}</dt>
                    </div>
                  </div>

                  <section>
                    <ul className="my-4 space-y-4">
                      {
                        responses.map((response, responseIdx) => (
                          <li key={response?.id} className='relative bg-zinc-50 rounded-lg p-2'>
                            <div className="relative">

                              <div className="relative flex space-x-3">
                                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                  <div>
                                    <p className="text-sm text-gray-500">
                                      {response?.messageType}{' '}
                                      <span className={`${response?.userId !== props.userAttributes.sub ? 'hidden' : ''} font-medium text-gray-900`}>
                                        me:
                                      </span>
                                    </p>
                                  </div>
                                  <div className="whitespace-nowrap text-right text-sm text-gray-500">

                                    {response?.createdAt != response?.updatedAt && <span className="text-gray-400 px-3 text-xs">edited</span>}
                                    <time dateTime={moment(response?.updatedAt).format()}>{moment(response?.updatedAt).format("MMMM Do h:mm a")}</time>
                                  </div>

                                </div>

                              </div>
                              <dt className='grid grid-cols-2  space-x-3 ml-12 text-slate-500 text-sm rounded-lg p-1 justify-space-between font-bold'>
                                <span> {response?.name}</span> <span className='grid justify-end'>{response?.email}</span>
                              </dt>
                              <dt className={`${response?.userId !== props.userAttributes.sub ? 'bg-slate-100' : 'bg-green-100'}  flex space-x-3 ml-12 text-slate-500 text-sm rounded-lg p-1 `}>{response?.details}</dt>
                            </div>
                            <dt className='flex h-8 relative justify-end space-x-4'>
                              <RemoveResponseBtn response={response} userAttributes={props.userAttributes} />
                            </dt>
                          </li>
                        ))}
                    </ul>
                    {/* JSON.stringify(responses) */}
                  </section>

                  <div className="w-full">
                    <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                      Message
                    </label>
                    <div className="mt-2.5">
                      <textarea
                        name="message"
                        id="message"
                        value={responseText}
                        onChange={handleChange}
                        rows={4}
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 sm:ml-3 sm:w-auto"
                      onClick={() => sendResponse(props.message)}
                    >
                      Send reply
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

function RoomRequestWindow(props) {
  const [open, setOpen] = useState(false)
  const [isOwner, setIsOwner] = new useState(false)

  function closeModal() {
    setOpen(false)
  }

  function openModal() {
    setOpen(true)
  }

  const DeleteRoomRequestBtn = (props) => {
    const [toggle, setToggle] = new useState(false)



    function toggleState() {
      setToggle(!toggle)

    }

    function removeRoomRequest() {

      const modelToDelete = async () => await DataStore.query(RoomRequests, props.room.id).then((model) => {
        DataStore.delete(model);
        toggleState();
      })
      modelToDelete()
    }

    if (props.room?.email === props.userAttributes.email || props.isAdmin) {
      return <section className={`${isOwner ? 'hidden' : ''} relative`}>
        <Transition.Root show={!toggle} as={Fragment}>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <button className='flex absolute right-0 align-center  hover:text-red-500 text-xs' onClick={() => { toggleState() }}>
              <span><TrashIcon className='h-4' /></span><span>Delete</span>
            </button>
          </Transition.Child>
        </Transition.Root>

        <Transition.Root show={toggle} as={Fragment}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed h-8 bottom-0 grid grid-cols-2 inset-0 transition-opacity bg-red-100 shadow-lg p-2 rounded-b-xl">
              <span className="text-xs">Delete this Room Request?</span>
              <div className="flex ">
                <div className="flex-grow  grid justify-items-center">
                  <button className='hover:text-red-500 text-xs' onClick={() => { removeRoomRequest() }}>Yes</button>
                </div>
                <div className="flex-grow grid justify-items-center">
                  <button className='hover:text-red-500 text-xs' onClick={() => { toggleState() }}>No</button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </Transition.Root>
      </section >
    } else {
      return <></>
    }

  }

  return (
    <>
      <div className="inset-0 h-8 flex items-center w-full justify-center">
        <button
          type="button"
          onClick={openModal}
          className="hover:text-blue-500 text-xs  w-full h-full"
        >
          View
        </button>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10 h-screen" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start  ">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10 ">
                      <ChatBubbleLeftRightIcon className="h-6 w-6 text-sky-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left flex-grow-1  w-full">
                      <Dialog.Title as="h3" className="text-xl font-semibold leading-6 pt-2 text-gray-900 uppercase ">
                        <span className='text-amber-500'>{props.room?.roomType}</span> for <span className="rounded-full text-sky-600"> {props.room?.partySize} persons </span>
                      </Dialog.Title>
                      <div className="relative flex flex-col space-x-3 my-1 ">
                        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                          <p className="text-sm text-gray-500 grid justify-center content-center">
                            <span className='uppercase text-[7pt]'>requested by:</span>{props.room?.name}
                          </p>
                          <div className=" text-right font-thin text-lg text-gray-500 uppercase w-24">
                            <time dateTime={moment(props.room?.date).format()}>{moment(props.room?.date).format("dddd MMMM Do")}</time>
                          </div>

                        </div>
                        <div className='grid  grid-cols- mt-2'>
                          <div className='flex space-x-2'><PhoneIcon className="h-[16pt]" /> <span>{props.room?.phone}</span></div>
                          <div className='flex space-x-2'><EnvelopeIcon className="h-[16pt]" /> <span>{props.room?.email}</span></div>
                        </div>
                      </div>
                      <dt className={`${!props.room?.details && 'hidden'} flex flex-grow-1  p-2 my-4 text-slate-500 text-sm rounded-lg p-1 `}>{props.room?.details}</dt>

                      <Divider></Divider>
                      <div className='grid grid-cols-2 justify-center content-center'>
                        <div className="whitespace-nowrap text-left text-xs text-gray-500 uppercase my-1">
                          {props.room?.createdAt != props.room?.updatedAt && <span className='text-xs text-gray-300'>EDITED</span>}
                          <span className='px-2 text-xs'>POSTED</span>
                          <time dateTime={props.room?.createdAt}>{moment(props.room?.createdAt).fromNow()}</time>
                        </div>
                        <div className="grid content-center justify-end">
                          <div className='relative'>
                            <DeleteRoomRequestBtn room={props.room} userAttributes={props.userAttributes} isAdmin={props.isAdmin} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

function EventRequestWindow(props) {
  const [open, setOpen] = useState(false)
  const [isOwner, setIsOwner] = new useState(false)

  function closeModal() {
    setOpen(false)
  }

  function openModal() {
    setOpen(true)
  }

  const DeleteEventRequestBtn = (props) => {
    const [toggle, setToggle] = new useState(false)



    function toggleState() {
      setToggle(!toggle)

    }

    function removeEventRequest() {

      const modelToDelete = async () => await DataStore.query(EventRequest, props.event.id).then((model) => {
        DataStore.delete(model);
        toggleState();
      })
      modelToDelete()
    }

    if (props.event?.email === props.userAttributes.email || props.isAdmin) {
      return <section className={`${isOwner ? 'hidden' : ''} relative`}>
        <Transition.Root show={!toggle} as={Fragment}>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <button className='flex absolute right-0 align-center  hover:text-red-500 text-xs' onClick={() => { toggleState() }}>
              <span><TrashIcon className='h-4' /></span><span>Delete</span>
            </button>
          </Transition.Child>
        </Transition.Root>

        <Transition.Root show={toggle} as={Fragment}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed h-8 bottom-0 grid grid-cols-2 inset-0 transition-opacity bg-red-100 shadow-lg p-2 rounded-b-xl">
              <span className="text-xs">Delete this Event Request?</span>
              <div className="flex ">
                <div className="flex-grow  grid justify-items-center">
                  <button className='hover:text-red-500 text-xs' onClick={() => { removeEventRequest() }}>Yes</button>
                </div>
                <div className="flex-grow grid justify-items-center">
                  <button className='hover:text-red-500 text-xs' onClick={() => { toggleState() }}>No</button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </Transition.Root>
      </section >
    } else {
      return <></>
    }

  }

  return (
    <>
      <div className="inset-0 h-8 flex items-center w-full justify-center">
        <button
          type="button"
          onClick={openModal}
          className="hover:text-blue-500 text-xs  w-full h-full"
        >
          View
        </button>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10 h-screen" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start  ">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10 ">
                      <ChatBubbleLeftRightIcon className="h-6 w-6 text-sky-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left flex-grow-1  w-full">
                      <Dialog.Title as="h3" className="text-xl font-semibold leading-6 pt-2 text-gray-900 uppercase ">
                        <span className='text-amber-500'>{props.event?.eventType}</span> for <span className="rounded-full text-sky-600"> {props.event?.partySize} persons </span>
                      </Dialog.Title>
                      <div className="relative flex flex-col space-x-3 my-1 ">
                        <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                          <p className="text-sm text-gray-500 grid justify-center content-center">
                            <span className='uppercase text-[7pt]'>requested by:</span>{props.event?.name}
                          </p>
                          <div className=" text-right font-thin text-lg text-gray-500 uppercase w-24">
                            <time dateTime={moment(props.event?.date).format()}>{moment(props.event?.date).format("dddd MMMM Do")}</time>
                          </div>

                        </div>
                        <div className='grid  grid-cols- mt-2'>
                          <div className='flex space-x-2'><PhoneIcon className="h-[16pt]" /> <span>{props.event?.phone}</span></div>
                          <div className='flex space-x-2'><EnvelopeIcon className="h-[16pt]" /> <span>{props.event?.email}</span></div>
                        </div>
                      </div>
                      <dt className={`${!props.event?.details && 'hidden'} flex flex-grow-1  p-2 my-4 text-slate-500 text-sm rounded-lg p-1 `}>{props.event?.details}</dt>

                      <Divider></Divider>
                      <div className='grid grid-cols-2 justify-center content-center'>
                        <div className="whitespace-nowrap text-left text-xs text-gray-500 uppercase my-1">
                          {props.event?.createdAt != props.event?.updatedAt && <span className='text-xs text-gray-300'>EDITED</span>}
                          <span className='px-2 text-xs'>POSTED</span>
                          <time dateTime={props.event?.createdAt}>{moment(props.event?.createdAt).fromNow()}</time>
                        </div>
                        <div className="grid content-center justify-end">
                          <div className='relative'>
                            <DeleteEventRequestBtn event={props.event} userAttributes={props.userAttributes} isAdmin={props.isAdmin} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default function DataTabContainer() {
  const [isAdmin, setIsAdmin] = new useState(false)
  const [userAttributes, setUserAttributes] = new useState(false)
  const [selectedTab, selectTab] = new useState({})

  const fx = async () => {
    const user = await Auth.currentAuthenticatedUser();
    // Returns an array of groups
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
    const result = _.includes(groups, 'web-admin')
    console.log(user)
    setUserAttributes(user.attributes)
    console.log(result)
    setIsAdmin(result)
  }



  const tabs = [

    { name: 'Rooms', action: 'Request Room', datalist: <RoomDataList userAttributes={userAttributes} isAdmin={isAdmin} /> },
    { name: 'Events', action: 'Request Venue', datalist: <EventDataList userAttributes={userAttributes} isAdmin={isAdmin} /> },
    { name: 'Messages', action: 'Send a Message', datalist: <MessageDataList userAttributes={userAttributes} isAdmin={isAdmin} /> },
  ]



  useEffect(() => {
    selectTab(_.first(tabs))
    fx()
  }, [])
  useEffect(() => {
    console.log(selectedTab)
  }, [selectedTab])

  return (
    <div className="flex w-full max-h-[500px] overflow-hidden flex-col ">
      <div className="flex flex-grow max-h-[80px]  p-8 pb-16">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={selectedTab.name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="flex space-x-4" aria-label="Tabs">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  selectedTab.name === tab.name ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                  'rounded-md px-3 py-2 text-sm font-medium cursor-pointer'
                )}
                aria-current={selectedTab === tab ? 'page' : undefined}
                onClick={() => selectTab(tab)}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
      <section className="flex flex-grow overflow-hidden">
        <DataListContainer tab={selectedTab} userAttributes={userAttributes} isAdmin={isAdmin} />
      </section>
    </div>
  )
}
