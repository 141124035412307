import React, { useContext } from "react";
import _ from "lodash";
import { isBrowser, isMobile } from "react-device-detect";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import CategoryBlock from "./CategoryBlock";
import ContentArea from "../ContentArea";
import { PriceModule, PriceModule_2 } from "../../components/PriceModule"

const incentives = [
  {
    name: "Podium",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "Basileia Palace offers podiums in their meeting rooms that are equipped with state-of-the-art technology and designed for the comfort of the speaker, providing a platform for effective and clear presentations during conferences and meetings.",
  },
  {
    name: "PA Systems",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "The PA system features advanced technology and equipment, including microphones, speakers, and amplifiers, that ensure clear and crisp sound quality. With the PA system, guests can enjoy seamless and effective communication during their meetings or events.",
  },
  {
    name: "WIFI Internet",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Basileia Palace provides secure and high-speed WiFi for guests in all areas of the hotel, enabling them to stay connected and productive during their stay.",
  },
  {
    name: "Projector",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "Basileia Palace offers projectors for use in their meeting rooms and outdoor celebrations venue, providing a high-quality audio-visual experience for presentations and entertainment. ",
  },
  {
    name: "Mini Bar",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "Basileia Palace offers a mini-bar for their guests, providing a convenient and refreshing selection of beverages and snacks for guests.",
  },
  {
    name: "Catering",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Guests can enjoy their meals in the dining areas or outdoor venue, which is a picturesque and tranquil oasis. The catering service is designed to provide guests with a memorable and enjoyable dining experience.",
  },
];

const tiers = [
  {
    name: "Basic",
    id: "tier-basic",
    href: "#",
    price: { monthly: "$15", annually: "$12" },
    description: "Everything necessary to get started.",
    features: [
      "5 products",
      "Up to 1,000 subscribers",
      "Basic analytics",
      "48-hour support response time",
    ],
  },
  {
    name: "Essential",
    id: "tier-essential",
    href: "#",
    price: { monthly: "$30", annually: "$24" },
    description:
      "Everything in Basic, plus essential tools for growing your business.",
    features: [
      "25 products",
      "Up to 10,000 subscribers",
      "Advanced analytics",
      "24-hour support response time",
      "Marketing automations",
    ],
  },
  {
    name: "Growth",
    id: "tier-growth",
    href: "#",
    price: { monthly: "$60", annually: "$48" },
    description:
      "Everything in Essential, plus collaboration tools and deeper insights.",
    features: [
      "Unlimited products",
      "Unlimited subscribers",
      "Advanced analytics",
      "1-hour, dedicated support response time",
      "Marketing automations",
      "Custom reporting tools",
    ],
  },
];

const Elem = () => (
  <div className="bg-white/100">
    <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8  px-4 py-10 sm:px-6 sm:py-10 lg:max-w-7xl lg:grid-cols-1 lg:px-8">
      <div>

        <dl className=" grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-3 sm:gap-y-16 lg:gap-x-8">
          {incentives.map((incentive) => (
            <div key={incentive.name} className="sm:flex lg:block">
              <div className="sm:flex-shrink-0">
                <img
                  className="hidden h-16 w-16"
                  src={incentive.imageSrc}
                  alt=""
                />
              </div>
              <div className="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                <h3 className="text-sm font-bold underline text-gray-800">
                  {incentive.name}
                </h3>
                <p className="mt-2 text-sm text-gray-800">
                  {incentive.description}
                </p>
              </div>
            </div>
          ))}
        </dl>
      </div>
    </div>
  </div>
);

function Hero() {
  return (
    <div className="overflow-hidden bg-white py-32">
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-0  lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-thin uppercase tracking-tight text-gray-900 sm:text-4xl">
              Celebrate New Beginning
            </h2>
            <p className="mt-6 text-xl leading-8 text-gray-600">
              Our beautiful surroundings provide a picturesque backdrop for capturing those special moments during the baby shower. Whether it's candid snapshots, group photos, or a professional photo shoot, our venue offers plenty of opportunities to create lasting memories that the parents-to-be will cherish forever
            </p>
            <p className="mt-6 text-xl leading-8 text-gray-600">
              Plan Your Baby Shower: Contact us now to discuss your baby shower requirements and reserve our venue. Let us help you create an enchanting celebration that honors the expectant parents and their little bundle of joy.
            </p>
          </div>
          <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
              <img
                src="https://cdn.basileiapalaceja.com/media/baby_shower/babyshower_1.jpg"
                alt=""
                className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
              />
            </div>
            <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
              <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                <img
                  src="https://cdn.basileiapalaceja.com/media/baby_shower/babyshower_2.jpg"
                  alt=""
                  className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
              <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                <img
                  src="https://cdn.basileiapalaceja.com/media/baby_shower/babyshower_3.jpg"
                  alt=""
                  className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
              <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                <img
                  src="https://cdn.basileiapalaceja.com/media/baby_shower/babyshower_7.jpg"
                  alt=""
                  className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const d = (
  <div className="mx-auto n max-w-7xl lg:px-4 py-0">
    <div
      className="w-full rounded-none h-full  overflow-auto  bg-cover bg-fixed bg-center bg-no-repeat"
      style={{
        backgroundImage: `url('https://cdn.basileiapalaceja.com/media/baby_shower/babyshower_15.jpg')`,
      }}
    >
      <div className="mx-auto max-w-7xl pb-10  lg:max-w-none">
        <div className="grid grid-cols-1 items-center gap-x-16 gap-y-0 lg:grid-cols-2 py-0">
          <h2 className="text-8xl font-thin tracking-tight text-gray-100 px-10">
            <p>Baby Showers</p>
            <p className="text-lg tracking-normal font-bold indent-3">@ Basiliea Palace</p>
          </h2>
          <p className="m-4 text-2xl text-gray-100 p-8  rounded-lg antialiased hover:subpixel-antialiased">
            Celebrate the imminent arrival of a precious little one in style. Our charming venue sets the stage for an unforgettable baby shower filled with love and joy. From elegant decorations to delectable treats, our team will ensure that every detail is meticulously taken care of. Enjoy precious moments with loved ones, indulge in delectable treats, and create beautiful memories to cherish.
          </p>
          <div className="my-0 col-span-2 bg-white/10 rounded-xl">
            <Hero />
          </div>
          <div className="grid px-10 py-10 my-0  col-span-2 gap-10 bg-black/40 backdrop-blur-xs min-h-[10rem]">

            <p class="text-white text-6xl">Baby Shower Bliss</p>
            <p class="text-white text-xl">we understand the significance of this joyous occasion and strive to make it an unforgettable experience. Our attentive staff is dedicated to ensuring that every detail is flawlessly executed, from decor and ambiance to personalized touches. Let us create an enchanting setting that reflects the love and excitement surrounding the soon-to-arrive little one.</p>

            <div className="grid grid-cols-4 w-full gap-10 space-around">

              <img
                src="https://cdn.basileiapalaceja.com/media/baby_shower/babyshower_17.jpg"
                alt=""
                className="aspect-[4/4] w-[15rem] max-w-none ring-4 ring-white bg-gray-50 object-cover object-center rounded-full drop-shadow-md"
              />

              <img
                src="https://cdn.basileiapalaceja.com/media/baby_shower/babyshower_16.jpg"
                alt=""
                className="aspect-[4/4] w-[15rem] max-w-none ring-4 ring-white bg-gray-50 object-cover object-center rounded-full drop-shadow-md"
              />

              <img
                src="https://cdn.basileiapalaceja.com/media/baby_shower/babyshower_14.jpg"
                alt=""
                className="aspect-[4/4] w-[15rem] max-w-none ring-4 ring-white bg-gray-50 object-cover object-center rounded-full drop-shadow-md"
              />

              <img
                src="https://cdn.basileiapalaceja.com/media/baby_shower/babyshower_7.jpg"
                alt=""
                className="aspect-[4/4] w-[15rem] max-w-none ring-4 ring-white bg-gray-50 object-cover object-top rounded-full drop-shadow-md"
              />
            </div>
          </div>

        </div>
        <Elem />
        <PriceModule category='babyshower' />
      </div>
    </div>
  </div>
);

export default function BabyShowerContentBlock() {
  return <ContentArea>{d}</ContentArea>;
}
