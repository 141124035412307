// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Responses, Messages, RoomRequests, UserProfile, EventRequest } = initSchema(schema);

export {
  Responses,
  Messages,
  RoomRequests,
  UserProfile,
  EventRequest
};