import React, { useContext } from "react";
import _ from "lodash";
import { MainLayout } from "../../layouts/mainLayout";
import { MobileLayout } from "../../layouts/mobileLayout";
import CorporateContentBlock from "../../components/ContentAreaBlocks/CorporateContentBlock"
import {CorporateContentBlock as MobileCorporateContentBlock} from "../../components/Mobile/MobileContentAreaBlocks/CorporateContentBlock";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Footer_alt from "../../components/Footer/Footer_alt";
import ContentArea from "../../components/ContentArea";
export default function CorporatePage() {
  return (
    <>
      <BrowserView>
        <MainLayout>
          <CorporateContentBlock />
          <Footer_alt />
        </MainLayout>
      </BrowserView>
      <MobileView>
        <MobileLayout>
          <MobileCorporateContentBlock />
        </MobileLayout>
      </MobileView>
    </>
  );
}
