import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import _ from "lodash";
import { MainLayout } from "../../layouts/mainLayout";
import Footer_alt from "../../components/Footer/Footer_alt";
import ContentArea from "../../components/ContentArea";
import Hero_1 from "../../components/Hero_1";
import HomeContentBlock from "../../components/ContentAreaBlocks/HomeContentBlock";

import { ChevronRightIcon } from "@heroicons/react/20/solid";
import {
  AcademicCapIcon,
  BanknotesIcon,
  CheckBadgeIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
export default function DeluxeRoomPage() {
  return (
    <>
      <MainLayout>
        <Panel>
          <Hero />
          <Features />
        </Panel>
        <Footer_alt />
      </MainLayout>
    </>
  );
}

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
const files = [
  {
    category: 1,
    source:
      "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_1.jpg",
  },
  {
    category: 1,
    source:
      "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_2.jpg",
  },
  {
    category: 1,
    source:
      "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_3.jpg",
  },
  {
    category: 1,
    source:
      "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_4.jpg",
  },
  {
    category: 1,
    source:
      "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_5.jpg",
  },
  {
    category: 1,
    source:
      "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_6.jpg",
  },
  {
    category: 1,
    source:
      "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_7.jpg",
  },
  {
    category: 1,
    source:
      "https://cdn.basileiapalaceja.com/media/bedrooms/bedroom_8.jpg",
  },
  // More files...
];


function ImageModal(props) {
  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <button
        type="button"
        onClick={openModal}
      >
        {props.children}
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">

                  <div className="mt-2">
                    <img
                      src={props.file.source}
                      alt=""
                      width="500px"
                      className="pointer-events-none object-cover"
                    />
                  </div>

                  <div className="mt-4 grid place-items-center">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-amber-100 px-4 py-2 text-sm font-medium text-amber-900 hover:bg-amber-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-amber-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Close Window
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}


export function ImageGallery() {
  return (
    <ul
      role="list"
      className="mx-auto max-w-7xl my-2 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
    >
      {files.map((file) => (
        <li key={file.source} className="relative">
          <ImageModal file={file} ><div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
            <Transition
              as={Fragment}
              show={true}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >

              <img
                src={file.source}
                alt=""
                className="pointer-events-none object-cover aspect-square group-hover:opacity-75"
              />
            </Transition>
            <button
              type="button"
              className="absolute inset-0 focus:outline-none"
            >
              <span className="sr-only">View details for {file.title}</span>
            </button>
          </div>
          </ImageModal >
        </li>
      ))}
    </ul>
  );
}

export function Panel(props) {
  return (
    <div className=" rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:p-6">{props.children}</div>
    </div>
  );
}


export function Hero() {
  return (
    <div className="bg-white">
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20">
        <div className="mx-auto max-w-7xl pb-24 pt-10 sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-40">
          <div className="px-6 lg:px-0 lg:pt-4">
            <div className="mx-auto max-w-2xl">
              <div className="max-w-lg">
                <img
                  className="block h-[300px] w-auto"
                  src="https://cdn.basileiapalaceja.com/media/basileia_palace_logo.png"
                  alt="Basileia Palace"
                />
                <h1 className="mt-10 text-4xl font-thin tracking-tight text-gray-900 sm:text-6xl">
                  Discover our Deluxe Double Rooms
                  <p className='text-[16pt] mt-5 font-extrabold'>Luxury and Comfort Redefined</p>
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Indulge in luxury and sophistication in our Deluxe Double Rooms. With stylish and modern decor, attention to detail, and spacious layouts, these rooms provide a truly elevated experience. Enjoy the convenience of double beds, a well-appointed en-suite bathroom, and a dedicated workspace.
                  <br />Stay connected with high-speed Wi-Fi, unwind with a variety of entertainment options, and relish personalized comfort with individually controlled air conditioning. Experience a perfect blend of elegance and comfort in our Deluxe Double Rooms.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-20 sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen">
            <div
              className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-2xl shadow-amber-600/90 ring-1 ring-indigo-50 md:-mr-20 lg:-mr-36"
              aria-hidden="true"
            />
            <div className="shadow-lg md:rounded-3xl">
              <div className="bg-amber-400  [clip-path:inset(5)] md:[clip-path:inset(0_round_theme(borderRadius.3xl))]">
                <div
                  className="absolute -inset-y-px left-1/2 -z-10 ml-10 w-[200%] skew-x-[-30deg] bg-sky-100 opacity-20 ring-1 ring-inset ring-white md:ml-20 lg:ml-36"
                  aria-hidden="true"
                />
                <div className="relative px-6 pt-8 sm:pt-16 md:pl-16 md:pr-0">
                  <div className="mx-auto max-w-2xl md:mx-0 md:max-w-none p-8">
                    <Example />
                    <ImageGallery />
                  </div>
                  <div
                    className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 md:rounded-3xl"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </div>
  );
}


function Features() {
  const features = [
    {
      name: 'Beds X2',
      description:
        'Two comfortable double beds or queen-size beds, providing ample space for a restful sleep',
    },
    {
      name: 'Comfortable Atmosphere',
      description:
        'Stylish and modern décor with meticulous attention to detail, creating a contemporary ambiance',
    },
    {
      name: 'Business Savvy',
      description:
        'Dedicated workspace with a desk and ergonomic chair, perfect for productivity or planning your adventures',
    },
    {
      name: 'High Speed WiFi',
      description:
        'High-speed Wi-Fi connectivity for multiple devices, keeping you connected throughout your stay',
    },
    {
      name: 'Room Ammenities',
      description:
        'In-room coffee and tea making facilities, allowing you to enjoy a refreshing beverage at any time',
    },
    {
      name: 'Mini Bar',
      description:
        ' Mini refrigerator or minibar for added convenience, providing storage for your snacks or beverages'
    },
    {
      name: 'Smart TV',
      description:
        ' Mini refrigerator or minibar for added convenience, providing storage for your snacks or beverages'
    },
    {
      name: 'Air Conditioner',
      description:
        'Individually controlled air conditioning, allowing you to personalize the room temperature to your preference'
    },
    {
      name: 'Family Options',
      description:
        'Option for connecting rooms, ideal for families or larger groups seeking to stay together'
    },
  ]
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Key Features</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Experience the ultimate blend of luxury and comfort in our Deluxe Double Rooms at Basileia Palace. Whether you're traveling with loved ones or friends, our thoughtfully designed rooms cater to your every need, ensuring a memorable and enjoyable stay.
          </p>
        </div>
        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {features.map((feature) => (
            <div key={feature.name}>
              <dt className="font-semibold text-gray-900">{feature.name}</dt>
              <dd className="mt-1 text-gray-600">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}





function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function Example() {
  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow flex sm:gap-px sm:divide-y-0">
      <div className="bg-gray-50 sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">What's Included</h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>Book your Deluxe Double Room today and embark on a journey of relaxation and indulgence at Basileia Palace.</p>
          </div>
          <div className="mt-5">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Request
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

